import React, { useContext } from 'react';

import { Flex, HStack, Icon, Stack } from '@chakra-ui/react';
import {
  FaDollarSign,
  FaEdit,
  FaHome,
  FaMapMarkedAlt,
  FaMapSigns,
  FaPowerOff,
  FaFileCsv,
  FaTrash,
  FaUser,
  FaUserTie,
  FaWallet,
  FaFileImport,
  FaFileDownload,
  FaHireAHelper,
} from 'react-icons/fa';
import Button from '../button';
import auth from '../../contexts/auth';
import NavLink from './navLink';
import NavSection from './navSection';

const SideBarNav = () => {
  return (
    <Flex flexDir='column' gap='8' align="flex-start">
      <NavSection title="GERAL">
        <NavLink icon={FaHome} to="/">
          Home
        </NavLink>
        <NavLink icon={FaMapMarkedAlt} to="/pontos">
          Pontos
        </NavLink>
        <NavLink icon={FaDollarSign} to="/acerto">
          Acerto
        </NavLink>
        <NavLink icon={FaFileImport} to="/importar">
          Importar
        </NavLink>
        <NavLink icon={FaFileDownload} to="/baixar">
          Baixas
        </NavLink>
      </NavSection>
      <NavSection title="CADASTRO">
        <NavLink icon={FaUser} to="/usuario">
          Usuário
        </NavLink>
        <NavLink icon={FaUserTie} to="/cliente">
          Cliente
        </NavLink>
        <NavLink icon={FaWallet} to="/carne">
          Carne
        </NavLink>
        <NavLink icon={FaMapSigns} to="/rota">
          Rota
        </NavLink>
      </NavSection>
    </Flex>
  );
};
export default SideBarNav;
