import React, { useState, useContext } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useToast,
  Button as CharaButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '../../components/Form/Input';
import animationData from '../../assets/forgot-password.json';
import animationSuccess from '../../assets/done.json';
import Button from '../../components/button';
import * as UsuarioService from '../../services/usuario';

const userFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('e-mail obrigatório')
    .email('Digite um email valido'),
});

interface senhaInterface {
  email: string;
}
const TrocarSenha: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const toast = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState:{errors,isSubmitting}} = useForm({
    resolver: yupResolver(userFormSchema),
  });

   const handleSalvar: SubmitHandler<senhaInterface> = async values => {
    const email = {
      email: values.email,
    };
    const response = await UsuarioService.redeFinirSenha(email);
    if (response.success) {
      setSuccess(true);
    } else {
      toast({
        title: 'Redefinir Senha!',
        description: response.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Flex
      as="form"
      w="100%"
      my="6"
      maxWidth={1480}
      mx="auto"
      px="6"
      align="center"
      justify="center"
      direction="column"
      onSubmit={handleSubmit(handleSalvar)}
    >
      {success ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          w="100%"
          my="6"
          mx="auto"
          px="6"
        >
        
          <Text
            fontSize={['2xl', '4xl']}
            fontWeight="black"
            w={64}
            mt={50}
            mb={50}
            color="teal.600"
            textAlign="center"
          >
            Email enviado com sucesso!
          </Text>
          <CharaButton
            type="button"
            mt="8"
            size="sm"
            variant="link"
            textColor="teal.600"
            _hover={{ color: 'teal.300' }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </CharaButton>
        </Flex>
      ) : (
        <>
          <Text
            fontSize={['2xl', '4xl']}
            fontWeight="black"
            letterSpacing={-2}
            w={64}
            mb={50}
          >
            supercrediario.
          </Text>
          <Flex direction="column" align="center" justify="center">
          
          </Flex>
          <Text fontSize={['lg', '2xl']} mb="10">
            Redefinir Senha!
          </Text>

          <Grid
            h={200}
            templateRows="repeat(6, 1fr)"
            templateColumns="repeat(6, 1fr)"
            gap={4}
          >
            <GridItem colSpan={[6, 6]}>
              <Input
                id="email"
                type="email"
                error={errors.email}
                label="Email"
                register={register}
                autoComplete="off"
              />
            </GridItem>
            <GridItem colSpan={[6, 6]}>
              <Button
                type="submit"
                w="100%"
                mt="8"
                isLoading={isSubmitting}
              >
                Enviar
              </Button>
            </GridItem>
          </Grid>
        </>
      )}
    </Flex>
  );
};
export default TrocarSenha;
