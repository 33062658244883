import React, { useState, useContext, useEffect } from 'react';

import {
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  useToast,
  Text,
  Input as InputChakra,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import {
  FaSearch,
  FaArrowRight,
  FaSave,
  FaTrash,
  FaArrowLeft,
} from 'react-icons/fa';

import Button from '../button';

import * as rotaServices from '../../services/rota';

import * as CarneServices from '../../services/carne';

import Carne from '../../interface/carne';
import empresaContext from '../../contexts/empresa';

import rotaInterface from '../../interface/rota';
import Alert from '../alertDialog';
import { InputControled } from '../Form/inputControled';

interface Props {
  rota: rotaInterface;
  handleCloseRota(): void;
}

const RotaCarnes: React.FC<Props> = ({ rota, handleCloseRota }) => {
  const toast = useToast();
  const { empresa } = useContext(empresaContext);
  const [textSearch, setTextSearch] = useState('');

  const [carneValido, setCarneValido] = useState(false);
  const [nomeRota, setNomeRota] = useState(false);
  const [carne, setCarne] = useState<Carne>({} as Carne);
  const [listaRota, setListaRota] = useState<Carne[]>([]);
  const [dataView, setDataView] = useState(false);
  const [open, setOpen] = useState(false);
  const [indexValue, setIndexValue] = useState(0);
  const [carneExcluir, setCarneExcluir] = useState<Carne>({} as Carne);

  useEffect(() => {
    function SortByOrdem(a: Carne, b: Carne) {
      const aOrder = a.ordem;
      const bOrder = b.ordem;
      // eslint-disable-next-line no-nested-ternary
      return aOrder < bOrder ? -1 : aOrder > bOrder ? 1 : 0;
    }
    if (rota.carnes) {
      const listaOrderBy = Array.from(rota.carnes);
      listaOrderBy.sort(SortByOrdem);
      setListaRota(listaOrderBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = async () => {
    const listaCarneRota = Array.from(listaRota);

    const pos = listaCarneRota.findIndex(x => x.id === carne.id);
    if (pos !== -1) {
      toast({
        title: 'Adicionar.',
        description: 'Carne ja existe rota',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
    } else {
      setDataView(true);
      listaCarneRota.push(carne);
      setListaRota(listaCarneRota);
    }
  };

  const handleSaveRotaCarne = async () => {
    const listaCarneRota = Array.from(listaRota);
    listaCarneRota.map(async (item, index) => {
      const carneUpdate = {
        id: item.id,
        rotaId: rota.id,
        empresaId: item.empresaId,
        clienteId: item.clienteId,
        ordem: index + 1,
        numeroReferencia: item.numeroReferencia,
        descricaoProdutos: item.descricaoProdutos,
        dataCompra: item.dataCompra,
        dataBaixa: item.dataBaixa,
        valorCompra: item.valorCompra,
      };
      await CarneServices.Update(carneUpdate);
    });

    handleCloseRota();
  };

  const handleSearch = async () => {
    if (empresa) {
      const response = await CarneServices.GetByReferencia(
        textSearch,
        empresa?.id,
      );

      if (response.success && response.resource) {
        const carneSelecionado = JSON.parse(JSON.stringify(response.resource));
        if (carneSelecionado.rotaId > 0) {
          const responseRota = await rotaServices.GetById(
            empresa.id,
            carneSelecionado.rotaId,
          );
          if (responseRota.success && responseRota.resource) {
            const rotaSelecionada = JSON.parse(
              JSON.stringify(responseRota.resource),
            );
            setNomeRota(rotaSelecionada.nomeRota);
            setCarneValido(false);
            toast({
              title: 'Rota',
              description: `Esse carne ja esta na rota ${rotaSelecionada.nomeRota}`,
              status: 'warning',
              duration: 5000,
              isClosable: true,
            });
          }
        } else {
          setCarneValido(true);
        }
        setCarne(carneSelecionado);
        setDataView(true);
      } else {
        toast({
          title: 'Consulta Erro.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleRemove = async (value: Carne, index: number) => {
    setCarneExcluir(value);
    setIndexValue(index);
    setOpen(true);
  };

  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  const handleConfirmar = async () => {
    const listaCarneRota = Array.from(listaRota);
    if (carneExcluir.rotaId) {
      const carneUpdate = {
        id: carneExcluir.id,
        rotaId: null,
        empresaId: carneExcluir.empresaId,
        clienteId: carneExcluir.clienteId,
        ordem: null,
        numeroReferencia: carneExcluir.numeroReferencia,
        descricaoProdutos: carneExcluir.descricaoProdutos,
        dataCompra: carneExcluir.dataCompra,
        dataBaixa: carneExcluir.dataBaixa,
        valorCompra: carneExcluir.valorCompra,
      };
      const response = await CarneServices.Update(carneUpdate);
      if (response.success) {
        listaCarneRota.splice(indexValue, 1);
        setListaRota(listaCarneRota);
      } else {
        toast({
          title: 'Atualização Erro.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      listaCarneRota.splice(indexValue, 1);
      setListaRota(listaCarneRota);
    }
  };

  return (
    <Flex w='full'>
      {open && (
        <Alert
          confirmar={handleConfirmar}
          cancelar={() => setOpen(false)}
          title="Rota"
          message="Deseja realmente remover esse carne da rota?"
          color="orange.300"
        />
      )}
      <Grid
        w="100%"
        templateColumns="repeat(10, 1fr)"
        gap={4}
      >
        <GridItem
          colSpan={3}
          bg="white"
          boxShadow="md"
          borderRadius="sm"
          p="5"
        >
          <Text fontSize="2xl" casing="capitalize">
            {rota.nomeRota}
          </Text>
          <Text fontSize="1xl" casing="capitalize">
            {rota.usuario?.nome}
          </Text>

          <GridItem colSpan={2}>
           
            <Flex w='full' gap='2' align='flex-end' mt='2'>
              <InputControled
                label='Número do carnê'
                value={textSearch}
                onChange={event => setTextSearch(event.target.value)}
              />
              <IconButton
                aria-label="open-search"
                icon={<Icon as={FaSearch} />}
                fontSize="18"
                mb="2"
                variant="link"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={handleSearch}
              />
            </Flex>
          </GridItem>

          {dataView && (
            <Flex direction="column" mt="10" color="gray.900">
              {!carneValido && (
                <Text fontSize="xl" fontWeight="semibold">
                  {`ROTA ${carne.rota.nomeRota}`}
                </Text>
              )}

              <Text fontSize="xl" fontWeight="semibold">
                {`Carne ${carne.numeroReferencia}`}
              </Text>
              <Text fontSize="md" >
                {`${carne.descricaoProdutos} `}
              </Text>
              <Text fontSize="md" fontWeight="normal">
                {currencyFormatter(carne.valorCompra)}
              </Text>

              <Text fontSize="md" fontWeight="normal" mt="2">
                {carne.cliente.nomeCompleto}
              </Text>
              <Text fontSize="sm" fontWeight="normal">
                {`${carne.cliente.logradouro},${carne.cliente.numero}`}
              </Text>
              <Text fontSize="sm" fontWeight="normal">
                {`${carne.cliente.bairro},${carne.cliente.municipio?.nomeMunicipio} - ${carne.cliente.uf}`}
              </Text>

              <Button
                mt="5"
                bg="indigo.400"
                color="white"
                type="button"
                boxShadow="lg"
                isDisabled={!carneValido}
                onClick={() => handleAdd()}
                rightIcon={<Icon as={FaArrowRight} />}
              >
                Adicionar
              </Button>
            </Flex>
          )}
        </GridItem>
        <GridItem
          colSpan={7}
          h={500}
          bg="white"
          boxShadow="lg"
          borderRadius="md"
          p="5"
        >
          <Table
            width={750}
            table-layout="fixed"
            border-collapse="collapse"
            size="sm"
          >
            <Thead display="block">
              <Tr display="block">
                <Th textAlign="left" w={150}>
                  CARNE
                </Th>
                <Th textAlign="left" w={150}>
                  CLIENTE
                </Th>
                <Th textAlign="left" w={150}>
                  PRODUTO
                </Th>
                <Th textAlign="right" w={150}>
                  VALOR
                </Th>
                <Th textAlign="right" w={150}>
                  EXCLUIR
                </Th>
              </Tr>
            </Thead>
            <Tbody display="block" width={750} overflow="auto" h={370}>
              {listaRota.map((carneItem, index) => {
                return (
                  <>
                    <Tr
                      key={carneItem.id}
                      display="block"
                      fontSize="xs"
                      color="gray.900"
                      _hover={{
                        bg: 'teal.100',
                      }}
                    >
                      <Td textAlign="left" w={150} fontSize="xs">
                        {carneItem.numeroReferencia}
                      </Td>
                      <Td textAlign="left" w={150} fontSize="xs">
                        {carneItem.cliente?.nomeCompleto}
                      </Td>
                      <Td textAlign="left" w={150} fontSize="xs">
                        {carneItem.descricaoProdutos}
                      </Td>
                      <Td textAlign="left" w={150} fontSize="xs" isNumeric>
                        {currencyFormatter(carneItem.valorCompra)}
                      </Td>
                      <Td textAlign="right" w={150} fontSize="xs">
                        <Icon
                          as={FaTrash}
                          color="red.300"
                          cursor="pointer"
                          onClick={() => handleRemove(carneItem, index)}
                        />
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          </Table>
          <Flex>
            <Button
              mt="2"
              bg="purple.400"
              color="white"
              type="button"
              boxShadow="lg"
              onClick={handleSaveRotaCarne}
              leftIcon={<Icon as={FaSave} />}
            >
              Salvar
            </Button>
            <Button
              type="button"
              onClick={handleCloseRota}
              ml="5"
              mt="2"
              bg="orange.300"
              color="orange.600"
              leftIcon={<Icon as={FaArrowLeft} />}
              _hover={{
                bg: 'orange.400',
              }}
            >
              Voltar
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
};
export default RotaCarnes;
