import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  FaArrowDown,
  FaChevronDown,
  FaChevronRight,
  FaQuestionCircle,
} from 'react-icons/fa';
import auth from '../../contexts/auth';
import empresaContext from '../../contexts/empresa';
import * as AcessoServices from '../../services/acesso';
import ListaEmpresa from '../list/empresa';
import acessoInterface from '../../interface/acesso';
import empresaInterface from '../../interface/empresa';
import usuarioInterface from '../../interface/usuario';
import { useNavigate } from 'react-router-dom';

interface ProfileProps {
  // eslint-disable-next-line react/require-default-props
  showProfileData?: boolean;
}

const Profile = ({ showProfileData }: ProfileProps) => {
  const { usuario, signOut } = useContext(auth);
  const { changeEmpresa, empresa } = useContext(empresaContext);
  const [acessoLista, setAcessoLista] = useState<acessoInterface[]>([]);
  const toast = useToast();
  const navigate = useNavigate();

  const [openLista, setOpenLista] = useState(false);

  const getAcesso = async (
    value: usuarioInterface,
    valueEmpresa: empresaInterface,
  ) => {
    if (empresa) {
      const response = await AcessoServices.GetById(value.id);
      if (response.success && response.resource) {
        const acessos = Array.from<acessoInterface>(
          JSON.parse(JSON.stringify(response.resource)),
        );
        const acessoUsuario = acessos.find(item => {
          return item.empresa.id === valueEmpresa.id;
        });
        if (acessoUsuario && !acessoUsuario.administrador) {
          toast({
            title: 'Atenticação usuário.',
            description: 'Usuario sem permissão de acesso!',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          signOut();
        }
      } else {
        toast({
          title: 'Atenticação usuário.',
          description: response.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        signOut();
      }
    }
  };

  useEffect(() => {
    const getEmpresa = async () => {
      if (usuario) {
        const response = await AcessoServices.GetById(usuario.id);
        if (response.success && response.resource) {
          await setAcessoLista(JSON.parse(JSON.stringify(response.resource)));
          const listaAcesso = JSON.parse(JSON.stringify(response.resource));

          if (listaAcesso.length > 1) {
            setOpenLista(true);
          } else {
            if (
              listaAcesso[0].empresa.ativo === false ||
              listaAcesso[0].empresa.suspenso
            ) {
              toast({
                title: 'Seleção de empresa.',
                description: 'A empresa não esta ativa ou suspensa!',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              signOut();
              return;
            }
            await getAcesso(usuario, listaAcesso[0].empresa);
            await changeEmpresa(listaAcesso[0].empresa);
          }
        }
      }
    };
    getEmpresa();
  }, []);

  const handleClose = async (values: empresaInterface) => {
    if (!values.id) {
      return;
    }
    if (values.ativo === false || values.suspenso) {
      toast({
        title: 'Seleção de empresa.',
        description: 'A empresa não esta ativa ou suspensa!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      signOut();
      return;
    }
    await changeEmpresa(values);
    if (usuario) {
      await getAcesso(usuario, values);
      navigate('/');
    }
    setOpenLista(false);
  };

  const handleChangeEmpresa = () => {
    if (acessoLista.length > 1) {
      setOpenLista(true);
    } else {
      toast({
        title: 'Alterar Empresa.',
        description: 'Você não possui outra empresa para alterar',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOpenHelp = () => {
    window.open(
      'https://www.bravetech.com.br/download/supercrediariohelp.pdf',
      '_blank',
    );
  };

  return (
    <>
      <Flex align="center" ml="auto">
        <Flex align="center" mr="2">
          <Avatar
            size="sm"
            name={usuario?.nome}
            bg="emerald.400"
            color="emerald.800"
            boxShadow="md"
          />
        </Flex>
        {showProfileData && (
          <Menu isLazy>
            {({ isOpen }) => (
              <>
                <MenuButton>
                  <Flex align="center" justify="center">
                    <Flex mr="4" direction="column" textAlign="left">
                      <Text
                        casing="capitalize"
                        fontWeight="semibold"
                        fontSize="sm"
                      >
                        {usuario?.nome}
                      </Text>

                      <Text casing="capitalize" fontSize="xs">
                        {empresa?.razaoSocial}
                      </Text>
                    </Flex>
                    <Icon
                      as={FaChevronRight}
                      transform={isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
                      transition='0.3s ease'
                      fontSize="x-small"
                    />
                  </Flex>
                </MenuButton>
                <MenuList>
                  {acessoLista.length > 1 && (
                    <MenuItem onClick={handleChangeEmpresa}>
                      <Text casing="capitalize" fontSize="smaller">
                        Alterar Empresa
                      </Text>
                    </MenuItem>
                  )}

                  <MenuItem onClick={handleOpenHelp}>
                    <Text casing="capitalize" fontSize="smaller">
                      Ajuda
                    </Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        )}
      </Flex>
      {openLista && (
        <ListaEmpresa
          title="Lista de empresas"
          empresas={acessoLista}
          handleClose={handleClose}
          isOpen={openLista}
        />
      )}
    </>
  );
};
export default Profile;
