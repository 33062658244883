import React, { ReactNode } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import usuarioInterface from '../../interface/usuario';

interface SimpleListProps {
  title: string;
  usuarios: usuarioInterface[];
  handleClose(usuario: usuarioInterface): void;
  isOpen: boolean;
}

const SimpleList: React.FC<SimpleListProps> = ({
  title,
  usuarios,
  handleClose,
  isOpen,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose({} as usuarioInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="auto" spacing="2" px="2" h="96">
            {usuarios.map(item => {
              return (
                <ListItem
                  onClick={() => handleClose(JSON.parse(JSON.stringify(item)))}
                  key={item.id}
                  bg="gray.100"
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="base"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                >
                  <HStack px="1">
                    <Avatar
                      name={item.nome}
                      bg="emerald.400"
                      color="emerald.800"
                      boxShadow="md"
                      mx="2"
                      my="2"
                    />
                    <Flex flexDir='column'>
                      <Text fontWeight="bold"  fontSize="small">
                        {item.nome}
                      </Text>
                      <Text  fontSize="small">
                        {item.email}
                      </Text>
                    </Flex>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default SimpleList;
