import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';
import { FieldError, Path, UseFormRegister } from 'react-hook-form';

interface InputProps extends ChakraInputProps {
  id: Path<any>
  register: UseFormRegister<any>
  label:string;
  error?: FieldError;
}

export const Input =({ register,id,label, error, ...rest }:InputProps) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={id} fontSize='sm' >
          {label}
        </FormLabel>
      )}
      <ChakraInput
        {...register(id)}
        borderRadius="sm"
        focusBorderColor="emerald.300"
        variant="filled"
        bg="gray.100"
        color="gray.600"
        boxShadow='sm'
        _hover={{
          color: 'emerald.900',
          borderWidth:'1px',
        }}
        _focus={{
          bg: 'gray.100',
          borderWidth:'1px',
        }}
        autoComplete="off"
        size="sm"
        {...rest}
      />
      {!!error && <FormErrorMessage fontSize='xs' color='red.300'>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

