import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import * as UpLoadServices from '../../services/import';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Flex,
  Text,
  Button,
  List,
  ListIcon,
  ListItem,
  useToast,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { FaPlus, FaSync } from 'react-icons/fa';
import RotaSelect from '../../components/selectRota';

import * as RotaServices from '../../services/rota';

import IMG from '../../assets/csv.png';
import ErrorFiles from './error';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  marginBottom: 8,
  marginRight: 8,
  height: 60,
  padding: 4,
  width: '100%',
  alignItems: 'center',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: 32,
  marginRight: 16,
};

const ImportCarnes = ({ empresaId }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    maxFiles: 1,
    accept: '.csv',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const [files, setFiles] = useState([]);
  const [listaErro, setListaErro] = useState(null);
  const [isError, setIsError] = useState(false);
  const [rotaFound, setRotaFound] = useState(false);
  const [rota, setRota] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isDragActive, isDragAccept],
  );

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={IMG} style={img} />
      </div>
      <Text>{file.name}</Text>
    </div>
  ));

  useEffect(() => {
    const getRota = async (rotaId, empresaId) => {
      const response = await RotaServices.GetById(rotaId, empresaId);
      if (response.success && response.resource) {
        setRota(JSON.parse(JSON.stringify(response.resource)));
      }
    };
    const rotaStorage = localStorage.getItem('rota');

    if (rotaStorage) {
      getRota(parseInt(rotaStorage, 10), empresaId);
      setRotaFound(true);
    }
  }, []);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      setListaErro(null);
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const handleCloseSelectRota = rotaRetorno => {
    if (rotaRetorno.id) {
      setRota(rotaRetorno);
      setIsOpen(false);
      setRotaFound(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleUpload = async () => {
    const rotaStorage = localStorage.getItem('rota');
    if (!rotaStorage && !rota) {
      toast({
        title: 'Importação de Carne.',
        description: 'Defina uma rota para importar o carne',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    const file = files[0];
    if (!file) {
      toast({
        title: 'Importação de Carne.',
        description: 'Selecione o arquivo para importar',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    const data = new FormData();
    data.append('file', file, file.name);
    const response = await UpLoadServices.ImportCarnes(data, empresaId, rota);

    if (response.success === undefined) {
      toast({
        title: 'Importação de Carne.',
        description: 'Carnes não foram importados, erro interno!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (response.success && response.resource) {
      setListaErro(response);
      setIsError(true);
    } else {
      toast({
        title: 'Importação de Carne.',
        description: 'Carnes importados com sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setFiles([]);
    }
  };
  return (
    <Flex direction="column">
      <Flex align="center">
        {rotaFound ? (
          <>
            <Text
              casing="uppercase"
              fontSize="sm"
              color="gray.600"
              fontWeight="semibold"
              mb="4"
            >
              {`Rota: ${rota ? rota.nomeRota : ''}`}
            </Text>
          </>
        ) : (
          <>
            <Text
              casing="uppercase"
              fontSize="sm"
              color="gray.600"
              fontWeight="semibold"
              mb="4"
            >
              Rota não definada
            </Text>
          </>
        )}

        <IconButton
          ml="4"
          mb="4"
          aria-label="trocar de rota"
          onClick={rota ? () => setRota(null) : () => setIsOpen(true)}
          icon={<Icon as={rota ? FaSync : FaPlus} />}
          colorScheme="purple"
          borderRadius="50%"
          size="xs"
        />
      </Flex>
      <Text>Importação de carnes</Text>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Clique aqui e selecione um arquivo</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </div>
      {listaErro && (
        <>
          <Text fontSize="sm">Estes carnes não foram imporados</Text>
          <ErrorFiles listaErros={listaErro} />
        </>
      )}
      <Button
        onClick={handleUpload}
        colorScheme="teal"
        variant="link"
        mr="8"
        mt="8"
      >
        ENVIAR
      </Button>
      {isOpen && (
        <RotaSelect
          title="Selecione a Rota"
          isOpen={isOpen}
          onClose={handleCloseSelectRota}
        />
      )}
    </Flex>
  );
};
export default ImportCarnes;
