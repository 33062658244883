import React from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';

interface buttonCustomProps extends ButtonProps {
  children: string;
}

const ButtonBase = ({ children, ...rest }: buttonCustomProps) => {
  return (
    <Button
      w='full'
      size="sm"
      alignItems="center"
      fontSize="sm"
      bg="emerald.400"
      color="emerald.800"
      borderRadius="sm"
      boxShadow="md"
      _hover={{
        filter: 'brightness(0.9)',
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
export default ButtonBase;
