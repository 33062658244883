
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps,
} from '@chakra-ui/react';
import { FieldError, Path, UseFormRegister } from 'react-hook-form';

import Municipio from '../../interface/municipio';

interface Props extends SelectProps {
  id: Path<any>
  register: UseFormRegister<any>
  label: string;
  municipios: Municipio[];
  error?: FieldError;
}

export const SelectMunicipio = ({ id,register, label, error, municipios, ...rest }:Props) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={id} fontSize="sm" pl={10} fontWeight="light">
          {label}
        </FormLabel>
      )}
      <ChakraSelect
        {...register(id)}
        placeholder="Selecione Municipio"
        borderRadius="md"
        focusBorderColor="emerald.500"
        variant="filled"
        bgColor="gray.200"
        boxShadow="lg"
        color="gray.600"
        _hover={{
          color: 'emerald.900',
        }}
        _focus={{
          bg: 'gray.100',
          color: 'emerald.600',
        }}
        size="sm"
        {...rest}
      >
        {municipios.map(item => {
          return (
            <option key={item.id} value={item.id}>
              {item.nomeMunicipio}
            </option>
          );
        })}
      </ChakraSelect>
      {!!error && <FormErrorMessage pl={10}>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

