import { Flex } from "@chakra-ui/react";
import Header from "../components/header";
import SideBar from "../components/sideBar";
interface Props{
  children:React.ReactNode;
}

export function Layout({children}:Props){
  return(
    <Flex
    h='full'
    w='full'
    maxW='8xl'
    minW='sm'
    mx='auto'
    flexDirection='column'
    position='relative'
    px='4'
   
    > 
        <Header  />
        <Flex  gap='2'>
        <SideBar />  
        <Flex w='full' px='4'>
          {children}
        </Flex>
        </Flex>
  </Flex>
  )
}