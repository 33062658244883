import React, { ReactNode } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import acessoInterface from '../../interface/acesso';
import empresaInterface from '../../interface/empresa';

interface SimpleListProps {
  title: string;
  empresas: acessoInterface[];
  handleClose(empresa: empresaInterface): void;
  isOpen: boolean;
}

const EmpresaList: React.FC<SimpleListProps> = ({
  title,
  empresas,
  handleClose,
  isOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose({} as empresaInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="scroll" spacing="2" px="2">
            {empresas.map(item => {
              return (
                <ListItem
                  onClick={() => handleClose(item.empresa)}
                  key={item.id}
                  bg="gray.100"
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="md"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                >
                  <HStack p='4'>
                    <Avatar
                      size="md"
                      name={item.empresa.razaoSocial}
                      bg="emerald.400"
                      color="emerald.800"
                      boxShadow="md"
                     
                    />
                    <Stack justify="space-around">
                      <Text
                        fontWeight="semibold"
                        color="gray.600"
                        fontSize="xs"
                        casing="uppercase"
                      >
                        {item.empresa.razaoSocial}
                      </Text>
                      <Text color="gray.600" fontSize="xs">
                        {`CNPJ: ${item.empresa.cnpj}`}
                      </Text>
                    </Stack>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default EmpresaList;
