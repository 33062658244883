import React, { ReactNode } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import clienteInterface from '../../interface/cliente';

interface SimpleListProps {
  title: string;
  clientes: clienteInterface[];
  handleClose(cliente: clienteInterface): void;
  isOpen: boolean;
}

const ClienteList: React.FC<SimpleListProps> = ({
  title,
  clientes,
  handleClose,
  isOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose({} as clienteInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="auto" spacing="2" px="2" h="96">
            {clientes.map(item => {
              return (
                <ListItem
                  onClick={() => handleClose(JSON.parse(JSON.stringify(item)))}
                  key={item.id}
                  bg="gray.100"
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="base"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                >
                  <HStack px="1">
                    <Avatar
                      size="sm"
                      name={item.nomeCompleto}
                      bg="teal.200"
                      color="teal.600"
                      boxShadow="lg"
                      mx="2"
                      my="2"
                    />
                    <Stack mx="2" my="2">
                      <Text
                        fontWeight="bold"
                        color="gray.600"
                        fontSize="small"
                        casing="capitalize"
                      >
                        {item.nomeCompleto.toLowerCase()}
                      </Text>
                      <Text color="gray.500" fontSize="small">
                        {item.cpf}
                      </Text>
                    </Stack>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ClienteList;
