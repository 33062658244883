import React from 'react';
import { Flex, Icon, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import Profile from './profile';
import Logo from './logo';
import { useSideBarDrawer } from '../../contexts/drawer';
import SideBar from '../sideBar';

const Header = () => {
  const { onOpen } = useSideBarDrawer();
  const isWideVersion = useBreakpointValue({ base: false, lg: true });
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Flex
      as="header"
      h="12"
      align="center"
      justify="space-between"
      w='full'
      transition="all 0.2s ease"
    >
      {!isWideVersion && (
        <IconButton
          aria-label="open-navigation"
          icon={<Icon as={FaBars} />}
          fontSize="24"
          variant="unstyled"
          onClick={onOpen}
          mr="2"
        />
      )}
      <Logo />
      <Profile showProfileData={isWideVersion} />
    </Flex>
  );
};
export default Header;
