import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { FaEdit, FaPlus, FaSearch, FaTrash } from 'react-icons/fa';

import * as ClienteService from '../../services/cliente';
import ClienteInterface from '../../interface/cliente';
import * as CarneServices from '../../services/carne';

import CreateCliente from './create';
import ClienteView from './view';
import Loading from '../../components/loading';

import Carne from '../../interface/carne';
import Button from '../../components/button';
import SideBar from '../../components/sideBar';
import ClienteList from '../../components/list/cliente';
import TodosClientes from '../../components/list/todosClientes';

import empresaContext from '../../contexts/empresa';
import ParcelaItens from '../../components/list/parcelas';
import ListaCarne from '../../components/list/carne';
import ImportFiles from '../../components/uploadClientes/index.js';
import { InputControled } from '../../components/Form/inputControled';

const Cliente: React.FC = () => {
  const toast = useToast();
  const { empresa } = useContext(empresaContext);
  const [textSearch, setTextSearch] = useState('');
  const [openCreate, setOpenCreate] = useState(false);
  const [typeSearch, setTypeSearch] = useState('');
  const [openViewData, setOpenViewData] = useState(false);
  const [openListaCliente, setOpenListacliente] = useState(false);
  const [erroCPF, setErroCPF] = useState(false);
  const [cpf, setCPF] = useState('');
  const [openSpinner, setOpenSpinner] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [empresaId, setEmpresaId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [cliente, setCliente] = useState<ClienteInterface>(
    {} as ClienteInterface,
  );
  const [clienteLista, setClienteLista] = useState<ClienteInterface[]>([]);
  const [listaCarne, setListaCarne] = useState<Carne[]>([]);
  const [cardEdit, setCardEdit] = useState(false);
  const [clienteView, setClienteView] = useState(false);
  const [todosClientes, setTodosClientes] = useState(false);
  const elementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (empresa) {
      setEmpresaId(empresa.id);
    }
  }, []);

  async function handleCloseListaCliente(clienteNovo: ClienteInterface) {
    if (clienteNovo.id !== undefined) {
      await setCliente(clienteNovo);
      setClienteView(true);
      setOpenListacliente(false);
      setTodosClientes(false);
    } else {
      setOpenListacliente(false);
      setTodosClientes(false);
    }
  }

  function handleNew() {
    setCliente({} as ClienteInterface);
    setOpenCreate(true);
  }
  function handleEdit() {
    setClienteView(false);
    setOpenCreate(true);
  }
  async function handleDelete(value: ClienteInterface) {
    if (value) {
      const response = await ClienteService.Delete(value);

      if (response.success && response.resource) {
        setCliente({} as ClienteInterface);
        setClienteView(false);
        toast({
          title: 'Cliente.',
          description: 'Excluído com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Consulta Erro.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  }

  const handleTodosClientes = async () => {
    if (empresa) {
      setOpenLoading(true);
      const response = await ClienteService.GetAll(empresa.id);

      if (response.success && response.resource) {
        await setClienteLista(JSON.parse(JSON.stringify(response.resource)));
        setTodosClientes(true);
      } else {
        toast({
          title: 'Consulta Erro.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
      setOpenLoading(false);
    }
  };

  const isNumber = (value: string) => {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(parseFloat(value)) && isFinite(parseFloat(value));
  };

  const handleSearch = async () => {
    let response;

    switch (typeSearch) {
      case 'nome':
        if (!isNumber(typeSearch)) {
          if (empresa) {
            setOpenLoading(true);
            response = await ClienteService.GetByNome(empresa?.id, textSearch);
            if (response.success && response.resource) {
              await setClienteLista(
                JSON.parse(JSON.stringify(response.resource)),
              );
              setOpenLoading(false);
              setOpenListacliente(true);
            } else {
              setOpenLoading(false);
              toast({
                title: 'Pesquisa Cliente.',
                description: response.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          }
        } else {
          toast({
            title: 'Pesquisa Cliente.',
            description: 'Digite um nome para a pesquisa!',
            status: 'warning',
            duration: 2000,
            isClosable: true,
          });
        }
        break;
      case 'cpf':
        if (textSearch) {
          if (empresa) {
            setOpenLoading(true);
            response = await ClienteService.GetByCPF(empresa.id, textSearch);
            if (response.success && response.resource) {
              setOpenLoading(false);
              await setCliente(JSON.parse(JSON.stringify(response.resource)));
              setClienteView(true);
              setTextSearch('');
            } else {
              setOpenLoading(false);
              toast({
                title: 'Pesquisa Cliente.',
                description: response.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          }
        } else {
          setOpenLoading(false);
          toast({
            title: 'Pesquisa Cliente.',
            description: 'Digite um CPF para a pesquisa!',
            status: 'warning',
            duration: 2000,
            isClosable: true,
          });
        }
        break;
      case '':
        if (textSearch) {
          if (empresa) {
            setOpenLoading(true);
            response = await ClienteService.GetByCPF(empresa.id, textSearch);
            if (response.success && response.resource) {
              setOpenLoading(false);
              await setCliente(JSON.parse(JSON.stringify(response.resource)));
              setClienteView(true);
              setTextSearch('');
            } else {
              setOpenLoading(false);
              toast({
                title: 'Pesquisa Cliente.',
                description: response.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          }
        } else {
          setOpenLoading(false);
          toast({
            title: 'Pesquisa Cliente.',
            description: 'Digite um CPF para a pesquisa!',
            status: 'warning',
            duration: 2000,
            isClosable: true,
          });
        }
        break;
      default:
        break;
    }
  };
  async function handleCloseTodosClientes(clienteNovo: ClienteInterface) {
    if (clienteNovo.id !== undefined) {
      setTextSearch(clienteNovo.cpf);
      setTypeSearch('cpf');

      setTodosClientes(false);
      if (elementRef.current !== null) {
        elementRef.current.focus();
      }
    } else {
      setTodosClientes(false);
    }
  }

  const handleCloseCreate = async (value: ClienteInterface) => {
    if (value.id !== undefined) {
      setCliente(value);
      if (empresa) {
        const response = await ClienteService.GetByCPF(empresa.id, value.cpf);
        if (response.success && response.resource) {
          await setCliente(JSON.parse(JSON.stringify(response.resource)));
          setClienteView(true);
        } else {
          toast({
            title: 'Pesquisa Cliente.',
            description: response.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }
      setClienteView(true);
    } else {
      setClienteView(false);
      setOpenCreate(false);
    }
  };

  const handleCloseView = () => {
    setClienteView(false);
    setCliente({} as ClienteInterface);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Flex w="full">
    {clienteView ? (
        <ClienteView
          cliente={cliente}
          handleClose={handleCloseView}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ) : (
        <Flex w="full">
        {openCreate ? (
            <CreateCliente cliente={cliente} handleClose={handleCloseCreate} />
          ) : (
            <Flex flexDir='column'  w='full' borderRadius='sm' bg="white" p="8" boxShadow="md" h='80vh'>
            <Flex gap='4' align="center" w='full'>
                <Text fontSize="2xl" >
                  Cliente
                </Text>

                <Flex
                  w='full'
                  align="center"
                  color="gray.500"
                  position="relative"
                  bg="gray.100"
                  borderRadius="md"
                  h='8'
                  px='4'
                  py='2'
                >
                  <Select
                    variant="unstyled"
                    placeholder="Pesquisa por"
                    value={typeSearch}
                    onChange={event => setTypeSearch(event.target.value)}
                    w='52'

                  >
                    <option value="nome">Nome</option>
                    <option value="cpf">CPF</option>
                  </Select>

                  <InputControled
                    label=''
                    onKeyDown={handleKeyPress}
                    value={textSearch}
                    onChange={event => setTextSearch(event.target.value)}
                    border='none'

                  />
                  <IconButton
                    aria-label="open-search"
                    icon={<Icon as={FaSearch} />}
                    fontSize="18"
                    variant="link"
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={handleSearch}
                  />
                </Flex>
                <Button
                  type="button"
                  onClick={handleNew}
                  leftIcon={<Icon as={FaPlus} />}
                  w='44'
                >
                  Criar Novo
                </Button>
              </Flex>
              <SimpleGrid
                columns={cliente.carnes?.length > 0 ? 2 : 1}
                spacing={10}
              >
                <Grid
                  h={350}
                  templateRows="repeat(10, 1fr)"
                  templateColumns="repeat(6, 1fr)"
                  gap={3}
                >
                  <GridItem colSpan={6}>
                  <Flex align="center" justify="center" direction="column" mt='4'>
                      <Text>Utilize o campo acima para consulta</Text>
                      <Text>são 2 tipos de consulta por CPF ou Nome</Text>
                      <Text>caso não selecione o padão é por CPF</Text>

                      <Text
                        color="indigo.600"
                        cursor="pointer"
                        onClick={handleTodosClientes}
                        mt="4"
                      >
                        Clique aqui para mostrar todos clientes
                      </Text>
                    </Flex>
                  </GridItem>
                </Grid>
              </SimpleGrid>
            </Flex>
          )}
        </Flex>
      )}
      {openListaCliente && (
        <ClienteList
          clientes={clienteLista}
          title="Lista de Clientes"
          handleClose={handleCloseListaCliente}
          isOpen={openListaCliente}
        />
      )}
      {todosClientes && (
        <TodosClientes
          title="Lista de Clientes"
          clientes={clienteLista}
          handleClose={handleCloseTodosClientes}
          isOpen={!!todosClientes}
        />
      )}
      {openLoading && <Loading isOpen={openLoading} text="Aguarde!" />}
    </Flex>
  );
};

export default Cliente;
