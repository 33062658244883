import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';
import { errorInternet } from '../usuario';

export async function Insert(
  data: any,
  mandarSms: boolean,
): Promise<baseResponse> {
  return api
    .post('/pagamento', JSON.stringify(data), {
      params: {
        mandarSms,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}
export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/pagamento/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function Delete(data: any): Promise<baseResponse> {
  return api
    .delete(`/pagamento/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function GetAll(): Promise<baseResponse> {
  return api
    .get('/pagamento')
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function GetById(data: any): Promise<baseResponse> {
  return api
    .get(`/pagamento/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function GetByParcelaId(parcelaId: number): Promise<baseResponse> {
  return api
    .get(`/pagamento/consultar`, {
      params: {
        parcelaId,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}
