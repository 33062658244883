import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from '../pages/login';
import RedefinirSenha from '../pages/redefinirSenha';
import TrocarSenha from '../pages/trocarSenha';

const Autenticacao: React.FC = () => {
  const navigate = useNavigate()
  function NoMatch() {
    navigate('/');
    return <></>;
  }
  return (
    <Routes>
      <Route  path="/" element={ <Login />} />
      <Route  path="/trocar-senha" element={<TrocarSenha />}/>
      <Route path="/redefinir-senha/:token" element={<RedefinirSenha />}/>
      <Route path="*" element={ <Login />} />
    </Routes>
  );
};
export default Autenticacao;
