import React, { ReactNode } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import acertoInterface from '../../interface/acerto';
import empresaInterface from '../../interface/empresa';

interface SimpleListProps {
  title: string;
  notaAcerto: acertoInterface[];
  handleClose(): void;
  isOpen: boolean;
}

const NotaAcertoList: React.FC<SimpleListProps> = ({
  title,
  notaAcerto,
  handleClose,
  isOpen,
}) => {
  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }
  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="scroll" spacing="2" px="2" h="96">
            {notaAcerto.map((item: acertoInterface) => {
              return (
                <ListItem
                  key={item.id}
                  bg="gray.100"
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="base"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                  px="2"
                  py="2"
                >
                  <Stack>
                    <Text
                      fontWeight="bold"
                      color="gray.600"
                      fontSize="small"
                      casing="capitalize"
                    >
                      {item.pagamento.parcela?.carne?.cliente.nomeCompleto}
                    </Text>
                    <Text color="gray.600" fontSize="small" casing="capitalize">
                      Carne:
                      {` ${item.pagamento.parcela?.carne?.numeroReferencia}`}
                    </Text>
                    <Flex justify="space-between">
                      <Text
                        color="gray.600"
                        fontSize="small"
                        casing="capitalize"
                      >
                        Parcela:
                        {` ${item.pagamento.parcela?.numeroParcela}`}
                      </Text>
                      <Text color="gray.500" fontSize="small">
                        Valor:
                        {` ${currencyFormatter(item.pagamento.valorPago)}`}
                      </Text>
                    </Flex>
                  </Stack>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default NotaAcertoList;
