import React from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import rotaInterface from '../../interface/rota';

interface SimpleListProps {
  title: string;
  rotas: rotaInterface[];
  handleClose(rota: rotaInterface): void;
  isOpen: boolean;
}

const RotaList: React.FC<SimpleListProps> = ({
  title,
  rotas,
  handleClose,
  isOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose({} as rotaInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="auto" spacing="2" px="2" h="96">
            {rotas.map(item => {
              return (
                <ListItem
                  onClick={() => handleClose(JSON.parse(JSON.stringify(item)))}
                  key={item.id}
                  bg="gray.100"
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="base"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                >
                  <HStack px="1">
                    <Avatar
                      size="sm"
                      name={item.nomeRota}
                      bg="teal.200"
                      color="teal.600"
                      boxShadow="lg"
                      mx="2"
                      my="2"
                    />
                    <Stack flex="1" mx="2" my="2">
                      <Text fontWeight="bold" color="gray.600" fontSize="small">
                        {item.nomeRota}
                      </Text>
                      <Text
                        fontWeight="light"
                        color="gray.600"
                        fontSize="small"
                      >
                        {`Valor a receber R$ ${item.valorTotalAReceber.toFixed(
                          2,
                        )}`}
                      </Text>
                    </Stack>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default RotaList;
