import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import Main from '../pages/main';
import Cliente from '../pages/cliente';
import CreateCliente from '../pages/cliente/create';

import Carne from '../pages/carne';
import Usuario from '../pages/usuario';
import Rota from '../pages/rota';
import Pontos from '../pages/pontos';
import Acerto from '../pages/acerto';

import Header from '../components/header';
import Importar from '../pages/importar';
import Baixar from '../pages/baixar';
import SideBar from '../components/sideBar';

const AppRoutes: React.FC = () => {
  function NoMatch() {
    const location = useLocation();
    return (
      <div>
        <h3>
          No match for
          <code>{location.pathname}</code>
        </h3>
      </div>
    );
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/cliente" element={<Cliente />}/>
        <Route path="/carne" element={<Carne />}/>          
        <Route path="/rota" element={<Rota />} />
        <Route path="/pontos" element={<Pontos />}/>
        <Route path="/acerto" element={<Acerto />} />
        <Route path="/usuario" element={<Usuario />} />
        <Route path="/importar" element={<Importar />}/>
        <Route path="/baixar" element={<Baixar />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
};
export default AppRoutes;
