import React, { ElementType } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

import {
  Flex,
  Icon,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Text,
} from '@chakra-ui/react';

interface NavLinkProps extends ChakraLinkProps {
  icon: ElementType;
  children: string;
  to: string;
}

const NavLink = ({ icon, children, to, ...rest }: NavLinkProps) => {
  const location = useLocation().pathname;
  let active = false;
  if (to === location) {
    active = true;
  }
  return (
    <Link to={to}>
      <Flex
        bg={active ? 'indigo.400' : 'gray.50'}
        w="32"
        pl="4"
        h="8"
        transition="0.2s"
        borderRadius="sm"
        boxShadow={active ? 'md' : 'none'}
      >
        <ChakraLink
          display="flex"
          alignItems="center"
          color={active ? 'white' : 'gray.400'}
          {...rest}
          gap='4'
          _hover={{textDecor:'none'}}
          
        >
          <Icon as={icon} fontSize='lg' />
          <Text fontWeight={active ? 'semibold' : ''} fontSize="sm">
            {children}
          </Text>
        </ChakraLink>
      </Flex>
    </Link>
  );
};
export default NavLink;
