import axios, { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';

import Cliente from '../../interface/cliente';
import { errorInternet } from '../usuario';

export async function Insert(data: any): Promise<baseResponse> {
  return api
    .post('/cliente', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      const erro = {
        success: 'false',
        message: error.message,
        resource: '',
      };
      return erro;
    });
}

export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/cliente/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      const erro = {
        success: 'false',
        message: error.message,
        resource: '',
      };
      return erro;
    });
}

export async function Delete(data: Cliente): Promise<baseResponse> {
  return api
    .delete(`/cliente/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      const erro = {
        success: 'false',
        message: error.message,
        resource: '',
      };
      return erro;
    });
}

export async function GetAll(empresaId: number): Promise<baseResponse> {
  return api
    .get('/cliente/consulta', {
      params: {
        empresaId,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetById(clienteId: number): Promise<baseResponse> {
  return api
    .get(`/cliente/`, {
      params: {
        clienteId,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      const erro = {
        success: 'false',
        message: error.message,
        resource: '',
      };
      return erro;
    });
}

export async function GetByCPF(
  empresaId: number,
  cpf: string,
): Promise<baseResponse> {
  return api
    .get(`/cliente/consulta`, {
      params: {
        empresaId,
        cpf,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        const erro = {
          success: false,
          message: error.message,
          resource: '',
        };
        return erro;
      }
      return error.response?.data;
    });
}

export async function GetByNome(
  empresaId: number,
  nome: string,
): Promise<baseResponse> {
  return api
    .get(`/cliente/consulta`, {
      params: { empresaId, nome },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      const erro = {
        success: 'false',
        message: error.message,
        resource: '',
      };
      return erro;
    });
}
