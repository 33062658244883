import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    Heading: 'Montserrat',
    body: 'Montserrat',
  },

  colors: {
    gray: {
      '50': '#F5F7FE',
    },
    indigo:{
      '50': '#eef2ff',
      '100':'#e0e7ff',
      '200': '#c7d2fe',
      '300': '#a5b4fc',
      '400': '#818cf8',
      '500': '#6366f1',
      '600': '#4f46e5',
      '700': '#4338ca',
      '800': '#3730a3',
      '900': '#312e81'
    },
    emerald:{
      '50': '#ecfdf5',
      '100':'#d1fae5',
      '200': '#a7f3d0',
      '300': '#6ee7b7',
      '400': '#34d399',
      '500': '#10b981',
      '600': '#059669',
      '700': '#047857',
      '800': '#065f46',
      '900': '#064e3b'
    }
  },

  styles: {
    global: {
      body: {
        bg: 'gray.50',
        color: 'gray.600',
      },
    },
  },
});
export default theme;
