import React, { useEffect, useState, useContext } from 'react';
import { Box, Flex, Grid, GridItem, useToast, Text } from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import * as UsuarioService from '../../services/usuario';
import * as AcessoService from '../../services/acesso';
import empresaContext from '../../contexts/empresa';

import { Input } from '../../components/Form/Input';
import { CheckBox } from '../../components/Form/CheckBox';
import Button from '../../components/button';
import Acesso from '../../interface/acesso';
import usuarioInterface from '../../interface/usuario';

const userFormSchema = yup.object().shape({
  nome: yup
    .string()
    .required('Nome obrigatório')
    .min(3, 'nome deve ter o minimo 3 caracteres'),

  codigoAtivacao: yup.string().required('Codigo é Obrigatório'),

  email: yup
    .string()
    .required('e-mail obrigatório')
    .email('Digite um email valido'),

  senha: yup
    .string()
    .required('senha obrigatório')
    .min(6, 'senha deve ter o minimo 6 caracteres'),
  senhaConfirmada: yup
    .string()
    .oneOf([yup.ref('senha')], 'as senhas precisam ser iguais'),
  telefone: yup.string(),
  celular: yup.string(),
  administrador: yup.boolean(),
  ativo: yup.boolean(),
  cobrador: yup.boolean(),
  acesso: yup.object(),
});

interface Props {
  usuario: usuarioInterface;
  acesso: Acesso;
  onClose(usuario: usuarioInterface): void;
}
const UsuarioCreate = ({ usuario, onClose, acesso }:Props) => {
  const toast = useToast();
  const { empresa } = useContext(empresaContext);

  const [edit, setEdit] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState:{errors,isSubmitting},
  } = useForm({
    resolver: yupResolver(userFormSchema),
  });

  const handleInsertUser = async () => {
    const  data = getValues()
    const acessoInsert = {
      empresaId: empresa!.id,
      administrador: getValues('administrador'),
      cobrador: getValues('cobrador'),
      codigoAtivacao: getValues('codigoAtivacao'),
    };
    data.acesso = acessoInsert;
    const response = await UsuarioService.Insert(data);
    if (response.success && response.resource) {
      toast({
        title: 'Salvar usuário.',
        description: 'usuário inserido com sucesso!.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      onClose(JSON.parse(JSON.stringify(response.resource)));
    } else {
      toast({
        title: 'Salvar usuário.',
        description: response.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleEdit = async () => {
    const novoUsuario = {
      id: usuario.id,
      nome: getValues('nome'),
      email: getValues('email'),
      telefone: getValues('telefone'),
      celular: getValues('celular'),
      ativo: getValues('ativo'),
    };
    const response = await UsuarioService.Update(novoUsuario);
    if (response.success && response.resource) {
      if (empresa) {
        const acessoEdit = {
          id: acesso.id,
          empresaId: acesso.empresaId,
          usuarioId: acesso.usuarioId,
          administrador: !!getValues('administrador'),
          cobrador: !!getValues('cobrador'),
        };
        const responseAcesso = await AcessoService.Update(acessoEdit);
        if (!responseAcesso.success) {
          toast({
            title: 'Salvar usuário.',
            description:
              'Não foi possivel editar o tipo do usuario entrar em contato com o suporte!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      }
      toast({
        title: 'Editar usuário.',
        description: 'Usuário editado com sucesso!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      onClose(JSON.parse(JSON.stringify(response.resource)));
    } else {
      toast({
        title: 'Editar usuário.',
        description: response.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const getUsuario = async () => {
      if (usuario.id !== undefined) {
        setValue('nome', usuario.nome);
        setValue('email', usuario.email);
        setValue('telefone', usuario.telefone);
        setValue('celular', usuario.celular);
        setValue('ativo', usuario.ativo);
        setValue('administrador', acesso.administrador);
        setValue('cobrador', acesso.cobrador);
        setEdit(true);
      }
    };
    getUsuario();
  }, []);

  return (
    <Flex direction="column">
      <Text
        casing="uppercase"
        fontSize="sm"
        color="gray.600"
        fontWeight="semibold"
        mb="4"
        w="100%"
      >
        Cadastro de usuário
      </Text>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        px="8"
        py="4"
        boxShadow="lg"
        onSubmit={handleSubmit(handleInsertUser)}
      >
        <Grid
          h={450}
          templateRows="repeat(6, 1fr)"
          templateColumns="repeat(6, 1fr)"
          gap={4}
        >
          {!usuario.id && (
            <>
              <GridItem colSpan={3}>
                <Input
                  register={register}
                  id="codigoAtivacao"
                  type="text"
                  error={errors.codigoAtivacao}
                  label="Codigo de Filiação"
                  autoComplete="off"
                />
              </GridItem>
              <GridItem colSpan={3} />
            </>
          )}
          <GridItem colSpan={3}>
            <Input
              register={register}
              id="nome"
              type="text"
              error={errors.nome}
              label="Nome"
              autoComplete="off"
            />
          </GridItem>
          <GridItem colSpan={3}>
            <Input
              id="email"
              type="email"
              error={errors.email}
              label="Email"
              register={register}
              autoComplete="off"
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Input
              id="telefone"
              type="text"
              error={errors.telefone}
              label="Telefone"
              register={register}
              autoComplete="off"
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Input
              id="celular"
              type="text"
              error={errors.telefone}
              label="Celular"
              register={register}
              autoComplete="off"
            />
          </GridItem>
          <GridItem colSpan={3}>
            <Input
              id="senha"
              type="password"
              error={errors.senha}
              label="Senha"
              isReadOnly={!!usuario.id}
              register={register}
              autoComplete="off"
            />
          </GridItem>
          <GridItem colSpan={3}>
            <Input
              id="senhaConfirmada"
              type="password"
              error={errors.senhaConfirmada}
              isReadOnly={!!usuario.id}
              label="Confirmação de senha"
              register={register}
              autoComplete="off"
            />
          </GridItem>
          <GridItem colSpan={2}>
            <CheckBox
              id="administrador"
              error={errors.administrador}
              label="Administrador"
              register={register}
              colorScheme="purple"
              defaultChecked={acesso.administrador}
            >
              Administrador
            </CheckBox>
          </GridItem>
          <GridItem colSpan={2}>
            <CheckBox
              id="cobrador"
              error={errors.cobrador}
              label="Cobrador"
              register={register}
              colorScheme="purple"
              defaultChecked={acesso.cobrador}
            >
              Cobrador
            </CheckBox>
          </GridItem>
          <GridItem colSpan={2}>
            <CheckBox
              register={register}
              id='ativo'
              error={errors.ativo}
              label="Ativo"
              ref={register}
              colorScheme="purple"
              defaultChecked={usuario.ativo}
            >
              Ativo
            </CheckBox>
          </GridItem>
          <GridItem colSpan={2}>
            {edit ? (
              <Button
                type="button"
                w="100%"
                mt="8"
                onClick={() => handleEdit()}
              >
                Salvar
              </Button>
            ) : (
              <Button
                type="submit"
                w="100%"
                mt="8"
                isLoading={isSubmitting}
              >
                Salvar
              </Button>
            )}
          </GridItem>
          <GridItem colSpan={2}>
            <Button
              onClick={() => onClose(usuario)}
              type="button"
              w="100%"
              mt="8"
              isLoading={isSubmitting}
            >
              Cancelar
            </Button>
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
};

export default UsuarioCreate;
