import React, { ReactNode, createContext, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import * as usuarioService from '../services/usuario';

import user from '../interface/usuario';

interface loginInterface {
  email: string;
  senha: string;
}

interface AuthContextData {
  signed: boolean;
  usuario: user | null;
  signIn(object: loginInterface): void;
  signOut(): void;
}

interface Props{
  children: ReactNode;
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }:Props) => {
  const toast = useToast();

  const [usuario, setUsuario] = useState<user | null>(null);

  async function signIn(data: loginInterface) {
    const response = await usuarioService.usuarioAuthenticate(data);

    if (response.success && response.resource) {
      const usuarioResponse = JSON.parse(JSON.stringify(response.resource));
      setUsuario(usuarioResponse);

      await localStorage.setItem('token', usuarioResponse.token);
    } else {
      toast({
        title: 'Login Erro.',
        description: response.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setUsuario(null);
      await localStorage.setItem('token', '');
    }
  }
  async function signOut() {
    setUsuario(null);
    await localStorage.setItem('token', '');
    await localStorage.setItem('empresa', '');
    await localStorage.setItem('rota', '');
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!usuario,
        usuario,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
