import React, { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import {
  FaCheckCircle,
  FaDoorClosed,
  FaEdit,
  FaMapSigns,
  FaTimesCircle,
  FaUser,
  FaUserCircle,
  FaWalking,
  FaWallet,
} from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import moment from 'moment';
import Button from '../../components/button';

import SideBar from '../../components/sideBar';

import clienteInterface from '../../interface/cliente';
import carneInterface from '../../interface/carne';
import rotaInterface from '../../interface/rota';

interface Props {
  rota: rotaInterface;
  handleClose(rota: rotaInterface): void;
  handleEdit(): void;
  handleDelete(rota: rotaInterface): void;
}

const RotaView: React.FC<Props> = ({
  rota,
  handleClose,
  handleEdit,
  handleDelete,
}) => {
  const [listaCarneOrdenado, setListaCarneOrdenado] = useState<
    carneInterface[]
  >([]);
  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  useEffect(() => {
    function SortByOrdem(a: carneInterface, b: carneInterface) {
      const aOrdem = a.ordem;
      const bOrdem = b.ordem;
      // eslint-disable-next-line no-nested-ternary
      return aOrdem < bOrdem ? -1 : aOrdem > bOrdem ? 1 : 0;
    }
    const carnesOrdenados = Array.from(rota.carnes);
    carnesOrdenados.sort(SortByOrdem);
    /* const carnesValidos = carnesOrdenados.filter(item => {
      return item.dataBaixa === null;
    }); */
    setListaCarneOrdenado(carnesOrdenados);
  }, []);

  return (
    <Flex flexDir='column' w='full' gap='2' >
       <Flex w='full' flexWrap='wrap' gap='4'>
         <Flex
            flexDir='column'
            w='full'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"
          >

            <Flex
              bg="indigo.400"
              h="180"
              direction="column"
              align="center"
              boxShadow="md"
              borderTopEndRadius="md"
              borderTopStartRadius="md"
              p="2"
            >
              <Flex
                justify="flex-end"
                w="100%"
                cursor="pointer"
                onClick={() => handleDelete(rota)}
              >
                <Icon as={MdClear} fontSize="2xl" color="white" />
              </Flex>
              <Flex
                bg="white"
                w="16"
                h="16"
                borderRadius="100%"
                align="center"
                justify="center"
              >
                <Icon as={FaMapSigns} color="indigo.400" fontSize="4xl" />
              </Flex>

            </Flex>
            <Flex h='56px' align='center'  justify="space-between">
              <Button

                boxShadow="none"
                bg="white"
                fontWeight="semibold"
                onClick={handleEdit}
              >
                EDITAR
              </Button>
              <Button

                boxShadow="none"
                bg="white"
                fontWeight="semibold"
                onClick={() => handleClose(rota)}
              >
                SAIR
              </Button>
            </Flex>
          </Flex>
          <Flex
             flexDir='column'
             flexBasis={200}
             flexGrow={1}
             h="237"
             bg="white"
             p="5"
             boxShadow="md"
             borderRadius="sm"
             textAlign="center"
          >
            <Flex justify="center" align="center" direction="column" h="48">
              <Text fontSize="x-large"  >
                {`${rota.nomeRota}`}
              </Text>
              <Text fontSize="large" >
                {`${rota.usuario ? rota.usuario?.nome : ''}`}
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"
          >
            <Flex
              w='full'
              bg="emerald.400"
              h="180"
              direction="column"
              align="center"
              justify='center'
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
              <Text
                 fontWeight="semibold"
                 textAlign="center"
                 mt="5"
                 color="white"
                 fontSize="3xl"
              >
                {currencyFormatter(rota.valorTotalAReceber)}
              </Text>
            </Flex>
            <Text fontWeight="semibold" textAlign="center" mt="5">
              VALOR A RECEBER
            </Text>
          </Flex>
          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"

          >
            <Flex
              w='full'
              bg="orange.400"
              h="180"
              direction="column"
              align="center"
              justify='center'
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
              <Text
                fontWeight="semibold"
                textAlign="center"
                mt="5"
                color="white"
                fontSize="2xl"
              >
                {listaCarneOrdenado.length}
              </Text>
            </Flex>
            <Text fontWeight="semibold" textAlign="center" mt="5">
              CARNES NA ROTA
            </Text>
          </Flex>
          </Flex>

          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            bg="white"
            px="5"
            py="5"
            boxShadow="md"
            borderRadius="sm"
        >
          <TableContainer overflowY="auto" h="96" mt='4' >
            <Table
              size="sm"
              position="relative"
            >
              <Thead >
                <Tr position="sticky" top="0" h="8" bg='gray.300'>
                  <Th  >
                    CARNE
                  </Th>
                  <Th>
                    CLIENTE
                  </Th>
                  <Th>
                    PRODUTO
                  </Th>
                  <Th  isNumeric>
                    VALOR R$
                  </Th>
                  <Th  isNumeric>
                    VALOR RECEBIDO R$
                  </Th>
                  <Th >
                    PAGO
                  </Th>
                </Tr>
              </Thead>
              <Tbody >
                {listaCarneOrdenado.map(carne => {
                  return (
                    <>
                      <Tr
                        key={carne.id}
                        fontSize="xs"
                      >
                        <Td  fontSize="xs">
                          {carne.numeroReferencia}
                        </Td>
                        <Td >
                         {carne.cliente?.nomeCompleto}
                        </Td>
                        <Td  fontSize="xs">
                          {carne.descricaoProdutos}
                        </Td>
                        <Td  isNumeric fontSize="xs">
                          {currencyFormatter(carne.valorCompra)}
                        </Td>
                        <Td  isNumeric fontSize="xs">
                          {currencyFormatter(
                            carne.valorCompra - carne.valorTotalAReceber,
                          )}
                        </Td>
                        <Td >
                          {carne.dataBaixa !== null ? (
                            <Text
                              fontSize="sm"
                              color="red.400"
                              fontWeight="semibold"
                            >
                              Baixado
                            </Text>
                          ) : carne.dataLiquidacao ? (
                            <Icon
                              as={FaCheckCircle}
                              color="emerald.400"
                              fontSize="16"
                            />
                          ) : (
                            <Icon
                              as={FaTimesCircle}
                              color="gray.400"
                              fontSize="16"
                            />
                          )}
                        </Td>
                      </Tr>
                    </>
                  );
                })}
              </Tbody>
            </Table>
            </TableContainer>
          </Flex>
    </Flex>
  );
};

export default RotaView;
