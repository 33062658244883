import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  Select,
  useToast,
  Text,
} from '@chakra-ui/react';

import { FaEdit, FaPlus, FaSearch, FaTrash } from 'react-icons/fa';
import moment from 'moment';
import * as CarneServices from '../../services/carne';
import empresaContext from '../../contexts/empresa';
import SideBar from '../../components/sideBar';
import CarneCreate from './create';
import carneInterface from '../../interface/carne';
import Button from '../../components/button';
import CarneView from './view';
import TodosCarnes from '../../components/list/todosCarnes';
import Loading from '../../components/loading';
import ImportFiles from '../../components/uploadCarne/index.js';
import Alert from '../../components/alertDialog';
import { InputControled } from '../../components/Form/inputControled';

interface Props {
  // eslint-disable-next-line react/require-default-props
  carneProps?: carneInterface;
}

const Carne: React.FC<Props> = ({ carneProps }) => {
  const { empresa } = useContext(empresaContext);
  const toast = useToast();
  const [textSearch, setTextSearch] = useState('');
  const [openCreate, setOpenCreate] = useState(false);
  const [typeSearch, setTypeSearch] = useState('');
  const [openViewData, setOpenViewData] = useState(false);
  const [carne, setCarne] = useState<carneInterface>({} as carneInterface);
  const [listaCarnes, setListaCarnes] = useState<carneInterface[]>([]);
  const [todosCarnes, setTodosCarnes] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [empresaId, setEmpresaId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const elementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (carneProps) {
      setCarne(carneProps);
      setOpenCreate(true);
    }
  }, [carneProps]);

  useEffect(() => {
    if (empresa) {
      setEmpresaId(empresa.id);
    }
  }, [empresa]);

  const handleTodosCarnes = async () => {
    if (empresa) {
      setOpenLoading(true);
      const response = await CarneServices.GetAll(empresa.id);
      if (response.success && response.resource) {
        await setListaCarnes(JSON.parse(JSON.stringify(response.resource)));
        setTodosCarnes(true);
      } else {
        toast({
          title: 'Consulta Erro.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
      setOpenLoading(false);
    }
  };

  const handleCloseTodosCarnes = (value: carneInterface) => {
    if (value.id !== undefined) {
      setTextSearch(value.numeroReferencia);
      setTodosCarnes(false);
      if (elementRef.current !== null) {
        elementRef.current.focus();
      }
    } else {
      setTodosCarnes(false);
    }
  };

  const handleCloseCreate = async (value: carneInterface) => {
    setCarne({} as carneInterface);
    setOpenViewData(false);
    setOpenCreate(false);
  };

  const handleSearch = async () => {
    setOpenLoading(true);
    let response;
    if (textSearch) {
      switch (typeSearch) {
        case 'referencia':
          if (empresa) {
            response = await CarneServices.GetByReferencia(
              textSearch,
              empresa.id,
            );
            if (response.success && response.resource) {
              await setCarne(JSON.parse(JSON.stringify(response.resource)));
              setOpenViewData(true);
            } else {
              toast({
                title: 'Consulta Erro.',
                description: response?.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          }

          break;
        case '':
          if (empresa) {
            response = await CarneServices.GetByReferencia(
              textSearch,
              empresa.id,
            );
            if (response.success && response.resource) {
              await setCarne(JSON.parse(JSON.stringify(response.resource)));
              setOpenViewData(true);
            } else {
              toast({
                title: 'Consulta Erro.',
                description: response?.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          }
          break;

        default:
          break;
      }
    }
    setOpenLoading(false);
  };

  const handleNew = () => {
    setCarne({} as carneInterface);
    setOpenCreate(true);
  };
  const handleEdit = () => {
    if (carne.dataBaixa !== null) {
      toast({
        title: 'Cancelar.',
        description: `Carne ${carne.numeroReferencia} ja foi baixado!`,
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setOpenViewData(false);
    setOpenCreate(true);
  };

  const handleCancelar = async (value: carneInterface) => {
    console.log(value.id);
    const response = await CarneServices.Cancelar(value.id);
    if (response.success) {
      toast({
        title: 'Cancelar.',
        description: 'As parcelas não pagas foram canceladas',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setOpenViewData(false);
    } else {
      toast({
        title: 'Cancelar Carne.',
        description: JSON.stringify(response.message),
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCloseView = () => {
    setOpenViewData(false);
  };

  return (
    <Flex w="full">
      {openViewData ? (
        <CarneView
          carne={carne}
          handleClose={handleCloseView}
          handleEdit={handleEdit}
          handleCancelar={handleCancelar}
        />
      ) : (
        <Flex w="full">
          {openCreate ? (
            <CarneCreate carne={carne} handleClose={handleCloseCreate} />
          ) : (
            <Flex flexDir='column'  w='full' borderRadius='sm' bg="white" p="8" boxShadow="md" h='80vh'>
            <Flex gap='4' align="center" w='full'>
             <Text fontSize="2xl" >
                  Carne
                </Text>

                <Flex
                  w='full'
                  align="center"
                  color="gray.500"
                  position="relative"
                  bg="gray.100"
                  borderRadius="md"
                  h='8'
                  px='4'
                  py='2'
                >
                  <Select
                  variant="unstyled"
                  placeholder="Pesquisa por"
                  value={typeSearch}
                  onChange={event => setTypeSearch(event.target.value)}
                  w='52'
                  >
                    <option value="referencia">Nº Carne</option>
                  </Select>

                  <InputControled
                    label=''
                    onKeyDown={handleKeyPress}
                    value={textSearch}
                    onChange={event => setTextSearch(event.target.value)}
                    border='none'
                    
                  />
                  <IconButton
                    aria-label="open-search"
                    icon={<Icon as={FaSearch} />}
                    fontSize="18"
                    variant="link"
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={handleSearch}
                  />
                </Flex>

                <Button
                  type="button"
                  onClick={handleNew}
                  leftIcon={<Icon as={FaPlus} />}
                  w='44'

                >
                  Criar Novo
                </Button>
              </Flex>
              <Grid
                h={350}
                templateRows="repeat(10, 1fr)"
                templateColumns="repeat(6, 1fr)"
                gap={3}
                mt='4'
              >
                <GridItem colSpan={6}>
                  <Flex align="center" justify="center" direction="column">
                    <Text>Utilize o campo acima para consulta</Text>
                    <Text>A consulta carne é feita pelo número do carne</Text>
                    <Text
                      color="indigo.600"
                      cursor="pointer"
                      onClick={handleTodosCarnes}
                      mt="4"
                    >
                      Clique aqui para mostrar todos os carnes
                    </Text>
                  </Flex>
                </GridItem>
              </Grid>
            </Flex>
          )}
        </Flex>
      )}
      {todosCarnes && (
        <TodosCarnes
          title="Lista de Carnes"
          carnes={listaCarnes}
          handleClose={handleCloseTodosCarnes}
          isOpen={!!todosCarnes}
        />
      )}
      {openLoading && <Loading isOpen={openLoading} text="Aguarde!" />}
    </Flex>
  );
};

export default Carne;
