import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';
import { errorInternet } from '../usuario';

export async function Insert(data: any): Promise<baseResponse> {
  return api
    .post('/rota', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/rota/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function Delete(id: number): Promise<baseResponse> {
  return api
    .delete(`/rota/${id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function GetAll(empresaId: number): Promise<baseResponse> {
  return api
    .get('/rota/consulta', {
      params: {
        empresaId,
      },
    })

    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function GetId(
  empresaId: number,
  rotaId: number,
): Promise<baseResponse> {
  return api
    .get(`/rota/consulta/`, {
      params: {
        empresaId,
        rotaId,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}
export async function GetByNome(
  nomeRota: string,
  empresaId: number,
): Promise<baseResponse> {
  return api
    .get(`/rota/consulta`, {
      params: { nomeRota, empresaId },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function GetById(
  rotaId: number,
  empresaId: number,
): Promise<baseResponse> {
  return api
    .get(`/rota/consulta`, {
      params: { rotaId, empresaId },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function GetByUsuarioId(
  usuarioId: number,
  empresaId: number,
): Promise<baseResponse> {
  return api
    .get(`/rota/consulta`, {
      params: { empresaId, usuarioId },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}
export async function GetSemVinculo(empresaId: number): Promise<baseResponse> {
  return api
    .get(`/rota/consulta`, {
      params: { empresaId },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}
