import {  FormControl, FormErrorMessage, FormLabel,Text, Input as ChakraIpunt, InputProps, useColorMode, Flex } from '@chakra-ui/react';
import { Path, UseFormRegister, FieldValues, FieldError } from "react-hook-form";


interface Props extends InputProps
  {
  label:string;
  isOptional?:boolean;
  };


export const InputControled  = ({label,isOptional,...rest}:Props) => {
  const { colorMode } = useColorMode();

  return(
    <FormControl zIndex={0}>
      <Flex>
    {label && <FormLabel htmlFor={label} fontSize='sm'  >{label}</FormLabel>}
    {isOptional && (<Text fontSize='small' opacity='0.6' fontStyle='italic'>opcional</Text>)}
    </Flex>
        <ChakraIpunt
        borderRadius="sm"
        focusBorderColor="emerald.300"
        variant="filled"
        bg="gray.100"
        color="gray.600"
        boxShadow='sm'
        _hover={{
          color: 'emerald.900',
          borderWidth:'1px',
        }}
        _focus={{
          bg: 'gray.100',
          borderWidth:'1px',
        }}
        autoComplete="off"
        size="sm"
        {...rest} />
      </FormControl>

  )
}
