import React, { useContext, useEffect, useState } from 'react';

import { Button, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { FaQuestionCircle, FaWallet } from 'react-icons/fa';
import auth from '../../contexts/auth';
import empresaContext from '../../contexts/empresa';

import Header from '../../components/header';
import SideBar from '../../components/sideBar';

const Main: React.FC = () => {
  const { usuario } = useContext(auth);
  const { empresa } = useContext(empresaContext);
  const [empresaId, setEmpresaId] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    if (empresa) {
      setEmpresaId(empresa.id);
    }
  }, []);
  return (
    <Flex direction="column">
     
    </Flex>
  );
};

export default Main;
