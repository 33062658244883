import React, { useEffect, useState } from 'react';
import { MdError } from 'react-icons/md';
import { Flex, List, ListIcon, ListItem, Text } from '@chakra-ui/react';

interface errosInterface {
  nomeCompleto: string;
  cpf: string;
  erros: string;
}
interface baseResponse {
  success: boolean;
  resource: string;
  message: string;
}

interface Props {
  listaErros: baseResponse;
}

const ErrorFiles: React.FC<Props> = ({ listaErros }) => {
  const [erros, setErros] = useState<errosInterface[]>([]);
  useEffect(() => {
    setErros(JSON.parse(JSON.stringify(listaErros.resource)));
  }, []);

  return (
    <List h="52" overflowY="scroll">
      {erros.map(item => {
        return (
          <ListItem key={item.cpf}>
            <Flex bg="gray.200" m="4" borderRadius="md" p="2">
              <ListIcon as={MdError} color="red.500" />
              <Flex direction="column">
                <Text fontSize="smaller">{item.nomeCompleto}</Text>
                <Text fontSize="smaller">{item.cpf}</Text>
                <Text fontSize="smaller">{item.erros}</Text>
              </Flex>
            </Flex>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ErrorFiles;
