import React, { ReactNode } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import animationData from '../../assets/loading.json';

interface LoadingProps {
  text: string;
  isOpen: boolean;
}

const Loading: React.FC<LoadingProps> = ({ text, isOpen }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p="4">
          <Flex direction="column" align="center" justify="center">
            <Text fontSize="lg">{text}</Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default Loading;
