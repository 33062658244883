import React, { } from 'react';


import Map from '../../components/map/mapBox';
import { Flex } from '@chakra-ui/react';

const Pontos: React.FC = () => {

  return (
      <Flex w='full'>
        <Map />
      </Flex>
  );
};

export default Pontos;
