import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  Button,
  useToast,
  TableContainer,
} from '@chakra-ui/react';
import { FaChevronCircleRight } from 'react-icons/fa';
import SideBar from '../../components/sideBar';
import carneInterface from '../../interface/carne';
import Alert from '../../components/alertDialog';
import * as CarneServices from '../../services/carne';
import EmpresaContext from '../../contexts/empresa';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';

const Baixar: React.FC = () => {
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const { empresa } = useContext(EmpresaContext);
  const [empresaId, setEmpresaId] = useState(0);
  const [carnesNaoBaixados, setCarnesNaoBaixados] = useState<carneInterface[]>(
    [],
  );
  const [carnesBaixados, setCarnesBaixados] = useState<carneInterface[]>([]);

  useEffect(() => {
    if (empresa) {
      setEmpresaId(empresa.id);
    }
  }, []);

  useEffect(() => {
    const getCarnesNaoBaixados = async () => {
      if (empresa) {
        const response = await CarneServices.GetDisponiveis(empresa.id);
        if (response.success && response.resource) {
          setCarnesNaoBaixados(JSON.parse(JSON.stringify(response.resource)));
        }
      }
    };
    getCarnesNaoBaixados();
  }, []);
  useEffect(() => {
    const getCarnesBaixados = async () => {
      if (empresa) {
        const response = await CarneServices.GetAll(empresa.id, true);
        if (response.success && response.resource) {
          setCarnesBaixados(JSON.parse(JSON.stringify(response.resource)));
        }
      }
    };
    getCarnesBaixados();
  }, []);

  const handleBaixar = () => {
    if (carnesNaoBaixados.length === 0) {
      toast({
        title: 'Baixar Carnes.',
        description: 'Nenhum carne para dar baixa!',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setOpen(true);
  };

  const handleConfirmar = async () => {
    const listString = carnesNaoBaixados
      .map(item => {
        return item.id;
      })
      .join(',')
      .split(',');

    const listaIdNaoBaixados = listString.map(item => {
      return parseInt(item, 10);
    });
    const baixas = {
      empresaId,
      carneId: listaIdNaoBaixados,
    };

    const response = await CarneServices.Baixar(baixas);
    if (response.success) {
      toast({
        title: 'Baixar Carnes.',
        description: 'Carnes Baixados com Sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setCarnesNaoBaixados([]);
      setOpen(false);
    } else {
      toast({
        title: 'Baixar Carnes.',
        description: response.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setOpen(false);
    }
  };

  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  return (
    <Flex w='full' >
      {open && (
        <Alert
          confirmar={() => handleConfirmar()}
          cancelar={() => setOpen(false)}
          title="Baixar Carnes"
          message={`Deseja realmente baixar ${carnesNaoBaixados.length},essa ação não podera ser desfeita!`}
          color="orange.300"
        />
      )}
      <Flex gap='4' w='full' >
        <Flex
          w='full'
          h="full"
          flexDir='column'
          borderRadius="sm"
          bg="white"
          px="8"
          py="4"
          boxShadow="md"
        >
          <Text>Carnes não baixados</Text>
          <TableContainer overflowY="auto" h={600} mt='4' >
          <Table
             size="sm"
             position="relative"
          >
            <Thead>
              <Tr position="sticky" top="0" h="8" bg='gray.300'>
                <Th>
                  Número
                </Th>
                <Th>
                  Data Compra
                </Th>
                <Th isNumeric>
                  Valor
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {carnesNaoBaixados.map((item, index) => {
                return (
                  <>
                    <Tr key={item.id} fontSize="xs">
                      <Td fontSize="xs">
                        {item.numeroReferencia}
                      </Td>

                      <Td fontSize="xs">
                      {moment(item.dataCompra, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}
                      </Td>
                      <Td fontSize="xs" isNumeric>
                        {currencyFormatter(item.valorCompra)}
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
            
          </Table>
          </TableContainer>
          <Flex justify="flex-end" w="100%">
                <Button
                  mt={3}
                  colorScheme="indigo"
                  leftIcon={<Icon as={FaChevronCircleRight} />}
                  onClick={handleBaixar}
                >
                  Baixar
                </Button>
              </Flex>
        </Flex>
        <Flex
          w='full'
          h="full"
          flexDir='column'
          borderRadius="md"
          bg="white"
          px="8"
          py="4"
          boxShadow="lg"
        >
          <Text>Carnes baixados</Text>
          <TableContainer overflowY="auto" h={600} mt='4' >
          <Table
             size="sm"
             position="relative"
          >
            <Thead >
              <Tr position="sticky" top="0" h="8" bg='gray.300'>
                <Th>
                  Número
                </Th>
                <Th>
                  Data Compra
                </Th>
                <Th isNumeric>
                  Valor
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {carnesBaixados.map((item, index) => {
                return (
                  <>
                    <Tr key={item.id} fontSize="xs" >
                      <Td fontSize="xs" >
                        {item.numeroReferencia}
                      </Td>

                      <Td fontSize="xs">
                      {moment(item.dataCompra, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}
                      </Td>

                      <Td fontSize="xs" isNumeric>
                        {currencyFormatter(item.valorCompra)}
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          </Table>
          </TableContainer>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Baixar;
