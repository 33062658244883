import React, { useContext, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  FaCheckCircle,
  FaDoorClosed,
  FaEdit,
  FaTimesCircle,
  FaUser,
  FaUserCircle,
  FaWalking,
} from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import moment from 'moment';
import Button from '../../components/button';

import SideBar from '../../components/sideBar';

import usuarioInterface from '../../interface/usuario';
import carneInterface from '../../interface/carne';
import * as CarneServices from '../../services/carne';
import empresaContext from '../../contexts/empresa';
import contaCorrenteInterface from '../../interface/contaCorrente';
import * as ContaCorrenteServices from '../../services/contaCorrente';

interface Props {
  usuario: usuarioInterface;
  handleClose(): void;
  handleEdit(): void;
  handleDelete(usuario: usuarioInterface): void;
}

const UsuarioView: React.FC<Props> = ({
  usuario,
  handleClose,
  handleEdit,
  handleDelete,
}) => {
  const toast = useToast();
  const { empresa } = useContext(empresaContext);
  const [totalReceber, setTotalReceber] = useState(0);
  const [carnes, setCarnes] = useState<carneInterface[]>([]);
  const [contaCorrente, setContaCorrente] = useState<contaCorrenteInterface[]>(
    [],
  );
  useEffect(() => {
    const getContaCorrente = async () => {
      if (empresa) {
        const response = await ContaCorrenteServices.GetByUsuario(
          empresa.id,
          usuario.id,
        );
        if (response.success && response.resource) {
          setContaCorrente(JSON.parse(JSON.stringify(response.resource)));
        }
      }
    };
    getContaCorrente();
  }, []);

  useEffect(() => {
    const getCarnes = async () => {
      if (empresa) {
        const response = await CarneServices.GetByUsuarioId(
          empresa.id,
          usuario.id,
        );
        if (response.success && response.resource) {
          setCarnes(JSON.parse(JSON.stringify(response.resource)));
        }
      }
    };
    getCarnes();
  }, []);

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < contaCorrente.length; i += 1) {
      total += contaCorrente[i].valor;
    }
    setTotalReceber(total);
  }, [contaCorrente]);

  function currencyFormatter(value: any) {
    if (!Number(value)) return '';
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  return (
    <Flex flexDir='column' w='full' gap='2'>
       <Flex w='full' flexWrap='wrap' gap='4'>
       <Flex
            flexDir='column'
            w='full'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"
          >
            <Flex
              w='full'
              bg="indigo.400"
              h="180"
              direction="column"
              align="center"
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
              <Flex
                justify="flex-end"
                w='full'
                cursor="pointer"
                onClick={() => handleDelete(usuario)}
              >
                <Icon as={MdClear} fontSize="2xl" color="white" />
              </Flex>
              <Icon as={FaUserCircle} fontSize="6xl" color="white" />
              <Text
                fontSize='sm' 
                color="white"
                textAlign="center"
              >
                {usuario.nome}
              </Text>
              <Text fontSize='xs'  color="white">
                {usuario.email}
              </Text>
              <Text fontSize="small" fontWeight="light" color="white">
                {usuario.ativo}
              </Text>
            </Flex>
            <Flex h='56px' align='center'  justify="space-between">
              <Button
                boxShadow="none"
                bg="white"
                fontWeight="semibold"
                onClick={handleEdit}
              >
                EDITAR
              </Button>
              <Button
                boxShadow="none"
                bg="white"
                fontWeight="semibold"
                onClick={handleClose}
              >
                SAIR
              </Button>
            </Flex>
          </Flex>

          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            p="5"
            boxShadow="md"
            borderRadius="sm"
            textAlign="center"
          >
            <Flex justify="center" align="center" direction="column" h="48">
              <Text fontSize="x-large" fontWeight="light">
                {usuario.nome}
              </Text>
              <Text  fontWeight="light">
                {`${usuario.email}`}
              </Text>

              <Text fontWeight="light">
                {usuario.telefone && `Telefone: ${usuario.telefone}`}
               
              </Text>
              <Text  fontWeight="light">
              {usuario.celular && `Celular: ${usuario.celular}`}               
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"

          >
            <Flex
              w='full'
              bg="indigo.400"
              h="180"
              direction="column"
              align="center"
              justify='center'
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
              <Text
                fontWeight="semibold"
                textAlign="center"
                mt="5"
                color="white"
                fontSize="2xl"
              >
                {currencyFormatter(totalReceber)}
              </Text>
            </Flex>
            <Text fontWeight="semibold" textAlign="center" mt="5">
              VALOR A ACERTAR
            </Text>
          </Flex>
          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"

          >
            <Flex
             w='full'
             bg="emerald.400"
             h="180"
             direction="column"
             align="center"
             justify='center'
             borderTopEndRadius="sm"
             borderTopStartRadius="sm"
             p="2"
            >
              <Text
                fontWeight="semibold"
                textAlign="center"
                mt="5"
                color="white"
                fontSize="2xl"
              >
                {contaCorrente.length}
              </Text>
            </Flex>
            <Text fontWeight="semibold" textAlign="center" mt="5">
              PAGAMENTOS
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDir='column'
          flexBasis={200}
          flexGrow={1}
          bg="white"
          px="5"
          py="5"
          boxShadow="md"
          borderRadius="sm"
        >
        <TableContainer overflowY="auto" h="full" mt='4' >
          <Table
             size="sm"
             position="relative"
          >
            <Thead>
            <Tr position="sticky" top="0" h="8" bg='gray.300'>
                <Th >CARNE/PARCELA/ROTA</Th>
                <Th >DATA</Th>
                <Th >CLIENTE</Th>
                <Th isNumeric>VALOR
                </Th>
              </Tr>
            </Thead>
            <Tbody >
              {contaCorrente.map(item => {
                return (
                  <Tr >
                    <Td  >
                      <Text>
                        {`${item.pagamento.parcela.carne?.numeroReferencia} / ${item.pagamento.parcela.numeroParcela}`}
                      </Text>
                      <Text>
                        {`${
                          item.pagamento.parcela.carne?.rota?.nomeRota
                            ? item.pagamento.parcela.carne?.rota?.nomeRota
                            : 'Sem rota'
                        }`}
                      </Text>
                    </Td>
                    <Td  >
                      {`${moment(
                        item.pagamento.dataPagamento,
                        'DD/MM/YYYY',
                      ).format('DD/MM/YYYY')}`}
                    </Td>
                    <Td  >
                      {item.pagamento.parcela.carne?.cliente.nomeCompleto}
                    </Td>

                    <Td isNumeric  >
                      {currencyFormatter(item.pagamento.valorPago)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            </Table>
          </TableContainer>
        </Flex>
    </Flex>
  );
};

export default UsuarioView;
