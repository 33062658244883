import React, { useContext, useEffect, useState } from 'react';
import {
  Flex,
  Icon,
  IconButton,
  Text,
  Input,
  Grid,
  GridItem,
  useToast,
} from '@chakra-ui/react';

import { FaSearch } from 'react-icons/fa';
import * as fns from 'date-fns';
import Button from '../button';
import usuarioInterface from '../../interface/usuario';
import * as LocalizacaoServices from '../../services/localizacao';
import * as UsuarioServices from '../../services/usuario';
import SimpleList from '../list/usuario';
import localizacaoInterface from '../../interface/localizacao';
import empresaContext from '../../contexts/empresa';
import { InputControled } from '../Form/inputControled';
import {format} from 'date-fns'
import { GoogleMap, useJsApiLoader,Marker,InfoWindow } from '@react-google-maps/api';
import './Marker.css';
import { formatMoneyBR } from '../../utils/utils';
import moment from 'moment';

const containerStyle = {
  width: '100%',
  height: '100%'
};


const MapView: React.FC = () => {
  const { empresa } = useContext(empresaContext);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDGBBBwcBAXfgrA1XTR1PXSx6Cz1Jr_zqs'
  })
  const [center, setCenter] = useState({ lat: -22.188540, lng: -48.215992 });

  const [map, setMap] = useState(null)


  const [zoom, setZoom] = useState(1);

  const [textSearch, setTextSearch] = useState('');
  const [openListaSearch, setOpenListaSearch] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const [usuarioLista, setUsuarioLista] = useState<usuarioInterface[]>([]);
  const toast = useToast();
  const [usuario, setUsuario] = useState<usuarioInterface>(
    {} as usuarioInterface,
  );
  const [localizacaoLista, setLocalizacaoLista] = useState<
    localizacaoInterface[]
  >([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleSearchUsuario = async () => {
    let response;
    if (textSearch) {
      if (empresa) {
        response = await UsuarioServices.GetByName(empresa.id, textSearch);
        if (response.success && response.resource) {
          await setUsuarioLista(JSON.parse(JSON.stringify(response.resource)));
          setOpenListaSearch(true);
        } else {
          toast({
            title: 'Consulta usuário.',
            description: response.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }
    } else {
      toast({
        title: 'Consulta Erro.',
        description: 'Digite nome para consultar',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleCloseListaUsuario = async (value: usuarioInterface) => {
    if (!value.id) {
      return;
    }
    setUsuario(value);
    setOpenListaSearch(false);
    setTextSearch(value.nome);
  };

  const handleConsultaLocalizacao = async () => {
    console.log(startDate?.toLocaleDateString('pt-BR'))
    if (startDate && endDate) {
      const start = fns.parseISO(startDate.toISOString());
      const end = fns.parseISO(endDate.toISOString());
      if (!empresa) {
        return;
      }
      if (!textSearch) {
        toast({
          title: 'Consulta.',
          description: 'Selecione um cobrandor para consultar',
          status: 'warning',
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      const response = await LocalizacaoServices.GetByData(
        usuario.id,
        empresa.id,
        start,
        end,
      );
      if (response.success && response.resource) {
        setLocalizacaoLista(JSON.parse(JSON.stringify(response.resource)));
        const lista = JSON.parse(JSON.stringify(response.resource));
        setCenter({ lat: lista[0].latitude, lng: lista[0].longitude });
        setZoom(11);
      } else {
        toast({
          title: 'Consulta Erro.',
          description: response?.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSearchUsuario();
    }
  };

  return isLoaded ? (
    
      <Flex align="center" w='full' h='full' position='relative'>
        <Flex
          w="200"
          bg="white"
          boxShadow="xl"
          zIndex="banner"
          position="absolute"
          borderRadius="md"
          top="2"
          left="2"
          pb='2'
        >
          <Flex flexDir='column' w='72' p='4' gap='2' >
            <Flex gap='2' align='flex-end'>
            <InputControled 
              label='Nome do Cobrador'
              onKeyDown={handleKeyPress}
              value={textSearch}
              onChange={event => setTextSearch(event.target.value)}
            />
            <IconButton
              aria-label="open-search"
              icon={<Icon as={FaSearch} />}
              variant='link'
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={handleSearchUsuario}
            />
            </Flex>
    
                <InputControled type='date' label='Data Início' value={startDate ? format(startDate,'yyyy-MM-dd'):''} onChange={(date) => setStartDate(new Date(date.target.value + 'EDT'))} />
                <InputControled type='date' label='Data Fim' value={endDate ? format(endDate,'yyyy-MM-dd'):''} onChange={(date) => setEndDate(new Date(date.target.value + 'EDT'))} />
                <Button
                onClick={handleConsultaLocalizacao}
                mt='2'
              >
                Consultar
              </Button>
          </Flex>
        </Flex>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={4}
          options={{streetViewControl:false}}
      >
        {localizacaoLista.map(localizacao => {
            return (
              <Marker
                key={localizacao.id}
                position={{ lat: localizacao.latitude,lng:  localizacao.longitude}}
                onClick={()=>setOpenInformation(true)}
                clickable={true}
              >
                  <InfoWindow position={{ lat: localizacao.latitude,lng:  localizacao.longitude}}>
                    <Flex flexDir='column'>
                    <Text>{localizacao.pagamento.parcela.carne?.cliente.nomeCompleto}</Text>
                    <Text>Parcela: {localizacao.pagamento.parcela.numeroParcela}</Text>
                    <Text>Pagamento: {moment(localizacao.pagamento.dataPagamento, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )} </Text>
                    <Text fontWeight='semibold'>{formatMoneyBR(localizacao.pagamento.valorPago)}</Text>
                    </Flex>
                  </InfoWindow>
                
              </Marker>
            );
          })}
      </GoogleMap>
        
        {openListaSearch && (
          <GridItem colSpan={6}>
            <SimpleList
              title="Consulta Cobrador"
              usuarios={usuarioLista}
              isOpen
              handleClose={handleCloseListaUsuario}
            />
          </GridItem>
        )}
      </Flex>
  ): <></>;
};

export default MapView;
