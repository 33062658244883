import { Avatar, Box, Flex, Stack, Text } from '@chakra-ui/react';
import React from 'react';

const Logo = () => {
  return (
    <Text
      fontSize={['large', '2xl']}
      fontWeight="semibold"
      letterSpacing={-2}
      w={64}
    >
      supercrediario.
    </Text>
  );
};
export default Logo;
