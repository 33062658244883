import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import {
  FaCheckCircle,
  FaDoorClosed,
  FaEdit,
  FaTimesCircle,
  FaUser,
  FaUserCircle,
  FaWalking,
} from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import Button from '../../components/button';

import SideBar from '../../components/sideBar';

import clienteInterface from '../../interface/cliente';
import carneInterface from '../../interface/carne';
import ListaParcelas from '../../components/list/parcelas';
import * as CarneServices from '../../services/carne';
import carne from '../carne';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';

interface Props {
  cliente: clienteInterface;
  handleClose(): void;
  handleEdit(): void;
  handleDelete(cliente: clienteInterface): void;
}

const ClienteView: React.FC<Props> = ({
  cliente,
  handleClose,
  handleEdit,
  handleDelete,
}) => {
  const [totalReceber, setTotalReceber] = useState(0);
  const [totalAtrasado, setTotalAtrasado] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [carneSelected, setCarneSelected] = useState<carneInterface>(
    {} as carneInterface,
  );
  const [carnes, setCarnes] = useState<carneInterface[]>([]);

  useEffect(() => {
    const getCarnes = async () => {
      const response = await CarneServices.GetByClienteId(
        cliente.empresaId,
        cliente.id,
      );
      if (response.success && response.resource) {
        setCarnes(JSON.parse(JSON.stringify(response.resource)));
      }
    };
    getCarnes();
  }, []);

  useEffect(() => {
    const total = carnes.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual.valorTotalAReceber,
      0,
    );
    setTotalReceber(total);
  }, [carnes]);

  useEffect(() => {
    const total = carnes.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual.valorTotalAtrazado,
      0,
    );
    setTotalAtrasado(total);
  }, [carnes]);

  const handleCarneDetails = (item: carneInterface) => {
    setIsOpen(true);
    setCarneSelected(item);
  };

  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  return (
    <Flex flexDir='column' w='full' gap='2'>
       <Flex w='full' flexWrap='wrap' gap='4'>
       <Flex
            flexDir='column'
            w='full'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"
          >
            <Flex
              w='full'
              bg="indigo.400"
              h="180"
              direction="column"
              align="center"
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
              <Flex
                justify="flex-end"
                w="100%"
                cursor="pointer"
                onClick={() => handleDelete(cliente)}
              >
                <Icon as={MdClear} fontSize="2xl" color="white" />
              </Flex>
              <Icon as={FaUserCircle} fontSize="6xl" color="white" />
              <Text
                fontSize='sm'
                color="white"
                textAlign="center"
              >
                {cliente.nomeCompleto}
              </Text>
              <Text fontSize='xs'  color="white">
                {cliente.email}
              </Text>
              <Text fontSize='xs'  color="white">
                {cliente.celular}
              </Text>
            </Flex>
            <Flex h='56px' align='center'  justify="space-between">
              <Button

                boxShadow="none"
                bg="white"
                fontWeight="semibold"
                onClick={handleEdit}
              >
                EDITAR
              </Button>
              <Button

                boxShadow="none"
                bg="white"
                fontWeight="semibold"
                onClick={handleClose}
              >
                SAIR
              </Button>
            </Flex>
          </Flex>

          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            p="5"
            boxShadow="md"
            borderRadius="sm"
            textAlign="center"
          >
            <Flex justify="center" align="center" direction="column" h="48">
              <Text color="gray.900">
                {cliente.nomeCompleto}
              </Text>
              <Text fontSize="xs" color="gray.900">
                {`${cliente.logradouro},${cliente.numero}`}
              </Text>
              <Text fontSize="xs"  color="gray.900">
                {cliente.complemento}
              </Text>
              <Text fontSize="xs"  color="gray.900">
                {cliente.bairro}
              </Text>
              <Text fontSize="xs"  color="gray.900">
                {`${cliente.municipio.nomeMunicipio}- ${cliente.uf}`}
              </Text>
              <Text fontSize="xs"  color="gray.900">
                {`CPF: ${cliente.cpf}  RG: ${cliente.rg}`}
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"

          >
            <Flex
              w='full'
              bg="emerald.400"
              h="180"
              direction="column"
              align="center"
              justify='center'
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
              <Text
                fontWeight="semibold"
                textAlign="center"
                mt="5"
                color="white"
                fontSize="3xl"
              >
                {currencyFormatter(totalReceber)}
              </Text>
            </Flex>
            <Text fontWeight="semibold" textAlign="center" mt="5">
              VALOR A RECEBER
            </Text>
          </Flex>

             <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"

          >
            <Flex
              w='full'
              bg="orange.400"
              h="180"
              direction="column"
              align="center"
              justify='center'
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
              <Text
                  fontWeight="semibold"
                  textAlign="center"
                  mt="5"
                  color="white"
                  fontSize="2xl"
              >
                {currencyFormatter(totalAtrasado)}
              </Text>
            </Flex>
            <Text fontWeight="semibold" textAlign="center" mt="5">
              VALOR EM ATRASO
            </Text>
          </Flex>
          </Flex>
          <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            bg="white"
            px="5"
            py="5"
            boxShadow="md"
            borderRadius="sm"
            >
          <TableContainer overflowY="auto" h="96" mt='4' >
          <Table
             size="sm"
             position="relative"
          >
              <Thead >
              <Tr position="sticky" top="0" h="8" bg='gray.300'>
                  <Th >
                    CARNE
                  </Th>
                  <Th >
                    DATA COMPRA
                  </Th>
                  <Th>
                    VALOR DO CARNE
                  </Th>
                  <Th isNumeric>
                    VALOR RECEBIDO
                  </Th>
                  <Th >
                    PAGO
                  </Th>
                </Tr>
              </Thead>
              <Tbody >
                {carnes.map(carne => {
                  return (
                    <>
                      <Tr
                        key={carne.id}

                        fontSize="xs"
                        color="gray.900"
                      >
                        <Td
                          fontSize="xs"
                          fontWeight="bold"
                          onClick={() => handleCarneDetails(carne)}
                          cursor="pointer"
                          color="indigo.500"
                        >
                          {carne.numeroReferencia}
                        </Td>
                        <Td >{moment(carne.dataCompra, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}

                        </Td>
                        <Td >
                          {currencyFormatter(carne.valorCompra)}
                        </Td>
                        <Td >
                          {carne.valorTotalAReceber !== 0
                            ? currencyFormatter(
                                carne.valorCompra - carne.valorTotalAReceber,
                              )
                            : currencyFormatter(carne.valorCompra)}
                        </Td>

                        <Td >
                          {carne.dataLiquidacao ? (
                            <Icon
                              as={FaCheckCircle}
                              color="teal.300"
                              fontSize="16"
                            />
                          ) : (
                            <Icon
                              as={FaTimesCircle}
                              color="gray.300"
                              fontSize="16"
                            />
                          )}
                        </Td>
                      </Tr>
                    </>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          </Flex>
      {isOpen && (
        <ListaParcelas
          carne={carneSelected}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Flex>
  );
};

export default ClienteView;
