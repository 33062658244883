import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { MdClear } from 'react-icons/md';
import Acerto from '../../interface/acerto';

interface Props {
  cancelar(): void;
  confirmar(): void;
  title: string;
  message: string;
  color: string;
}

const Alert = ({ cancelar, confirmar, title, message, color }: Props) => {
  return (
    <Flex
      position="absolute"
      top={0}
      right={0}
      left={0}
      bottom={0}
      zIndex="banner"
      justify="center"
      align="center"
    >
      <Flex
        w="96"
        h="56"
        bg={color}
        boxShadow="lg"
        borderRadius="10"
        p="2"
        direction="column"
      >
        <Flex justify="flex-start" w="100%">
          <Text color="white" fontWeight="semibold">
            {title}
          </Text>
        </Flex>
        <Flex>
          <Text mt="5" ml="5" color="white">
            {message}
          </Text>
        </Flex>
        <Flex justify="flex-end" w="100%" mt="30">
          <Button colorScheme="red" m="2" onClick={cancelar}>
            Cancelar
          </Button>
          <Button colorScheme="teal" m="2" onClick={() => confirmar()}>
            OK
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Alert;
