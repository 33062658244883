import React, { ReactNode, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import usuarioInterface from '../../interface/usuario';

interface SimpleListProps {
  title: string;
  usuarios: usuarioInterface[];
  handleClose(usuario: usuarioInterface): void;
  isOpen: boolean;
}

const TodosUsuarios: React.FC<SimpleListProps> = ({
  title,
  usuarios,
  handleClose,
  isOpen,
}) => {
  const [usuariosSort, setUsuariosSort] = useState<usuarioInterface[]>([]);
  useEffect(() => {
    function SortByName(a: usuarioInterface, b: usuarioInterface) {
      const aName = a.nome.toLowerCase();
      const bName = b.nome.toLowerCase();
      // eslint-disable-next-line no-nested-ternary
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    }
    const usuariosOrd = Array.from(usuarios);
    usuariosOrd.sort(SortByName);

    setUsuariosSort(usuariosOrd);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose({} as usuarioInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="auto" spacing="2" px="2" h="96">
            {usuariosSort.map(item => {
              return (
                <ListItem
                  onClick={() => handleClose(JSON.parse(JSON.stringify(item)))}
                  key={item.id}
                  bg="gray.100"
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="md"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                >
                  <HStack px="1">
                    <Avatar
                      size="md"
                      name={item.nome}
                      bg="emerald.400"
                      color="emerald.900"
                      boxShadow="md"
                      mx="2"
                      my="2"
                    />
                    <Stack justify="space-around">
                      <Text
                        fontWeight="semibold"
                        fontSize="sm"
                        casing="uppercase"
                      >
                        {item.nome}
                      </Text>
                      <Text  fontSize="xs">
                        {item.email}
                      </Text>
                    </Stack>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default TodosUsuarios;
