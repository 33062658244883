import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Text,
  Flex,
  useToast,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  TableCaption,
  Spinner,
  TableContainer,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import {
  FaEdit,
  FaPlus,
  FaSearch,
  FaArrowLeft,
  FaCheckCircle,
  FaDollarSign,
  FaSave,
  FaSync,
  FaAd,
  FaShippingFast,
} from 'react-icons/fa';
import ptBR from 'date-fns/locale/pt-BR';
import * as fns from 'date-fns';
import carneInterface from '../../interface/carne';
import { Input } from '../../components/Form/Input';

import clienteInterface from '../../interface/cliente';
import rotaInterface from '../../interface/rota';
import ClienteList from '../../components/list/cliente';
import * as ClientesServices from '../../services/cliente';
import * as PagamentoServices from '../../services/pagamento';

import * as ParcelaServices from '../../services/parcela';
import empresaContext from '../../contexts/empresa';
import authContext from '../../contexts/auth';

import Button from '../../components/button';
import parcelaInterface from '../../interface/parcela';
import Parcela from '../parcela';
import * as CarneServices from '../../services/carne';
import * as RotaServices from '../../services/rota';

import ParcelaEdit from '../../components/parcelaEdit';
import light from '../../styles/themes/light';
import formaDePagamento from '../../interface/formaPagamento';
import RotaSelect from '../../components/selectRota';
import Alert from '../../components/alertDialog';
import { format, isValid, parseISO } from 'date-fns';
import { InputControled } from '../../components/Form/inputControled';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


interface Props {
  carne: carneInterface;
  handleClose(carne: carneInterface): void;
  handleCloseCliente?(cliente: clienteInterface): void;
  parametroCliente?: clienteInterface;
}

const FormSchema = yup.object().shape({
  descricaoProdutos: yup.string().required('Produto obrigatório'),
  numeroReferencia: yup.string().required('Referência obrigatório'),
  dataCompra: yup.string().required('Data é obrigatório'),
  valorCompra: yup.string().required('Valor obrigatório'),
  cpf: yup.string().required('CPF obrigatório'),
  rota:yup.string(),
  clienteCPF: yup.string(),
  clienteNome: yup.string(),
});

const CarneCreate = ({ carne,handleClose,parametroCliente,handleCloseCliente}:Props) => {
  const toast = useToast();
  const { empresa } = useContext(empresaContext);
  const { usuario } = useContext(authContext);

  const [cliente, setCliente] = useState<clienteInterface>(
    {} as clienteInterface,
  );
  const [carneNovo, setCarneNovo] = useState<carneInterface>(carne);
  const [listaParcela, setListaParcela] = useState<parcelaInterface[]>([]);
  const [clienteNome, setClienteNome] = useState('');
  const [clienteLista, setClientelista] = useState<clienteInterface[]>([]);
  const [openListaCliente, setOpenListacliente] = useState(false);
  const [openParcela, setOpenParcela] = useState(false);
  const [openParcelaEdit, setOpenParcelaEdit] = useState(false);
  const [openListaParcela, setOpenListaParcela] = useState(false);
  const [clienteFound, setClienteFound] = useState(false);
  const [rotaFound, setRotaFound] = useState(false);
  const [openSpinner, setOpenSpinner] = useState(false);
  const [dataDaCompra, setDataDaCompra] = useState<Date | null>(null);
  const [ErrorDataCompra, setErrorDataCompra] = useState(false);
  const [ErrorDescricao, setErrorDescricao] = useState(false);
  const [ErrorReferencia, setErrorReferencia] = useState(false);
  const [ErrorValor, setErrorValor] = useState(false);
  const [rota, setRota] = useState<rotaInterface>({} as rotaInterface);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [parcela, setParcela] = useState<parcelaInterface>(
    {} as parcelaInterface,
  );
  const navigate =useNavigate()
  const { register, handleSubmit, setValue, getValues,reset, formState:{errors,isSubmitting} } =
    useForm({ resolver: yupResolver(FormSchema) });

  useEffect(() => {
    const getParcelas = async (carneId: number) => {
      if (empresa) {
        const response = await ParcelaServices.GetByCarneId(
          empresa.id,
          carneId,
        );
        if (response.success && response.resource) {
          const parcelasAux: parcelaInterface[] = JSON.parse(
            JSON.stringify(response.resource),
          );
          parcelasAux.sort((a, b) => {
            return a.numeroParcela < b.numeroParcela
              ? -1
              : a.numeroParcela > b.numeroParcela
              ? 1
              : 0;
          });
          setListaParcela(parcelasAux);
        }
      }
    };
    console.warn(JSON.stringify(carne, null, 2));
    if (carne.id !== undefined && carneNovo.id !== undefined) {
      getParcelas(carne.id);
    }
    setOpenListaParcela(true);
  }, []);

  useEffect(() => {
    const getRota = async (rotaId: number, empresaId: number) => {
      const response = await RotaServices.GetById(rotaId, empresaId);

      if (response.success && response.resource) {
        setRota(JSON.parse(JSON.stringify(response.resource)));
        const { nomeRota }: rotaInterface = JSON.parse(
          JSON.stringify(response.resource),
        );
        if (carne.id === undefined) {
          setValue('rota', nomeRota);
        }
      }
    };
    const rotaStorage = localStorage.getItem('rota');

    if (rotaStorage) {
      if (empresa) {
        getRota(parseInt(rotaStorage, 10), empresa.id);
        setRotaFound(true);
      }
    }
  }, []);

  useEffect(() => {
    if (parametroCliente) {
      setCliente(parametroCliente);
      setClienteNome(parametroCliente.nomeCompleto);
      setClienteFound(true);
    }
  }, []);

  useEffect(() => {
    if (carne.id !== undefined) {
      setCliente(carne.cliente);
      setValue('numeroReferencia', carne.numeroReferencia);

     const date = new Date(moment(carne.dataCompra, 'DD-MM-YYYY').format('YYYY-MM-DD'));

      setDataDaCompra(date);
      setValue('descricaoProdutos', carne.descricaoProdutos);
      setValue('valorCompra', carne.valorCompra.toFixed(2));
      setValue('rota', carne.rota?.nomeRota);
      setCarneNovo(carne);
      setClienteFound(true);
    }
  }, []);

  const handleInsertCarne = async () => {
    let data;
    if (dataDaCompra) {
      data = fns.parseISO(dataDaCompra.toISOString());
    }
    setErrorDataCompra(false);
    setErrorReferencia(false);
    setErrorDescricao(false);
    setErrorValor(false);
    if (rota.id === undefined) {
      toast({
        title: 'Carne.',
        description: 'Defina uma rota antes de lançar o carne',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    let ordemRota =
      rota.carnes.pop()?.ordem === 0 ? 0 : rota.carnes.pop()?.ordem;
    if (ordemRota !== undefined && ordemRota > 0) {
      ordemRota += 1;
    }
    if (!getValues('descricaoProdutos')) {
      setErrorDescricao(true);
      return;
    }
    if (!getValues('numeroReferencia')) {
      setErrorReferencia(true);
      return;
    }
    if (!dataDaCompra) {
      setErrorDataCompra(true);
      return;
    }

    if (!getValues('valorCompra')) {
      setErrorValor(true);
      return;
    }
    if (cliente.id !== undefined) {
      const carneInsert = {
        rotaId: rota.id,
        clienteId: cliente.id,
        empresaId: cliente.empresaId,
        ordem: ordemRota,
        numeroReferencia: getValues('numeroReferencia'),
        dataCompra: data,
        dataBaixa: null,
        descricaoProdutos: getValues('descricaoProdutos'),
        valorCompra: parseFloat(
          getValues('valorCompra').toString().replace(',', '.'),
        ),
      };

      if (carne.id !== undefined) {
        const carneEdit = {
          id: carne.id,

          clienteId: cliente.id,
          empresaId: cliente.empresaId,

          numeroReferencia: getValues('numeroReferencia'),
          dataCompra: data,
          descricaoProdutos: getValues('descricaoProdutos'),
          valorCompra: parseFloat(
            getValues('valorCompra').toString().replace(',', '.'),
          ),
        };
        const response = await CarneServices.Update(carneEdit);
        if (response.success && response.resource) {
          toast({
            title: 'Alterar Carne.',
            description: 'Carne alterado com sucesso!',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Carne erro.',
            description: response.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      } else {
        const response = await CarneServices.Insert(carneInsert);
        if (response.success && response.resource) {
          toast({
            title: 'Carne',
            description: 'Carne cadastrado com sucesso!',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
          const carneNew = JSON.parse(JSON.stringify(response.resource));
          const responseCarne = await CarneServices.GetByReferencia(
            carneNew.numeroReferencia,
            empresa ? empresa.id : 1,
          );
          if (responseCarne.success && responseCarne.resource) {
            setCarneNovo(JSON.parse(JSON.stringify(responseCarne.resource)));
            setOpenParcela(true);
          } else {
            toast({
              title: 'Carne Erro.',
              description:
                'Não foi possivel obter dados do novo carne, por favor consulte o carne na tela principal',
              status: 'error',
              duration: 2000,
              isClosable: true,
            });
            handleClose(carneNew);
          }
        } else {
          toast({
            title: 'Carne Erro.',
            description: response.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }
    } else {
      toast({
        title: 'Carne.',
        description: 'O carne precisa de um cliente',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleSearch = async () => {
    setOpenSpinner(true);
    if (!getValues('clienteCPF')) {
      toast({
        title: 'Consulta Cliente.',
        description: 'CPF é obrigatório',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      setOpenSpinner(false);
      return;
    }
    if (empresa) {
      const response = await ClientesServices.GetByCPF(
        empresa.id,
        getValues('clienteCPF')!,
      );
      if (response.success && response.resource) {
        setCliente(JSON.parse(JSON.stringify(response.resource)));
        setClienteFound(true);
      } else {
        toast({
          title: 'Consulta Cliente.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
      setOpenSpinner(false);
    }
  };

  const handleCloseListaCliente = (value: clienteInterface) => {
    setCliente(JSON.parse(JSON.stringify(value)));
    setClienteNome(JSON.parse(JSON.stringify(value.nomeCompleto)));
    setValue('clienteNome', value.nomeCompleto);
    setOpenListacliente(false);
  };

  const handleCloseParcela = (value: carneInterface) => {
    setOpenParcela(false);
    setClienteFound(false);
    reset();
    navigate('/carne')

  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleParcelaEditar = async (value: parcelaInterface) => {
    if (value.dataQuitacao !== null) {
      toast({
        title: 'Alterar Parcela.',
        description: 'Parcela ja quitada não pode ser alterada!',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (value.cancelado) {
      toast({
        title: 'Alterar Parcela.',
        description: 'Parcela cancelada não pode ser alterada!',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setParcela(value);
    setOpenParcelaEdit(true);
  };

  const handleCloseParcelaEditar = async (value: parcelaInterface) => {
    if (value.id !== undefined) {
      const parcelaNova = {
        id: parseInt(value.id.toString(), 10),
        carneId: parseInt(value.carneId.toString(), 10),
        numeroParcela: parseInt(value.numeroParcela.toString(), 10),
        dataVencimento: value.dataVencimento,
        dataQuitacao: value.dataQuitacao ? value.dataQuitacao : '',

        valorParcela: parseFloat(
          value.valorParcela.toString().replace(',', '.'),
        ),
        empresaId: value.empresaId,
      };
      const response = await ParcelaServices.Update(
        JSON.parse(
          JSON.stringify(parcelaNova, [
            'id',
            'carneId',
            'numeroParcela',
            'dataVencimento',
            'dataQuitacao',
            'valorParcela',
            'empresaId',
          ]),
        ),
      );

      if (response.success && response.resource) {
        toast({
          title: 'Alterar Parcela.',
          description: 'Parcela alterado com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        const itensCopy = Array.from(listaParcela);
        const index = itensCopy.findIndex(item => item.id === parcelaNova.id);
        itensCopy[index].dataVencimento = value.dataVencimento;
        setListaParcela(itensCopy);
      } else {
        toast({
          title: 'Alterar Parcela.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
    setOpenParcelaEdit(false);
  };

  const handleCloseParcelaCancelar = async (value: parcelaInterface) => {
    const parcelaNova = {
      id: parseInt(value.id.toString(), 10),
      carneId: parseInt(value.carneId.toString(), 10),
      numeroParcela: parseInt(value.numeroParcela.toString(), 10),
      dataVencimento: value.dataVencimento,
      dataQuitacao: value.dataQuitacao,
      valorParcela: value.valorParcela,
      empresaId: value.empresaId,
      cancelado: true,
    };
    const response = await ParcelaServices.Update(
      JSON.parse(
        JSON.stringify(parcelaNova, [
          'id',
          'carneId',
          'numeroParcela',
          'dataVencimento',
          'dataQuitacao',
          'valorParcela',
          'empresaId',
          'cancelado',
        ]),
      ),
    );

    if (response.success && response.resource) {
      console.log(response.resource);
      toast({
        title: 'Alterar Parcela.',
        description: 'Parcela alterado com sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      const itensCopy = Array.from(listaParcela);
      const index = itensCopy.findIndex(item => item.id === parcelaNova.id);
      itensCopy[index].cancelado = true;
      setListaParcela(itensCopy);
    } else {
      toast({
        title: 'Alterar Parcela.',
        description: response.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }

    setOpenParcelaEdit(false);
  };

  const handleParcelaQuitar = async (
    item: parcelaInterface,
    formaPagamento: formaDePagamento,
  ) => {
    if (!formaPagamento.id) {
      toast({
        title: 'Quitar Parcela.',
        description: 'seleciona a forma de pagamento!',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (usuario && empresa) {
      const pagamentoNovo = {
        parcelaId: item.id,
        formaPagamentoId: formaPagamento.id,
        dataPagamento: fns.parseISO(new Date().toISOString()),
        valorPago: parseFloat(
          item.valorTotalAReceber.toString().replace(',', '.'),
        ),
        usuarioId: usuario.id,
        empresaId: empresa.id,
      };

      const response = await PagamentoServices.Insert(pagamentoNovo, false);
      if (response.success && response.resource) {
        toast({
          title: 'Quitar Parcela.',
          description: 'Parcela quitada com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });

        const responseParcelas = await ParcelaServices.GetByCarneId(
          empresa.id,
          item.carneId,
        );
        if (responseParcelas.success && responseParcelas.resource) {
          const parcelas: parcelaInterface[] = JSON.parse(
            JSON.stringify(responseParcelas.resource),
          );
          const itensCopy = Array.from(parcelas);
          setListaParcela(itensCopy);
        }
        setOpenParcelaEdit(false);
      } else {
        toast({
          title: 'Quitar Parcela.',
          description: response.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleCloseSelectRota = (rotaRetorno: rotaInterface) => {
    if (rotaRetorno.id) {
      localStorage.setItem('rota', rotaRetorno.id.toString());
      setRota(rotaRetorno);
      setIsOpen(false);
      setRotaFound(true);
      if (carne.id === undefined) {
        setValue('rota', rotaRetorno.nomeRota);
      }
    } else {
      setIsOpen(false);
    }
  };

  const handleRemoveCliente = () => {
    setCliente({} as clienteInterface);
    setClienteFound(false);
  };

  const handleConfirmar = async () => {
    if (rota.id === undefined) {
      toast({
        title: 'Alterar rota.',
        description: 'Selecione uma rota antes de alterar',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const carneUpadte  = {
      id: carne.id,
      rotaId: rota.id,
      numeroReferencia: carne.numeroReferencia,
      descricaoProdutos: carne.descricaoProdutos,
      empresaId: carne.empresaId,
      clienteId: carne.clienteId,
      dataCompra: carne.dataCompra,
      dataLiquidacao: carne.dataLiquidacao,
      dataBaixa: carne.dataBaixa,
      valorCompra: carne.valorCompra,
      ordem: carne.ordem,

    };
    const response = await CarneServices.Update(carneUpadte);
    if (response.success && response.resource) {
      toast({
        title: 'Alterar rota.',
        description: 'Rota alterada com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setValue('rota', rota.nomeRota);
      setOpen(false);
    } else {
      toast({
        title: 'Alterar rota.',
        description: response.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setOpen(false);
    }
  };
  const handleChageRota = async () => {
    setOpen(true);
  };
  return (
    <Flex w='full' h='full' flexDir='column'>
      {open && (
        <Alert
          confirmar={() => handleConfirmar()}
          cancelar={() => setOpen(false)}
          title="Alterar Rota"
          message={`Deseja alterar a rota ${getValues('rota')} para a rota ${
            rota?.nomeRota
          }`}
          color="orange.300"
        />
      )}
      <Flex align="center">
        <Text
          casing="uppercase"
          fontSize="sm"
          fontWeight="semibold"
          mb="4"
          mr="4"
        >
          Cadastro de Carne
        </Text>
        {rotaFound ? (
          <>
            <Text
              casing="uppercase"
              fontSize="sm"
              fontWeight="semibold"
              mb="4"
            >
              {`Rota: ${rota.nomeRota}`}
            </Text>
          </>
        ) : (
          <>
            <Text
              casing="uppercase"
              fontSize="sm"
              fontWeight="semibold"
              mb="4"
            >
              Rota não definida
            </Text>
          </>
        )}

        <IconButton
          ml="4"
          mb="4"
          aria-label="trocar de rota"
          onClick={() => setIsOpen(true)}
          icon={<Icon as={rota.id ? FaSync : FaPlus} />}
          colorScheme="indigo"
          borderRadius="50%"
          size="xs"
        />
      </Flex>
      <Flex as="form" w='full' h='full' onSubmit={handleSubmit(handleInsertCarne)} >
        {openParcela ? (
          <Parcela handleClose={handleCloseParcela} carne={carneNovo} />
        ) : (
            <Flex
              h='full'
              w="full"
              gap='4'

            >

            <Flex direction="column" w='full' p='4' justify='space-between'  bg='white'
              borderRadius='sm'
              boxShadow='md'>
                  {clienteFound ? (
              <Flex direction="column" w="100%">
                      <Flex w="100%" align="center" justify="flex-end">
                        <Icon
                          as={FaEdit}
                          color="indigo.400"
                          cursor="pointer"
                          onClick={handleRemoveCliente}
                        />
                      </Flex>
                      <Flex
                        align="center"
                        justify="center"
                        direction="column"
                        w="100%"
                      >
                        <Text fontSize="lg">{cliente.nomeCompleto}</Text>
                        <Text fontSize="sm">{`Tel: ${cliente.telefone} - Cel: ${cliente.celular}`}</Text>

                        <Text fontSize="sm">{`CPF: ${cliente.cpf}`}</Text>
                        <Text fontSize="sm">{`${cliente.email}`}</Text>
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex>
                      <Input
                        id="clienteCPF"
                        type="text"
                        label="Cliente CPF"
                        onKeyDown={event => handleKeyPress(event)}
                        register={register}
                      />
                      {openSpinner ? (
                        <Flex align="center" justify="center">
                          <Spinner
                            thickness="4px"
                            speed="0.98s"
                            emptyColor="gray.200"
                            color="teal.300"
                            size="md"
                          />
                        </Flex>
                      ) : (
                        <IconButton
                          aria-label="open-search"
                          icon={<Icon as={FaSearch} />}
                          fontSize="20"
                          onClick={handleSearch}
                          variant="unstyled"
                          mt="6"
                        />
                      )}
                    </Flex>
                  )}
                  <Button
                  type="button"
                  isLoading={isSubmitting}
                  onClick={
                    () =>
                      // eslint-disable-next-line react/jsx-curly-newline
                      parametroCliente
                        ? // eslint-disable-next-line react/jsx-curly-newline
                          handleCloseCliente &&
                          handleCloseCliente(parametroCliente)
                        : handleClose(carne)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  bg="orange.400"
                  color="orange.800"
                  leftIcon={<Icon as={FaArrowLeft} />}
                  _hover={{
                    bg: 'orange.500',
                  }}
                  mt="30"
                >
                  Sair
                </Button>
            </Flex>

              <Flex flexDir='column' w='full' p='4' gap='2' bg='white' borderRadius='sm' boxShadow='md'>
                  <Flex >
                    <Input
                      id="rota"
                      type="text"
                      label="Rota"
                      register={register}
                      isReadOnly
                    />
                    <IconButton
                      ml="4"
                      mt="8"
                      aria-label="trocar de rota"
                      onClick={handleChageRota}
                      icon={<Icon as={FaSync} />}
                      colorScheme="purple"
                      borderRadius="50%"
                      size="xs"
                    />
                    </Flex>
                    <Flex gap='4' >
                    <Flex direction="column">
                      <Input
                        id="numeroReferencia"
                        error={errors.numeroReferencia}
                        type="text"
                        label="Nº Carne"
                        borderWidth={ErrorReferencia ? 'thin' : '0'}
                        borderColor={ErrorReferencia ? 'red.400' : ''}
                        register={register}
                        isReadOnly={carne.parcelas !== undefined}
                      />
                      {ErrorReferencia && (
                        <Text
                          mt="2"
                          ml="10"
                          fontSize="sm"
                          fontWeight="light"
                          color={light.colors.err}
                        >
                          Carne é obrigatório
                        </Text>
                      )}
                    </Flex>

                     <InputControled label='Data da compra' type='date' value={dataDaCompra ? format(dataDaCompra,'yyyy-MM-dd'):''} onChange={(date) => setDataDaCompra(new Date(date.target.value + 'EDT'))}/>
                     <Flex direction="column">
                      <Input
                        id="valorCompra"
                        type="text"
                        error={errors.valorCompra}
                        label="Valor"
                        borderWidth={ErrorValor ? 'thin' : '0'}
                        borderColor={ErrorValor ? 'red.400' : ''}
                        register={register}
                        isReadOnly={carne.parcelas !== undefined}
                      />
                      {ErrorValor && (
                        <Text
                          mt="2"
                          ml="10"
                          fontSize="sm"
                          fontWeight="light"
                          color={light.colors.err}
                        >
                          Valor é obrigatório
                        </Text>
                      )}
                    </Flex>
                     </Flex>

                    <Flex direction="column">
                      <Input
                        id="descricaoProdutos"
                        error={errors.descricaoProdutos}
                        type="text"
                        label="Descrição dos Produtos"
                        borderWidth={ErrorDescricao ? 'thin' : '0'}
                        borderColor={ErrorDescricao ? 'red.400' : ''}
                        register={register}
                      />
                      {ErrorDescricao && (
                        <Text
                          mt="2"
                          ml="10"
                          fontSize="sm"
                          fontWeight="light"
                          color={light.colors.err}
                        >
                          Descriçao do produto é obrigatório
                        </Text>
                      )}
                    </Flex>


                    {carne.id === undefined ? (
                      <Button
                        type="button"
                        onClick={() => handleInsertCarne()}
                        leftIcon={<Icon as={FaPlus} />}
                        mt="30"
                      >
                        Adicionar Parcela
                      </Button>
                    ) : carne.parcelas.length === 0 ? (
                      <Button
                        type="button"
                        onClick={() => handleInsertCarne()}
                        leftIcon={<Icon as={FaPlus} />}
                        mt="30"
                      >
                        Adicionar Parcela
                      </Button>
                    ) : (
                      <></>
                    )}

                  {listaParcela.length > 0 && (
                    <TableContainer overflowY="auto" h="full" mt='4'>
                      <Table
                        size="sm"
                        position="relative"
                      >
                        <TableCaption>
                          Para editar clique no número da parcela
                        </TableCaption>
                        <Thead>
                          <Tr position="sticky" top="0" h="8" bg='gray.300'>
                            <Th >
                              PARCELA
                            </Th>
                            <Th >
                              VENCIMENTO
                            </Th>
                            <Th >
                              QUITADO
                            </Th>
                            <Th isNumeric>
                              VALOR
                            </Th>
                            <Th isNumeric>
                              VALOR A RECEBER
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody
                        >
                          {listaParcela.map(parcelaItem => {
                            return (
                              <>
                                <Tr
                                  key={parcelaItem.id}
                                  fontSize="xs"
                                  bg={
                                    parcelaItem.cancelado
                                      ? 'red.400'
                                      : parcelaItem.valorTotalAtrazado
                                      ? 'orange.300'
                                      : 'white'
                                  }
                                  borderRadius={
                                    parcelaItem.cancelado
                                      ? 'md'
                                      : parcelaItem.valorTotalAtrazado
                                      ? 'md'
                                      : 0
                                  }
                                >
                                  <Td
                                    fontSize="xs"
                                    fontWeight="semibold"
                                    cursor="pointer"
                                    color={
                                      parcelaItem.cancelado
                                        ? 'red.800'
                                        : 'indigo.400'
                                    }
                                    onClick={
                                      () => handleParcelaEditar(parcelaItem)
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                  >
                                    {parcelaItem.numeroParcela}
                                  </Td>
                                  <Td fontSize="xs">
                                   {moment(parcelaItem.dataVencimento, 'DD-MM-YYYY').format(
                                        'DD/MM/YYYY',
                                     )}
                                  </Td>
                                  <Td fontSize="xs">
                                    {parcelaItem.dataQuitacao === null
                                      ? ''
                                      : moment(parcelaItem.dataQuitacao, 'DD-MM-YYYY').format('DD/MM/YYYY')
                                      }

                                  </Td>
                                  <Td  fontSize="xs" isNumeric>
                                    {parcelaItem.valorParcela.toFixed(2)}
                                  </Td>
                                  <Td fontSize="xs" isNumeric>
                                    {parcelaItem.cancelado ? (
                                      <Text
                                        casing="uppercase"
                                        color="red.800"
                                        fontWeight="semibold"
                                      >
                                        Cancelada
                                      </Text>
                                    ) : (
                                      parcelaItem.valorTotalAReceber.toFixed(2)
                                    )}
                                  </Td>
                                </Tr>
                              </>
                            );
                          })}
                        </Tbody>
                      </Table>
                      </TableContainer>
                  )}
              </Flex>
            </Flex>
        )}
      </Flex>
      {openListaCliente && (
          <ClienteList
            clientes={clienteLista}
            title="Lista de Clientes"
            handleClose={handleCloseListaCliente}
            isOpen={openListaCliente}
          />
        )}
        {isOpen && (
          <RotaSelect
            title="Selecione a Rota"
            isOpen={isOpen}
            onClose={handleCloseSelectRota}
          />
        )}
      {openParcelaEdit && (
          <ParcelaEdit
            isOpen={openParcelaEdit}
            parcela={parcela}
            onClose={handleCloseParcelaEditar}
            onQuitar={handleParcelaQuitar}
            onCancelar={handleCloseParcelaCancelar}
          />
        )}
    </Flex>
  );
};

export default CarneCreate;
