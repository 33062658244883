import React, { ReactNode, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FaRegCheckCircle } from 'react-icons/fa';
import carneInterface from '../../interface/carne';

interface SimpleListProps {
  title: string;
  carnes: carneInterface[];
  handleClose(carne: carneInterface): void;
  isOpen: boolean;
}

const TodosCarnes: React.FC<SimpleListProps> = ({
  title,
  carnes,
  handleClose,
  isOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose({} as carneInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="scroll" spacing="2" px="2" h="96" py="2">
            {carnes.map(item => {
              return (
                <ListItem
                  onClick={() => handleClose(JSON.parse(JSON.stringify(item)))}
                  key={item.id}
                  bg={item.dataLiquidacao ? 'green.200' : 'gray.100'}
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="base"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                >
                  <Flex justify="space-between" px="2" align="center">
                    <HStack p="2">
                      <Stack justify="space-around">
                        <Text
                          fontWeight="semibold"
                          color="gray.800"
                          fontSize="small"
                          casing="uppercase"
                        >
                          {`${item.numeroReferencia} - ${item.cliente.nomeCompleto}`}
                        </Text>
                        <Text
                          color="gray.800"
                          fontSize="x-small"
                          casing="uppercase"
                        >
                          {item.descricaoProdutos}
                        </Text>
                      </Stack>
                    </HStack>
                    {item.dataLiquidacao && (
                      <Flex direction="column" align="center" justify="center">
                        <Icon
                          as={FaRegCheckCircle}
                          fontSize="3xl"
                          color="green.900"
                          opacity="0.5"
                        />
                        <Text
                          fontWeight="semibold"
                          color="green.900"
                          opacity="0.5"
                          fontSize="smaller"
                        >
                          Liquidado
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default TodosCarnes;
