import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';
import React, { createContext, ReactNode, useContext } from 'react';

interface SideBarDrawerProps {
  children: ReactNode;
}

type SideBarDrawerContextData = UseDisclosureReturn;

const SideBarDrawerContext = createContext({} as SideBarDrawerContextData);
export const SideBarDrawerProvider = ({ children }: SideBarDrawerProps) => {
  const disclosure = useDisclosure();
  return (
    <SideBarDrawerContext.Provider value={disclosure}>
      {children}
    </SideBarDrawerContext.Provider>
  );
};
export const useSideBarDrawer = () => useContext(SideBarDrawerContext);
