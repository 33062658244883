import React, { ReactNode, createContext, useState } from 'react';

import empresaInterface from '../interface/empresa';

interface AuthContextData {
  empresa: empresaInterface | null;
  changeEmpresa(object: empresaInterface): void;
}
interface Props{
  children: ReactNode;
}
const EmpresaContext = createContext<AuthContextData>({} as AuthContextData);

export const EmpresaProvider = ({ children }:Props) => {
  const [empresa, setEmpresa] = useState<empresaInterface | null>(null);

  async function changeEmpresa(emp: empresaInterface) {
    setEmpresa(emp);
  }

  return (
    <EmpresaContext.Provider
      value={{
        empresa,
        changeEmpresa,
      }}
    >
      {children}
    </EmpresaContext.Provider>
  );
};
export default EmpresaContext;
