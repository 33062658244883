import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';
import { errorInternet } from '../usuario';

export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/nota-acerto/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetByAcertado(
  empresaId: number,
  usuarioId: number,
  acertado?: boolean,
): Promise<baseResponse> {
  return api
    .get(`/nota-acerto/`, {
      params: {
        empresaId,
        usuarioId,
        acertado,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetPagamentoNota(
  empresaId: number,
  notaAcertoId: number,
): Promise<baseResponse> {
  return api
    .get(`/nota-acerto/consulta`, {
      params: {
        empresaId,
        notaAcertoId,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}
