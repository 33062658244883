import React, { ReactNode, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import clienteInterface from '../../interface/cliente';

interface SimpleListProps {
  title: string;
  clientes: clienteInterface[];
  handleClose(cliente: clienteInterface): void;
  isOpen: boolean;
}

const TodosClientes: React.FC<SimpleListProps> = ({
  title,
  clientes,
  handleClose,
  isOpen,
}) => {
  const [clientesSort, setClienteSort] = useState<clienteInterface[]>([]);
  useEffect(() => {
    function SortByName(a: clienteInterface, b: clienteInterface) {
      const aName = a.nomeCompleto.toLowerCase();
      const bName = b.nomeCompleto.toLowerCase();
      // eslint-disable-next-line no-nested-ternary
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    }
    const clientesOrd = Array.from(clientes);
    clientesOrd.sort(SortByName);

    setClienteSort(clientesOrd);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose({} as clienteInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="scroll" spacing="2" px="2" h="96">
            {clientesSort.map(item => {
              return (
                <ListItem
                  onClick={() => handleClose(JSON.parse(JSON.stringify(item)))}
                  key={item.id}
                  bg="gray.100"
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="base"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                >
                  <HStack p="2">
                    <Avatar
                      size="sm"
                      name={item.nomeCompleto}
                      bg="emerald.400"
                      color="emerald.900"
                      boxShadow="md"

                    />
                    <Flex flexDir='column' gap='2'>
                      <Text
                        fontWeight="semibold"
                        fontSize="xs"
                        casing="uppercase"
                      >
                        {item.nomeCompleto}
                      </Text>
                      <Text fontSize="xs">
                        {`CPF: ${item.cpf}`}
                      </Text>
                    </Flex>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default TodosClientes;
