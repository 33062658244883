import React, { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import {
  FaCheckCircle,
  FaDoorClosed,
  FaEdit,
  FaTimesCircle,
  FaUser,
  FaUserCircle,
  FaWalking,
  FaWallet,
} from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import Button from '../../components/button';

import SideBar from '../../components/sideBar';

import clienteInterface from '../../interface/cliente';
import carneInterface from '../../interface/carne';
import ParcelaView from '../parcela/view';
import parcelaInterface from '../../interface/parcela';
import Alert from '../../components/alertDialog';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import moment from 'moment';

interface Props {
  carne: carneInterface;
  handleClose(carne: carneInterface): void;
  handleEdit(): void;
  handleCancelar(carne: carneInterface): void;
}

const CarneView: React.FC<Props> = ({
  carne,
  handleClose,
  handleEdit,
  handleCancelar,
}) => {
  const [parcelaEdit, setParcelaEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const [parcela, setParcela] = useState<parcelaInterface>(
    {} as parcelaInterface,
  );
  const [parcelaLista, setParcelaLista] = useState<parcelaInterface[]>([]);

  useEffect(() => {
    const tempList: parcelaInterface[] = Array.from(carne.parcelas);
    tempList.sort((a, b) => {
      return a.numeroParcela < b.numeroParcela
        ? -1
        : a.numeroParcela > b.numeroParcela
        ? 1
        : 0;
    });
    setParcelaLista(tempList);
  }, []);

  const handleCloseParcelaview = async () => {
    setParcelaEdit(false);
  };

  const handleValorRecebido = (value: parcelaInterface) => {
    return value.valorParcela - value.valorTotalAReceber;
  };

  const handleConfirmar = () => {
    setOpen(false);
    handleCancelar(carne);
  };

  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  return (
    <Flex flexDir='column' w='full' gap='2' >
        
          <Flex w='full' flexWrap='wrap' gap='4' >

            <Flex
              flexDir='column'
              w='full'
              flexBasis={200}
              flexGrow={1}
              h="237"
              bg="white"
              boxShadow="md"
              borderRadius="sm"
            >
              <Flex
                w='full'
                bg={carne.dataBaixa ? 'red.400' : 'indigo.400'}
                h="180"
                direction="column"
                align="center"
                borderTopEndRadius="sm"
                borderTopStartRadius="sm"
                p="2"
              >
               
                  <Flex
                    justify="flex-end"
                    w="100%"
                    cursor="pointer"
                    onClick={() => setOpen(true)}
                  >
                    <Icon as={MdClear} fontSize="2xl" color="white" />
                  </Flex>
                  <Flex
                    bg="white"
                    w="16"
                    h="16"
                    borderRadius="100%"
                    align="center"
                    justify="center"
                  >
                    <Icon
                      as={FaWallet}
                      color={carne.dataBaixa ? 'red.300' : 'indigo.400'}
                      fontSize="4xl"
                    />
                  </Flex>
                  <Text
                   
                    color="white"
                    textAlign="center"
                  >
                    {carne.numeroReferencia}
                  </Text>

                  <Text fontSize="xs" color="white">
                    {carne.cliente.nomeCompleto}
                  </Text>
                  <Text fontSize="xs" color="white">
                    {`CPF: ${carne.cliente.cpf}`}
                  </Text>
                </Flex>
                <Flex h='56px' align='center'  justify="space-between">
                  <Button
                    boxShadow="none"
                    bg="white"
                    fontWeight="semibold"
                    onClick={handleEdit}
                  >
                    EDITAR
                  </Button>
                  <Button
                    boxShadow="none"
                    bg="white"
                    fontWeight="semibold"
                    onClick={() => handleClose({} as carneInterface)}
                  >
                    SAIR
                  </Button>
              </Flex>
            </Flex>

            <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg={
              carne.dataBaixa
                ? 'red.400'
                : carne.dataLiquidacao
                ? 'emerald.400'
                : 'white'
            }
            px="4"
            py='2'
            boxShadow="md"
            borderRadius="sm"
          >
            
            <Flex justify='space-between'  direction="column" h='full' >
              <Flex direction="column" color={  carne.dataBaixa
                ? 'red.900'
                : carne.dataLiquidacao
                ? 'emerald.900'
                : 'gray.700'}>
                <Text fontSize='small' fontWeight="bold" >
                  {`${carne.numeroReferencia} - ${carne.cliente.nomeCompleto}`}
                </Text>
                <Text fontSize='xs'>
                  {`${carne.descricaoProdutos}`}
                </Text>

                <Text fontSize="xs">
                  {`Data Compra: ${moment(carne.dataCompra, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}
                        `}
                </Text>
                {carne.dataLiquidacao && (
                  <Text fontSize="xs" >
                    {`Data Liquidação: ${moment(carne.dataLiquidacao, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}
                        `}
                  </Text>
                )}
                {carne.dataBaixa && (
                  <Text fontSize="xs" >
                    {`Data da Baixa: ${moment(carne.dataBaixa, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}
                        `}
                  </Text>
                )}

                <Text fontSize="xs" >
                  {`Valor: ${currencyFormatter(carne.valorCompra)}`}
                </Text>
                <Text fontSize="xs" >
                  {carne.rota && `Rota: ${carne.rota.nomeRota}`}
                </Text>
                </Flex>
                <Flex>
                {carne.dataBaixa ? (
                  <Text fontSize="3xl" fontWeight="bold" color="red.800">
                    BAIXADO
                  </Text>
                ) : (
                  carne.dataLiquidacao && (
                    <Text
                      fontSize="lg"
                      fontWeight="bold"
                      color="emerald.900"
                      casing="uppercase"
                    >
                      liquidado
                    </Text>
                  )
                )}
                </Flex>
              </Flex>
            </Flex>

            <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"

          >
            <Flex
              w='full'
              bg={carne.dataBaixa ? 'red.300' : 'indigo.400'}
              h="180"
              direction="column"
              align="center"
              justify='center'
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
              
                <Text
                  fontWeight="semibold"
                  textAlign="center"
                  mt="5"
                  color="white"
                  fontSize="2xl"
                >
                  {currencyFormatter(
                    carne.dataLiquidacao || carne.dataBaixa
                      ? 0
                      : carne.valorTotalAReceber,
                  )}
                </Text>
              </Flex>
              <Text fontWeight="semibold" textAlign="center" mt="5">
                VALOR A RECEBER
              </Text>
            </Flex>
           
           <Flex
            flexDir='column'
            flexBasis={200}
            flexGrow={1}
            h="237"
            bg="white"
            boxShadow="md"
            borderRadius="sm"

          >
            <Flex
              w='full'
              bg={carne.dataBaixa ? 'red.300' : 'orange.400'}
              h="180"
              direction="column"
              align="center"
              justify='center'
              borderTopEndRadius="sm"
              borderTopStartRadius="sm"
              p="2"
            >
             
                <Text
                  fontWeight="semibold"
                  textAlign="center"
                  mt="5"
                  color="white"
                  fontSize="2xl"
                >
                  {currencyFormatter(
                    carne.dataLiquidacao || carne.dataBaixa
                      ? 0
                      : carne.valorTotalAtrazado,
                  )}
                </Text>
              </Flex>
              <Text fontWeight="semibold" textAlign="center" mt="5">
                VALOR EM ATRASO
              </Text>
            </Flex>
            
          </Flex>
            <Flex
              flexDir='column'
              flexBasis={200}
              flexGrow={1}
              bg="white"
              px="5"
              py="5"
              boxShadow="md"
              borderRadius="sm"
            >
               <TableContainer overflowY="auto" h="full" mt='4' >
                <Table
                  size="sm"
                  position="relative"
                >
                <Thead>
                  <Tr position="sticky" top="0" h="8" bg='gray.300'>
                    <Th>
                      PARCELA
                    </Th>
                    <Th>
                      VENCIMENTO
                    </Th>
                    <Th>
                      VALOR PARCELA
                    </Th>
                    <Th isNumeric>
                      VALOR RECEBIDO
                    </Th>
                    <Th>
                      PAGO
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {parcelaLista.map(parcelaItem => {
                    return (
                      <Tr
                        key={parcelaItem.id}
                        fontSize="xs"
                        bg={
                          parcelaItem.cancelado
                            ? 'red.400'
                            : parcelaItem.valorTotalAtrazado
                            ? 'orange.400'
                            : 'white'
                        }
                        borderRadius={
                          parcelaItem.cancelado
                            ? 'sm'
                            : parcelaItem.valorTotalAtrazado
                            ? 'sm'
                            : 0
                        }
                      >
                        <Td fontSize="xs">
                          {parcelaItem.numeroParcela}
                        </Td>
                        <Td fontSize="xs">
                        {moment(parcelaItem.dataVencimento, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}
                        
                        </Td>
                        <Td fontSize="xs">
                          {currencyFormatter(parcelaItem.valorParcela)}
                        </Td>

                        <Td isNumeric fontSize="xs">
                          {currencyFormatter(handleValorRecebido(parcelaItem))}
                        </Td>

                        <Td fontSize="xs">
                          {parcelaItem.cancelado ? (
                            <Text
                              casing="uppercase"
                              color="red.800"
                              fontWeight="semibold"
                            >
                              Cancelada
                            </Text>
                          ) : parcelaItem.dataQuitacao === null ? (
                            <Icon
                              as={FaTimesCircle}
                              color="gray.300"
                              fontSize="16"
                            />
                          ) : (
                            <Icon
                              as={FaCheckCircle}
                              color="emerald.400"
                              fontSize="16"
                            />
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              </TableContainer>
            </Flex>
        
         {open && (
          <Alert
            confirmar={() => handleConfirmar()}
            cancelar={() => setOpen(false)}
            title="Baixar Carnes"
            message="Deseja realmente cancelar as parcelas não pagas deste carne,essa ação não podera ser desfeita!"
            color="orange.300"
          />
        )}
    </Flex>
  );
};

export default CarneView;
