import React from 'react';
import './App.css';
import Routes from './routes';
import { AuthProvider } from './contexts/auth';
import { EmpresaProvider } from './contexts/empresa';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './styles/theme';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <ChakraProvider theme={theme} resetCSS>
    <AuthProvider>
      <EmpresaProvider>
      <BrowserRouter>
           <Routes />
        </BrowserRouter>
      </EmpresaProvider>
    </AuthProvider>
  </ChakraProvider>
  );
}

export default App;
