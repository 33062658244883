import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';
import { errorInternet } from '../usuario';

export async function Insert(data: any): Promise<baseResponse> {
  return api
    .post('/municipio', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/municipio/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function Delete(data: any): Promise<baseResponse> {
  return api
    .delete(`/municipio/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function GetAll(): Promise<baseResponse> {
  return api
    .get('/municipio')
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function GetById(id: number): Promise<baseResponse> {
  return api
    .get(`/municipio/${id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}
export async function GetByUF(UF: string): Promise<baseResponse> {
  return api
    .get(`/municipio/consulta/${UF}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}
