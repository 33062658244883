import { AxiosError, AxiosResponse } from 'axios';
import baseResponse from '../../interface/baseResponse';
import parcela from '../../interface/parcela';
import api from '../api.js';
import { errorInternet } from '../usuario';

export async function Insert(data: parcela[]): Promise<baseResponse> {
  return api
    .post(
      '/parcela',
      JSON.stringify(data, [
        'carneId',
        'numeroParcela',
        'dataVencimento',
        'valorParcela',
        'empresaId',
      ]),
    )
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function Update(data: parcela): Promise<baseResponse> {
  return api
    .put('/parcela/', data)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function Delete(data: any): Promise<baseResponse> {
  return api
    .delete(`/parcela/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function GetAll(): Promise<baseResponse> {
  return api
    .get('/parcela')
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function GetById(data: any): Promise<baseResponse> {
  return api
    .get(`/parcela/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function GetByCarneId(
  empresaId: number,
  carneId: any,
): Promise<baseResponse> {
  return api
    .get(`/parcela/consulta`, {
      params: { empresaId, carneId },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}
