import React, { ReactNode, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import rotaInterface from '../../interface/rota';

interface SimpleListProps {
  title: string;
  rotas: rotaInterface[];
  handleClose(rota: rotaInterface): void;
  isOpen: boolean;
}

const TodasRotas: React.FC<SimpleListProps> = ({
  title,
  rotas,
  handleClose,
  isOpen,
}) => {
  const [rotaSort, setRotaSort] = useState<rotaInterface[]>([]);
  useEffect(() => {
    function SortByName(a: rotaInterface, b: rotaInterface) {
      const aName = a.nomeRota.toLowerCase();
      const bName = b.nomeRota.toLowerCase();
      // eslint-disable-next-line no-nested-ternary
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    }
    const rotaOrd = Array.from(rotas);
    rotaOrd.sort(SortByName);

    setRotaSort(rotaOrd);
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose({} as rotaInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <List overflowY="scroll" spacing="2" px="2" h="96">
            {rotaSort.map(item => {
              return (
                <ListItem
                  onClick={() => handleClose(JSON.parse(JSON.stringify(item)))}
                  key={item.id}
                  bg="gray.100"
                  borderRadius="md"
                  cursor="pointer"
                  mx="4"
                  boxShadow="base"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                >
                  <HStack p="2">
                    <Stack justify="space-around">
                      <Text
                        fontWeight="semibold"
                        color="gray.800"
                        fontSize="small"
                        casing="uppercase"
                      >
                        {item.nomeRota}
                      </Text>
                    </Stack>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default TodasRotas;
