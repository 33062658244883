import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {  useNavigate } from 'react-router-dom';

import auth from '../../contexts/auth';
import { Input } from '../../components/Form/Input';

const singInFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('e-mail obrigatório')
    .email('Digite um email valido'),
  senha: yup
    .string()
    .required('senha obrigatório')
    .min(3, 'senha deve ter o minimo 3 caracteres'),
});

const Login: React.FC = () => {
  const toast = useToast();
  const { signIn } = useContext(auth);
  const { register,getValues, handleSubmit, setValue,formState:{errors,isSubmitting} } = useForm({
    resolver: yupResolver(singInFormSchema),
  });
  const navigate = useNavigate();
  const [lembrarEmail, setLembrarEmail] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      setValue('email', email);
      setLembrarEmail(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignIn = async() => {
    const {email,senha}= getValues();
    const response = await signIn({email,senha});

    if (response === null) {
      toast({
        title: 'Login Erro.',
        description: 'usuário ou senha incorreto!.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    if (lembrarEmail) {
      localStorage.setItem('email',email);
    } else {
      localStorage.setItem('email', '');
    }
  };

  return (
    <Flex  h="100vh" w='full' maxW={1440} minW={390} mx='auto' align="center" justify="center" >
    <Flex filter='auto' bg='indigo.400'  w={520} h={200} pos='absolute' zIndex={1}  blur='150px' />
 
    <Flex  flexDir='column' bg='whiteAlpha.600' backdropBlur='20px' w='96' borderRadius='md' boxShadow='sm' px='8' py='4'zIndex={5} >
    <form style={{ display:'flex',flexDirection:'column',width:'100%'}} onKeyDown={() => { }} >
        <Text
          fontSize='3xl'
          fontWeight='bold'
          letterSpacing={-2}
         >
          supercrediario.
        </Text>
          <Input
            register={register}
            id="email"
            type="email"
            label="E-mail"
            error={errors.email}
          />
          <Input
            register={register}
            id="senha"
            type="password"
            error={errors.senha}
            label="Senha"
          />
          <Flex justify='space-between' w='full' my='2'>
          <Checkbox
              colorScheme="indigo"
              isChecked={lembrarEmail}
              onChange={event => setLembrarEmail(event.target.checked)}
              borderColor='gray.400'
            >
              <Text fontSize='xs'>Lembrar e-mail?</Text>
            </Checkbox>

          <Button
          type="button"
          size="sm"
          variant="link"
          onClick={() => navigate('/trocar-senha')}
        >
          <Text
            textColor="indigo.400"
            fontSize="xs"
            _hover={{
              color: 'indigo.800',
            }}
          >
            Esqueceu a senha?
          </Text>
        </Button>
          </Flex>
           
        <Button
          bg="emerald.400"
          borderRadius="sm"
          size="sm"
          boxShadow="sm"
          my='4'
          _hover={{
            bg: 'emerald.500',
          }}
          isLoading={isSubmitting}
          onClick={handleSubmit(handleSignIn)}
        >
          <Text textColor="emerald.800">
            Entrar
          </Text>
        </Button>
        </form>

      </Flex>

      <Flex position="absolute" bottom={2} right={2}>
        <Text fontSize="xs" color="black">
          V-2.0.0
        </Text>
      </Flex>
    </Flex>
  );
};

export default Login;
