import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';
import { errorInternet } from '../usuario';

export async function ImportClientes(
  data: any,
  empresaId: number,
): Promise<baseResponse> {
  return api
    .post('/importer/clientes', data, {
      headers: {
        'Content-Type': data.type,
        'Access-Control-Max-Age': 86400,
      },
      params: { empresaId },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}

export async function ImportCarnes(
  data: any,
  empresaId: number,
  rota: any,
): Promise<baseResponse> {
  return api
    .post('/importer/carnes', data, {
      headers: {
        'Content-Type': data.type,
        'Access-Control-Max-Age': 86400,
      },
      params: { empresaId, rotaId: rota ? rota.id : rota },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }

      return error.response.data;
    });
}
