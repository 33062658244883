import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Text,
  Tfoot,
  IconButton,
  Input as InputChakra,
  Spinner,
  TableContainer,
} from '@chakra-ui/react';
import moment from 'moment';
import {
  FaCheck,
  FaChevronCircleRight,
  FaPaperPlane,
  FaSearch,
  FaTrash,
} from 'react-icons/fa';
import SideBar from '../../components/sideBar';

import authContext from '../../contexts/auth';
import empresaContext from '../../contexts/empresa';

import * as AcertoServices from '../../services/acerto';
import * as UsuarioServices from '../../services/usuario';
import acertoInterface from '../../interface/acerto';
import usuarioInterface from '../../interface/usuario';

import light from '../../styles/themes/light';
import { Input } from '../../components/Form/Input';
import SimpleList from '../../components/list/usuario';
import ListaItensAcerto from '../../components/list/notaAcerto';

import Alert from '../../components/alertDialog';
import { InputControled } from '../../components/Form/inputControled';

const NotaAcerto: React.FC = () => {
  const toast = useToast();

  const { empresa } = useContext(empresaContext);
  const [textSearch, setTextSearch] = useState('');
  const [usuario, setUsuario] = useState<usuarioInterface>(
    {} as usuarioInterface,
  );
  const [listaAcerto, setListaAcerto] = useState<acertoInterface[]>([]);
  const [listaAcertado, setListaAcertado] = useState<acertoInterface[]>([]);
  const [open, setOpen] = useState(false);
  const [indexValue, setIndexValue] = useState(0);
  const [acerto, setAcerto] = useState<acertoInterface>({} as acertoInterface);
  const [totalAcertar, setTotalAcertar] = useState(0);
  const [totalAcertado, setTotalAcertado] = useState(0);
  const [openListaSearch, setOpenListaSearch] = useState(false);
  const [usuarioLista, setUsuarioLista] = useState<usuarioInterface[]>([]);
  const [openSpinner, setOpenSpinner] = useState(false);
  const [openItensAcerto, setOpenitensAcerto] = useState(false);
  const [listaItensAcerto, setListaItensAcerto] = useState<acertoInterface[]>(
    [],
  );
  useEffect(() => {
    const getAcerto = async () => {
      if (empresa && usuario.id) {
        const response = await AcertoServices.GetByAcertado(
          empresa.id,
          usuario.id,
          false,
        );

        if (response.success && response.resource) {
          setListaAcerto(JSON.parse(JSON.stringify(response.resource)));
        } else {
          toast({
            title: 'Carregar Notas Acerto.',
            description: response.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };
    getAcerto();
    setOpenSpinner(false);
  }, [usuario]);

  useEffect(() => {
    const getAcerto = async () => {
      if (empresa && usuario.id) {
        const response = await AcertoServices.GetByAcertado(
          empresa.id,
          usuario.id,
          true,
        );

        if (response.success && response.resource) {
          setListaAcertado(JSON.parse(JSON.stringify(response.resource)));
        } else {
          toast({
            title: 'Carregar Notas Acerto.',
            description: response.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };
    getAcerto();
    setOpenSpinner(false);
  }, [usuario]);

  useEffect(() => {
    const total = listaAcerto.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual.valor,
      0,
    );
    setTotalAcertar(total);
  }, [listaAcerto]);

  useEffect(() => {
    const totalA = listaAcertado.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual.valor,
      0,
    );
    setTotalAcertado(totalA);
  }, [listaAcertado]);

  const handleSearchUsuario = async () => {
    setOpenSpinner(true);
    let response;
    if (empresa) {
      if (textSearch) {
        response = await UsuarioServices.GetByName(empresa.id, textSearch);
        if (response.success && response.resource) {
          await setUsuarioLista(JSON.parse(JSON.stringify(response.resource)));
          setOpenListaSearch(true);
        } else {
          toast({
            title: 'Consulta usuário.',
            description: response.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }
    } else {
      toast({
        title: 'Consulta Erro.',
        description: 'Digite nome para consultar',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setOpenSpinner(false);
  };

  const handleSelectAcerto = async (item: acertoInterface) => {
    if (empresa) {
      const response = await AcertoServices.GetPagamentoNota(
        empresa.id,
        item.id,
      );

      if (response.success && response.resource) {
        setListaItensAcerto(JSON.parse(JSON.stringify(response.resource)));
        setOpenitensAcerto(true);
      }
    }
  };

  const handleAcertar = async (value: acertoInterface, index: number) => {
    setAcerto(value);
    setIndexValue(index);
    setOpen(true);
  };

  const handleCloseListaUsuario = async (value: usuarioInterface) => {
    setUsuario(value);
    setOpenListaSearch(false);
    setTextSearch(value.nome);
  };

  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSearchUsuario();
    }
  };
  const handleConfirmar = async () => {
    const updateAcerto = {
      empresaId: acerto.empresaId,
      usuarioId: acerto.usuarioId,
      notaAcertoId: acerto.id,
    };
    const response = await AcertoServices.Update(updateAcerto);
    if (response.success) {
      const novaLista = Array.from(listaAcerto);
      novaLista.splice(indexValue, 1);
      setListaAcerto(novaLista);

      const novaListaAcertado = Array.from(listaAcertado);
      novaListaAcertado.push(acerto);

      setListaAcertado(novaListaAcertado);
    } else {
      toast({
        title: 'Lançar Acerto.',
        description: response.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setOpen(false);
  };

  const handleCancelar = () => {
    setOpen(false);
  };

  return (
    <Flex w='full'>
      {open && (
        <Alert
          confirmar={handleConfirmar}
          cancelar={handleCancelar}
          title="Acerto"
          message="Deseja realmente lançar o acerto, o acerto da
            nota não podera ser desfeito!"
          color="orange.300"
        />
      )}
          <Flex gap='8' h={500} w="full" py={10}>
            <Flex
              flexDir='column'
              borderRadius="md"
              bg="white"
              px="8"
              py="4"
              boxShadow="md"
              height="500px"
              w='full'
            >
              <Text>Nota Acerto</Text>
            
              <Flex align='flex-end' gap='1'>
                <InputControled
                  label='Nome do cobrador'
                  onKeyDown={handleKeyPress}
                  value={textSearch}
                  onChange={event => setTextSearch(event.target.value)}
                  
                />
                <IconButton
                  aria-label="open-search"
                  icon={<Icon as={FaSearch} />}
                  variant='link'
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={handleSearchUsuario}
                  mb='1'
                />
              </Flex>
              {usuario.id !== undefined ? (
                <>
                <TableContainer overflowY="auto" h="96" mt='4'>
                <Table
                  size="sm"
                  position="relative"
                >
                  <Thead>
                    <Tr position="sticky" top="0" h="8" bg='gray.300'>
                      <Th>Número</Th>
                      <Th>DATA LANÇ</Th>
                      <Th isNumeric>VALOR</Th>
                      <Th >ACERTAR</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {listaAcerto.map((acertoItem, index) => {
                      return (
                        <>
                          <Tr
                            key={acertoItem.id}
                            fontSize="xs"
                            color="gray.900"
                          >
                            <Td
                              textAlign="left"
                              fontSize="xs"
                              fontWeight="semibold"
                              cursor="pointer"
                              color="purple.400"
                              onClick={() => handleSelectAcerto(acertoItem)}
                            >
                              {acertoItem.id}
                            </Td>

                            <Td  fontSize="xs">
                              {moment(acertoItem.created, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}
                            </Td>
                            <Td  fontSize="xs" isNumeric>
                              {currencyFormatter(acertoItem.valor)}
                            </Td>
                            <Td fontSize="xs">
                              <Flex w='full' justify='center'>
                              <Icon
                                as={FaChevronCircleRight}
                                color="teal.400"
                                cursor="pointer"
                                fontSize="16"
                                onClick={() => handleAcertar(acertoItem, index)}
                              />
                              </Flex>
                            </Td>
                          </Tr>
                        </>
                      );
                    })}
                  </Tbody>
                </Table>
                </TableContainer>
                 <Flex>
                 <Flex flexDir='column'>
                   <Text fontSize='xs' >TOTAL</Text>
                   <Text fontWeight='semibold'>{currencyFormatter(totalAcertar)}</Text>
                 </Flex>
               </Flex>
               </>
              ) : (
                openSpinner && (
                  <Flex align="center" justify="center">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </Flex>
                )
              )}
            </Flex>
            <Flex
              flexDir='column'
              borderRadius='sm'
              bg="white"
              p="8"
              boxShadow="md"
              height="500px"
              w='full'

            >
              <Text>Notas Acertadas</Text>
              {usuario.id !== undefined ? (
                <>
                <TableContainer overflowY="auto" h="96" mt='4'>
                <Table
                  size="sm"
                  position="relative"
                  >
                  <Thead>
                    <Tr position="sticky" top="0" h="8" bg='gray.300'>
                      <Th>Número</Th>
                      <Th>DATA LANÇ</Th>
                      <Th isNumeric>VALOR</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {listaAcertado.map((acertoItem, index) => {
                      return (
                        <>
                          <Tr
                            key={acertoItem.id}
                            fontSize="xs"
                            color="gray.900"
                          >
                            <Td
                              fontSize="xs"
                              fontWeight="semibold"
                              cursor="pointer"
                              color="purple.400"
                              onClick={() => handleSelectAcerto(acertoItem)}
                            >
                              {acertoItem.id}
                            </Td>

                            <Td  fontSize="xs">
                              {moment(acertoItem.created, 'DD-MM-YYYY').format(
                                'DD/MM/YYYY',
                              )}
                            </Td>
                            <Td fontSize="xs" isNumeric>
                              {currencyFormatter(acertoItem.valor)}
                            </Td>
                          </Tr>
                        </>
                      );
                    })}
                  </Tbody>
                  
                </Table>
                </TableContainer>
                <Flex>
                 <Flex flexDir='column'>
                   <Text fontSize='xs' >TOTAL</Text>
                   <Text fontWeight='semibold'>{currencyFormatter(totalAcertado)}</Text>
                 </Flex>
               </Flex>
                </>
              ) : (
                openSpinner && (
                  <Flex align="center" justify="center">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </Flex>
                )
              )}
            </Flex>
          </Flex>
          {openListaSearch && (
            <SimpleList
              title="Consulta Cobrador"
              usuarios={usuarioLista}
              isOpen
              handleClose={handleCloseListaUsuario}
            />
          )}
          {openItensAcerto && (
            <ListaItensAcerto
              title="Acertos"
              notaAcerto={listaItensAcerto}
              handleClose={() => setOpenitensAcerto(false)}
              isOpen={!!openItensAcerto}
            />
          )}
        </Flex>
  );
};

export default NotaAcerto;
