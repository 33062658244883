import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps,
} from '@chakra-ui/react';
import { FieldError, Path, UseFormRegister } from 'react-hook-form';
import UFs from '../../utils/json/UF.json';
import { ref } from 'yup';

interface Props extends SelectProps {
  id: Path<any>
  register: UseFormRegister<any>
  label?: string;
  error?: FieldError;
}

export const SelectUF = ({ id,register, label, error, ...rest } : Props) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={id} fontSize="sm" pl={10} fontWeight="light">
          {label}
        </FormLabel>
      )}
      <ChakraSelect
        {...register(id)}
        placeholder="Selecione UF"
        borderRadius="md"
        focusBorderColor="emerald.500"
        variant="filled"
        bgColor="gray.200"
        boxShadow="lg"
        color="gray.600"
        _hover={{
          color: 'emerald.900',
        }}
        _focus={{
          bg: 'gray.100',
          color: 'emerald.600',
        }}
        size="sm"
        {...rest}
      >
        {UFs.map(item => {
          return (
            <option key={item.id} value={item.sigla}>
              {item.sigla}
            </option>
          );
        })}
      </ChakraSelect>
      {!!error && <FormErrorMessage pl={10}>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

