import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';

import Carne from '../../interface/carne';
import { errorInternet } from '../usuario';

export async function Insert(data: any): Promise<baseResponse> {
  return api
    .post('/carne', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}

export async function Baixar(data: any): Promise<baseResponse> {
  return api
    .post('/carne/baixar', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}

export async function Cancelar(carneId: number): Promise<baseResponse> {
  return api
    .post(
      '/carne/cancelar',
      {},
      {
        params: {
          carneId,
        },
      },
    )
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}

export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/carne/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}

export async function Delete(data: Carne): Promise<baseResponse> {
  return api
    .delete(`/carne/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetAll(
  empresaId: number,
  showBaixados = false,
): Promise<baseResponse> {
  return api
    .get(`/carne/consulta`, {
      params: {
        empresaId,
        showBaixados,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetDisponiveis(empresaId: number): Promise<baseResponse> {
  return api
    .get(`/carne/baixar/disponiveis`, {
      params: {
        empresaId,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetById(id: number): Promise<baseResponse> {
  return api
    .get(`/carne/${id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetByClienteId(
  empresaId: number,
  clienteId: number,
  showBaixados = false,
): Promise<baseResponse> {
  return api
    .get(`/carne/consulta/`, {
      params: {
        empresaId,
        clienteId,
        showBaixados,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetByUsuarioId(
  empresaId: number,
  usuarioId: number,
  showBaixados = false,
): Promise<baseResponse> {
  return api
    .get(`/carne/consulta`, {
      params: {
        empresaId,
        usuarioId,
        showBaixados,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetByReferencia(
  numeroReferencia: string,
  empresaId: number,
): Promise<baseResponse> {
  return api
    .get(`/carne/consulta`, {
      params: {
        numeroReferencia,
        empresaId,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}
export async function GetSemVinculo(empresaId: number): Promise<baseResponse> {
  return api
    .get(`/carne/disponiveis`, {
      params: { empresaId },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}
