/* eslint-disable react/no-children-prop */
import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Grid,
  GridItem,
  InputGroup,
  InputLeftAddon,
  useToast,
  Text,
  Flex,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectUF } from '../../components/Form/selectUF';
import { SelectMunicipio } from '../../components/Form/selectMunicipio';
import CarneCreate from '../carne/create';

import * as MunicipioServices from '../../services/municipio';

import * as ClienteServices from '../../services/cliente';
import empresaContext from '../../contexts/empresa';
import Cliente from '../../interface/cliente';
import { Input } from '../../components/Form/Input';
import Button from '../../components/button';
import carneInterface from '../../interface/carne';

interface Props {
  cliente: Cliente;
  handleClose(cliente: Cliente): void;
}

export interface Municipio {
  codigoMunicipio: string;
  nomeMunicipio: string;
  uf: string;
  id: number;
}

const FormSchema = yup.object().shape({
  nomeCompleto: yup
    .string()
    .required('Nome obrigatório')
    .min(3, 'nome deve ter o minimo 3 caracteres'),

  email: yup.string().email('Digite um email valido'),

  cpf: yup
    .string()
    .required('CPF obrigatório')
    .min(11, 'nome deve ter o minimo 11 caracteres'),

  celular: yup
    .string()
    .required('Celular obrigatório')
    .min(11, 'Celular deve ter o minimo 11 caracteres'),

  logradouro: yup
    .string()
    .required('Logradouro obrigatório')
    .min(4, 'logradouro deve ter o minimo  4 caracteres'),
  numero: yup.string().required('Número obrigatório'),
  bairro: yup
    .string()
    .required('Bairro obrigatório')
    .min(4, 'logradouro deve ter o minimo  4 caracteres'),
  municipioId: yup.number().required('Municipio obrigatório'),
  uf: yup.string().required('UF obrigatório'),
  rg:yup.string(),
  telefone: yup.string(),
  complemento: yup.string(),
  cep:yup.string(),
});

const ClienteCreate: React.FC<Props> = ({ handleClose, cliente }) => {
  const { empresa } = useContext(empresaContext);
  const toast = useToast();
  const [edit, setEdit] = useState(false);
  const [openCarne, setOpenCarne] = useState(false);
  const [clienteNovo, setClienteNovo] = useState<Cliente>({} as Cliente);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState:{errors,isSubmitting},
  } = useForm({
    resolver: yupResolver(FormSchema),
  });
  const [listaMunicipios, setListaMunicipios] = useState<Municipio[]>([]);
  const [uf, setUF] = useState('');

  useEffect(() => {
    const setCliente = async () => {
      setValue('nomeCompleto', cliente.nomeCompleto);
      setValue('email', cliente.email);
      setValue('cpf', cliente.cpf);
      setValue('rg', cliente.rg);
      setValue('telefone', cliente.telefone);
      setValue('celular', cliente.celular);
      setValue('logradouro', cliente.logradouro);
      setValue('numero', cliente.numero);
      setValue('complemento', cliente.complemento);
      setValue('bairro', cliente.bairro);
      setValue('uf', cliente.uf);
      await setUF(cliente.uf);
      setValue('cep', cliente.cep);
      setEdit(true);
      setClienteNovo(cliente);

    };
    if (cliente.id !== undefined) setCliente();
  }, []);

  useEffect(() => {
    async function getMunicipios() {
      if (cliente.id !== undefined ) {
        const response = await MunicipioServices.GetByUF(cliente.uf);
        if (response.success && response.resource) {
          await setListaMunicipios(
            JSON.parse(JSON.stringify(response.resource)),
          );
          if (cliente.municipioId !== undefined) {
            await setValue('municipioId', cliente.municipioId);
          }
        }
      }else{
        const response = await MunicipioServices.GetByUF(uf);
        if (response.success && response.resource) {
          await setListaMunicipios(
            JSON.parse(JSON.stringify(response.resource)),
          );

        }

      }
    }
    getMunicipios();
  }, [uf]);

  interface clienteInsert {
    id: number;
    nomeCompleto: string;
    email: string;
    cpf: string;
    rg: string;
    telefone: string;
    celular: string;
    logradouro: string;
    numero: number;
    complemento: string;
    bairro: string;
    municipioId: number;
    uf: string;
    cep: string;
  }

  const handleEdit = async (values: any) => {
    const editCliente = {
      id: cliente.id,
      lastUpdated: cliente.lastUpdated,
      nomeCompleto: values.nomeCompleto,
      email: values.email,
      cpf: values.cpf,
      rg: values.rg,
      telefone: values.telefone,
      celular: values.celular,
      logradouro: values.logradouro,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      municipioId: values.municipioId,
      uf: values.uf,
      cep: values.cep,
      empresaId: cliente.empresaId,
    };
    const response = await ClienteServices.Update(editCliente);
    if (response.success && response.resource) {
      toast({
        title: 'Alterar Cliente.',
        description: 'Cliente alterado com sucesso!.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      handleClose({} as Cliente);
    } else {
      toast({
        title: 'Alterar Cliente.',
        description: response.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleInsertCliente = async () => {
    const values = getValues()
    const InsertCliente = {
      nomeCompleto: values.nomeCompleto,
      email: values.email,
      cpf: values.cpf,
      rg: values.rg,
      telefone: values.telefone,
      celular: values.celular,
      logradouro: values.logradouro,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      municipioId: values.municipioId,
      uf: values.uf,
      cep: values.cep,
      empresaId: empresa?.id,
    };
    if (cliente.id !== undefined) {
      handleEdit(values);
    } else {
      const response = await ClienteServices.Insert(InsertCliente);
      if (response.success && response.resource) {
        toast({
          title: 'Salvar Cliente.',
          description: 'Cliente inserido com sucesso!.',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });

        handleClose({} as Cliente);
      } else {
        toast({
          title: 'Salvar Cliente.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleChangeUF = (values: React.ChangeEvent<HTMLSelectElement>) => {
    setListaMunicipios([]);
    setUF(values.target.value);
  };

  const handleCloseCreateCarne = () => {
    setOpenCarne(false);
  };

  const handleInsertClienteCarne = async () => {
    const clienteCarne = JSON.parse(JSON.stringify(getValues()));
    if (cliente.id !== undefined) {
      setOpenCarne(true);
      return;
    }
    if (cliente.nomeCompleto !== undefined) {
      return;
    }

    const InsertCliente = {
      nomeCompleto: clienteCarne.nomeCompleto,
      email: clienteCarne.email,
      cpf: clienteCarne.cpf,
      rg: clienteCarne.rg,
      telefone: clienteCarne.telefone,
      celular: clienteCarne.celular,
      logradouro: clienteCarne.logradouro,
      numero: clienteCarne.numero,
      complemento: clienteCarne.complemento,
      bairro: clienteCarne.bairro,
      municipioId: clienteCarne.municipioId,
      uf: clienteCarne.uf,
      cep: clienteCarne.cep,
      empresaId: empresa?.id,
    };

    const response = await ClienteServices.Insert(InsertCliente);
    if (response.success && response.resource) {
      toast({
        title: 'Salvar Cliente.',
        description: 'Cliente inserido com sucesso!.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setClienteNovo(JSON.parse(JSON.stringify(response.resource)));
      setOpenCarne(true);
    } else {
      toast({
        title: 'Salvar Cliente.',
        description: response.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column">
      {openCarne ? (
        <CarneCreate
          carne={{} as carneInterface}
          handleClose={() => console.log('')}
          handleCloseCliente={handleCloseCreateCarne}
          parametroCliente={clienteNovo}
        />
      ) : (
        <>
          <Text
            casing="uppercase"
            fontSize="sm"
            color="gray.600"
            fontWeight="semibold"
            mb="4"
          >
            Cadastro de Cliente
          </Text>

          <Box
            as="form"
            display="flex"
            borderRadius={8}
            bg="white"
            px="8"
            py="4"
            boxShadow="lg"
            h={500}
            onSubmit={handleSubmit(handleInsertCliente)}
          >
            <Grid
              h={350}
              templateRows="repeat(8, 1fr)"
              templateColumns="repeat(8, 1fr)"
              gap={4}
            >
              <GridItem colSpan={4}>
                <Input
                  id="nomeCompleto"
                  type="text"
                  error={errors.nomeCompleto}
                  label="Nome"
                  register={register}
                />
              </GridItem>
              <GridItem colSpan={4}>
                <Input
                  id="email"
                  type="email"
                  error={errors.email}
                  label="Email"
                  register={register}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  id="cpf"
                  type="number"
                  error={errors.cpf}
                  label="CPF"
                  register={register}
                  maxLength={11}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  id="rg"
                  type="number"
                  error={errors.rg}
                  label="RG"
                  register={register}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  id="telefone"
                  type="number"
                  label="Telefone"
                  register={register}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  id="celular"
                  type="number"
                  label="Celular"
                  register={register}
                  error={errors.celular}
                />
              </GridItem>

              <GridItem colSpan={2}>
                <Input id="cep" type="number" label="CEP" register={register} />
              </GridItem>
              <GridItem colSpan={4}>
                <Input
                  id="logradouro"
                  type="text"
                  error={errors.logradouro}
                  label="Av. Rua..."
                  register={register}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  id="numero"
                  type="text"
                  error={errors.numero}
                  label="Número"
                  register={register}
                />
              </GridItem>
              <GridItem colSpan={4}>
                <Input
                  id="complemento"
                  type="text"
                  label="Complemento"
                  register={register}
                />
              </GridItem>
              <GridItem colSpan={4}>
                <Input
                  id="bairro"
                  type="text"
                  error={errors.bairro}
                  label="Bairro"
                  register={register}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <SelectUF
                  id="uf"
                  error={errors.uf}
                  label="UF"
                  register={register}
                  onChange={e => handleChangeUF(e)}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <SelectMunicipio
                  id="municipioId"
                  error={errors.municipioId}
                  label="Municipios"
                  register={register}
                  municipios={listaMunicipios}
                />
              </GridItem>
              <GridItem colSpan={4} />
              <GridItem colSpan={2}>
                {edit ? (
                  <Button type="submit" w="100%" mt="8">
                    Salvar
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    w="100%"
                    mt="8"
                    isLoading={isSubmitting}
                  >
                    Salvar
                  </Button>
                )}
              </GridItem>
              <GridItem colSpan={2}>
                <Button
                  onClick={() => handleClose({} as Cliente)}
                  type="button"
                  w="100%"
                  mt="8"
                  bg="orange.300"
                  color="orange.900"
                  isLoading={isSubmitting}
                >
                  Cancelar
                </Button>
              </GridItem>
              <GridItem colSpan={2}>
                <Button
                  onClick={() => handleInsertClienteCarne()}
                  type="button"
                  w="100%"
                  mt="8"
                  bg="purple.300"
                  color="purple.900"
                  isLoading={isSubmitting}
                >
                  Novo Carne
                </Button>
              </GridItem>
            </Grid>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default ClienteCreate;
