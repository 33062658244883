/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import {
  Flex,
  Stack,
  Text,
  Button,
  SimpleGrid,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  List,
  ListItem,
} from '@chakra-ui/react';
import moment from 'moment';
import {
  FaRegCheckCircle,
  FaRegTimesCircle,
  FaRegCalendarTimes,
} from 'react-icons/fa';
import { title } from 'process';
import parcela from '../../interface/parcela';
import carneInterface from '../../interface/carne';

interface Props {
  carne: carneInterface;
  isOpen: boolean;
  onClose(): void;
}

const ListaParcelas: React.FC<Props> = ({ carne, isOpen, onClose }) => {
  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Carne ${carne.numeroReferencia}`}</ModalHeader>
        <Flex direction="column" pl="8">
          <Text casing="capitalize">{carne.descricaoProdutos}</Text>
          <Text>
            {`Valor da compra: ${currencyFormatter(carne.valorCompra)}`}
          </Text>
          <Text>
            {`Data da Compra: ${carne.dataCompra.toLocaleString('pt-BR')}`}
          </Text>
          {carne.dataLiquidacao && (
            <Text>
              {`Data da Liquidação: ${ carne.dataLiquidacao.toLocaleString('pt-BR')}`}
            </Text>
          )}
        </Flex>
        <ModalBody>
          <List overflowY="scroll" spacing="2" px="2" h="96" py="2">
            {carne.parcelas.map((item: parcela) => {
              return (
                <ListItem
                  key={item.numeroParcela}
                  h="28"
                  bg={
                    item.cancelado
                      ? 'red.300'
                      : item.dataQuitacao
                      ? 'green.300'
                      :  item.dataVencimento < new Date()
                      ? 'orange.300'
                      : 'gray.100'
                  }
                  borderRadius="md"
                  mx="4"
                  boxShadow="base"
                  transition="0.2s"
                  _hover={{
                    transform: 'translateX(10px)',
                  }}
                  px="5"
                  py="5"
                >
                  <SimpleGrid columns={2} spacingX="5px">
                    <Flex direction="column" w="80" color="gray.800">
                      <Text fontSize="small">
                        Parcela:
                        {` ${item.numeroParcela}`}
                      </Text>
                      <Text fontSize="small">
                        Valor:
                        {` ${item.valorParcela.toFixed(2)}`}
                      </Text>
                      <Text fontSize="small">
                        Vencimento:
                        {` ${moment(item.dataVencimento, 'DD-MM-YYYY').format(
                          'DD/MM/YYYY',
                        )}`}
                      </Text>
                      <Text fontSize="small">
                        Quitado:
                        {item.dataQuitacao &&
                          ` ${moment(item.dataQuitacao, 'DD-MM-YYYY').format(
                            'DD/MM/YYYY',
                          )}`}
                      </Text>
                    </Flex>
                    <Flex
                      align="flex-end"
                      w="50"
                      justify="center"
                      direction="column"
                    >
                      {item.cancelado ? (
                        <Flex
                          direction="column"
                          align="center"
                          justify="center"
                        >
                          <Icon
                            as={FaRegTimesCircle}
                            fontSize="3xl"
                            color="red.900"
                            opacity="0.2"
                          />
                          <Text
                            fontWeight="semibold"
                            color="red.900"
                            opacity="0.2"
                          >
                            Cancelado
                          </Text>
                        </Flex>
                      ) : item.dataQuitacao ? (
                        <Flex
                          direction="column"
                          align="center"
                          justify="center"
                        >
                          <Icon
                            as={FaRegCheckCircle}
                            fontSize="3xl"
                            color="green.900"
                            opacity="0.5"
                          />
                          <Text
                            fontWeight="semibold"
                            color="green.900"
                            opacity="0.5"
                          >
                            Pago
                          </Text>
                        </Flex>
                      ) : 
                          item.dataVencimento < new Date() ? (
                        <Flex
                          direction="column"
                          align="center"
                          justify="center"
                        >
                          <Icon
                            as={FaRegCalendarTimes}
                            fontSize="3xl"
                            color="orange.900"
                            opacity="0.5"
                          />
                          <Text
                            fontWeight="semibold"
                            color="orange.900"
                            opacity="0.5"
                          >
                            Atrasado
                          </Text>
                        </Flex>
                      ) : (
                        <></>
                      )}
                    </Flex>
                  </SimpleGrid>
                </ListItem>
              );
            })}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ListaParcelas;
