import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import * as fns from 'date-fns';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FaArrowLeft,
  FaDollarSign,
  FaEdit,
  FaRegTimesCircle,
} from 'react-icons/fa';
import ptBR from 'date-fns/locale/pt-BR';
import parcelaInterface from '../../interface/parcela';
import { Input } from '../Form/Input';
import Button from '../button';
import * as FormaPagamentoServices from '../../services/formaPagamento';
import FormaDePagamento from '../../interface/formaPagamento';
import Alert from '../alertDialog';
import { InputControled } from '../Form/inputControled';


interface parcelaEditProps {
  parcela: parcelaInterface;
  isOpen: boolean;
  onClose(parcela: any): void;
  onQuitar(parcela: any, formaPagamento: FormaDePagamento): void;
  onCancelar(parcela: any): void;
}

const ParcelaEdit: React.FC<parcelaEditProps> = ({
  parcela,
  isOpen,
  onClose,
  onQuitar,
  onCancelar,
}) => {
  const [selected, setSelected] = useState(-1);
  const [dataVencimento, setDataVencimento] = useState<Date | null>(null);
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [open, setOpen] = useState(false);

  const [formapagamento, setFormaPagamento] = useState<FormaDePagamento>(
    {} as FormaDePagamento,
  );
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState:{errors,isSubmitting}
    
  } = useForm();

  useEffect(() => {
    const getFormaPagamento = async () => {
      const response = await FormaPagamentoServices.GetAll();
      if (response.success && response.resource) {
        setFormasPagamento(JSON.parse(JSON.stringify(response.resource)));
      }
    };
    getFormaPagamento();
  }, []);
  useEffect(() => {
    setValue('id', parcela.id);
    setValue('carneId', parcela.carneId);
    setValue('numeroParcela', parcela.numeroParcela);

    setDataVencimento(parcela.dataVencimento);

    if (parcela.dataQuitacao) {
      setValue(
        'dataQuitacao',
        fns.parseISO(parcela.dataQuitacao.toISOString()),
      );
    }

    setValue('valorParcela', parcela.valorParcela.toFixed(2));
    setValue('valorTotalAReceber', parcela.valorTotalAReceber.toFixed(2));
    setValue('empresaId', parcela.empresaId);
  }, []);

  const SelectFormaPagamento = (item: FormaDePagamento, id: number) => {
    setFormaPagamento(item);
    setSelected(id);
  };

  const handleClose = (value: any) => {
    if (dataVencimento) {
      const parcelaNova = {
        id: parseInt(value.id.toString(), 10),
        carneId: parseInt(value.carneId.toString(), 10),
        numeroParcela: parseInt(value.numeroParcela.toString(), 10),
        dataVencimento: fns.parseISO(dataVencimento.toISOString()),
        dataQuitacao: value.dataQuitacao,
        valorParcela: parseFloat(
          value.valorParcela.toString().replace(',', '.'),
        ),
        empresaId: parseInt(value.empresaId.toString(), 10),
      };
      onClose(parcelaNova);
    }
  };

  const handleConfirmar = (value: any) => {
    if (dataVencimento) {
      const parcelaNova = {
        id: parseInt(value.id.toString(), 10),
        carneId: parseInt(value.carneId.toString(), 10),
        numeroParcela: parseInt(value.numeroParcela.toString(), 10),
        dataVencimento: fns.parseISO(dataVencimento.toISOString()),
        dataQuitacao: value.dataQuitacao,
        valorParcela: parseFloat(
          value.valorParcela.toString().replace(',', '.'),
        ),
        empresaId: parseInt(value.empresaId.toString(), 10),
      };
      onCancelar(parcelaNova);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose({})}
      isCentered
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p="4">
      {open && (
        <Alert
          confirmar={() => handleConfirmar(getValues())}
          cancelar={() => setOpen(false)}
          title="Parcela"
          message="Ao cancelar a parcela a ação não podera ser desfeita!"
          color="orange.300"
        />
      )}
     
        <Tabs variant="soft-rounded" colorScheme="emerald">
          <TabList>
            <Tab>Editar</Tab>
            <Tab>Quitar</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Grid
                h={250}
                templateRows="repeat(8, 1fr)"
                templateColumns="repeat(8, 1fr)"
                gap={4}
              >
                <GridItem colSpan={4}>
                  <Input
                    id="numeroParcela"
                    type="text"
                    label="Parcela"
                    register={register}
                    isReadOnly
                  />
                </GridItem>
                <GridItem colSpan={4}>
                 
                    
                      <InputControled label='Data Vencimento' type='date' value={dataVencimento?.toLocaleString('pt-BR')} onChange={(date) => setDataVencimento(new Date(date.target.value + 'EDT'))}/>
                     
                </GridItem>
                <GridItem colSpan={4}>
                  <Input
                    id="valorParcela"
                    type="text"
                    label="Valor"
                    register={register}
                    isReadOnly
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <Button
                    mt="30"
                    type="button"
                    onClick={() => handleClose(getValues())}
                    isLoading={isSubmitting}
                    leftIcon={<Icon as={FaEdit} />}
                  >
                    Editar
                  </Button>
                </GridItem>
                <GridItem colSpan={2}>
                  <Button
                    bg="red.400"
                    mt="30"
                    type="button"
                    color="red.900"
                    onClick={() => setOpen(true)}
                    isLoading={isSubmitting}
                    leftIcon={<Icon as={FaRegTimesCircle} />}
                  >
                    Cancelar
                  </Button>
                </GridItem>

                <GridItem colSpan={8}>
                  <Input id="id" label='' type="text" register={register} hidden />
                  <Input id="carneId" label='' type="text" register={register} hidden />
                  <Input id="empresaId" label='' type="text" register={register} hidden />
                </GridItem>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid
                h={250}
                templateRows="repeat(8, 1fr)"
                templateColumns="repeat(8, 1fr)"
                gap={4}
              >
                <GridItem colSpan={8}>
                  <Text ml="10">Formas de Pagamento</Text>
                  <Flex direction="row">
                    {formasPagamento.map((item: FormaDePagamento, id) => {
                      return (
                        <Flex
                          key={item.id}
                          bg={selected === id ? 'emerald.600' : 'emerald.200'}
                          mx="2"
                          w="auto"
                          p="2"
                          borderRadius="lg"
                          align="center"
                          justify="center"
                          cursor="pointer"
                          onClick={() => SelectFormaPagamento(item, id)}
                        >
                          <Text
                            fontSize="sm"
                            color={selected === id ? 'white' : 'emerald.900'}
                          >
                            {item.descricao}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                </GridItem>
                <GridItem colSpan={4}>
                  <Input
                    id="valorTotalAReceber"
                    type="text"
                    label="Valor a Receber"
                    register={register}
                  />
                </GridItem>
                <GridItem colSpan={4}>
                  <Button
                    mt="30"
                    type="button"
                    bg="indigo.400"
                    color="indigo.800"
                    onClick={() => onQuitar(getValues(), formapagamento)}
                    isLoading={isSubmitting}
                    leftIcon={<Icon as={FaDollarSign} />}
                  >
                    Quitar
                  </Button>
                </GridItem>
                <GridItem colSpan={8}>
                  <Input id="id" label=''type="text" register={register} hidden />
                  <Input id="carneId" label='' type="text" register={register} hidden />
                  <Input id="empresaId" label='' type="text" register={register} hidden />
                </GridItem>
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Flex justify="flex-end">
          <Button
            type="button"
            onClick={() => onClose({})}
            bg="orange.400"
            color="orange.800"
            leftIcon={<Icon as={FaArrowLeft} />}
            _hover={{
              bg: 'orange.500',
            }}
          >
            Sair
          </Button>
        </Flex>
    </ModalBody>
    </ModalContent>
    </Modal>
  );
};

export default ParcelaEdit;
