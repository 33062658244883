import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';
import { errorInternet } from '../usuario';

export async function Insert(data: any): Promise<baseResponse> {
  return api
    .post('/acesso', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/acesso/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}

export async function Delete(data: any): Promise<baseResponse> {
  return api
    .delete(`/acesso/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetAll(): Promise<baseResponse> {
  return api
    .get('/acesso')
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}
export async function GetById(usuarioId: number): Promise<baseResponse> {
  return api
    .get(`/acesso/consulta/${usuarioId}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}
