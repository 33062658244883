import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import SideBar from '../../components/sideBar';
import ImportFiles from '../../components/uploadClientes/index.js';
import ImportCarne from '../../components/uploadCarne/index.js';

import empresaContext from '../../contexts/empresa';

const Importar: React.FC = () => {
  const [empresaId, setEmpresaId] = useState(0);
  const { empresa } = useContext(empresaContext);

  useEffect(() => {
    if (empresa) {
      setEmpresaId(empresa.id);
    }
  }, []);

  return (
    <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
      <SimpleGrid columns={2} spacing={10} h={500} w="100%" py="8">
        <Box
          borderRadius="md"
          bg="white"
          px="8"
          py="4"
          boxShadow="lg"
          height="500px"
        >
          <a
            href="https://www.bravetech.com.br/download/importacao.pdf"
            target="blank"
          >
            <Text fontSize="smaller" fontWeight="light" as="u">
              Em dúvida de como fazer a imporação ? clique aqui!
            </Text>
          </a>
          <ImportFiles empresaId={empresaId} />
        </Box>
        <Box
          borderRadius="md"
          bg="white"
          px="8"
          py="4"
          boxShadow="lg"
          height="500px"
        >
          <ImportCarne empresaId={empresaId} />
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default Importar;
