export default {
  title: 'light',
  colors: {
    primary: '#00B4D4',
    secundary: '#00E0D3',
    background: '#F5F7FE',
    white: '#ffffff',
    black: '#000000',
    text: '#414959',
    border: '#c2c2c2',
    err: '#ff6464',
  },
};
