/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button as ChakraButton,
  Collapse,
  Divider,
  Fade,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Table,
  Text,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  TableContainer,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FaPlus,
  FaRegTrashAlt,
  FaSave,
  FaTrash,
  FaEdit,
  FaArrowLeft,
  FaCheckCircle,
} from 'react-icons/fa';
import * as fns from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Button from '../../components/button';
import * as ParcelaServices from '../../services/parcela';
import * as CarneService from '../../services/carne';

import parcelaInterface from '../../interface/parcela';
import { Input } from '../../components/Form/Input';
import Carne from '../../interface/carne';
import empresaContex from '../../contexts/empresa';
import pagamentoInterface from '../../interface/pagamento';
import clienteInterface from '../../interface/cliente';
import { format, isValid } from 'date-fns';
import { InputControled } from '../../components/Form/inputControled';
import carne from '../carne';
import moment from 'moment';
interface ParcelaProps {
  carne: Carne;
  handleClose(carne: Carne): void;
}
const FormSchema = yup.object().shape({
  numeroParcela: yup.string().required('Referência obrigatório'),
  dataVencimento: yup.date().required('Data é obrigatório'),
  valorParcela: yup.number().required('Valor obrigatório'),
  numeroReferencia: yup.string(),
  valorCompra: yup.number(),
  quantidadeParcelas: yup.number().default(0),
});

const Parcela: React.FC<ParcelaProps> = ({ carne, handleClose }) => {
  const toast = useToast();

  const { empresa } = useContext(empresaContex);
  const { isOpen, onToggle } = useDisclosure();
  const [openListaParcela, setOpenListaParcela] = useState(false);
  const [parcela, setParcela] = useState<parcelaInterface>(
    {} as parcelaInterface,
  );
  const [pagamentos, setPagamentos] = useState<pagamentoInterface[]>([]);
  const [countParcela, setCountParcela] = useState(1);
  const [quantidadeDias, setQuantidadeDias] = useState(0);
  const [valorTotalParcela, setValorTotalParcela] = useState(0);
  const [selectDate, setSelectedDate] = useState(new Date());
  const [dataFixa, setDataFixa] = useState<Date | null>(null);

  const [cliente, setCliente] = useState<clienteInterface>(
    {} as clienteInterface,
  );
  const [listaParcela, setListaParcela] = useState<parcelaInterface[]>([]);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState:{errors,isSubmitting}  } = useForm({
    resolver: yupResolver(FormSchema),
  });

  useEffect(() => {
    const getParcelas = async () => {
      if (empresa) {
        const response = await ParcelaServices.GetByCarneId(
          empresa.id,
          carne.id,
        );
        if (response.success && response.resource) {
          setListaParcela(JSON.parse(JSON.stringify(response.resource)));
        }
      }
    };
    getParcelas();
  }, []);

  useEffect(() => {
    if (carne.id !== undefined) {
      setValue('numeroReferencia', carne.numeroReferencia);
      setValue('valorCompra', parseFloat(carne.valorCompra.toFixed(2)));
    }
  }, []);

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < listaParcela.length; i += 1) {
      total += listaParcela[i].valorParcela;
    }

    setValorTotalParcela(total);
  }, [listaParcela]);

  function currencyFormatter(value: number) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  const handleLancarParcela = async (value: any) => {
    if (carne.valorCompra === valorTotalParcela) {
      toast({
        title: 'Lançar Parcela.',
        description: 'Valor das parcelas não pode ser maior que da compra',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    if (value) {
      const itensCopy = Array.from(listaParcela);
      const quantidadeParcelas = getValues('quantidadeParcelas');

      if ( quantidadeParcelas > 0) {
        let numeroParcela = listaParcela.length + 1;
        let index = 0;
        for (index; index < quantidadeParcelas; index += 1) {
          if (dataFixa) {
            const data = fns.parseISO(dataFixa.toISOString());
            const dt = fns.add(data, { months: index });
            const newParcela = {
              id: 0,
              carneId: carne.id,
              numeroParcela,
              dataVencimento: dt,

              valorParcela: parseFloat(
                (
                  carne.valorTotalAReceber / getValues('quantidadeParcelas')
                ).toFixed(2),
              ),
              dataQuitacao: null,
              empresaId: carne.empresaId,
              valorTotalAReceber: 0,
            };
            itensCopy.push(newParcela);
            numeroParcela += 1;
          }
        }

        const total = parseFloat(
          itensCopy
            .reduce(
              (prevValue, currentValue) =>
                prevValue + currentValue.valorParcela,
              0,
            )
            .toFixed(2),
        );
        if (total > 0) {
          const diferença = parseFloat((total - carne.valorCompra).toFixed(2));

          const item = itensCopy[itensCopy.length - 1];
          const novaParcela = {
            id: 0,
            carneId: item.carneId,
            numeroParcela: item.numeroParcela,
            dataVencimento: item.dataVencimento,
            dataQuitacao: null,
            valorParcela: parseFloat(
              (item.valorParcela - diferença).toFixed(2),
            ),
            empresaId: item.empresaId,
            valorTotalAReceber: 0,
          };

          itensCopy.splice(itensCopy.length - 1, 1, novaParcela);
        }
        await setListaParcela(itensCopy);

        setParcela({} as parcelaInterface);
      } else {
        toast({
          title: 'Lançar Parcela.',
          description: 'Qtd Parcela Qtd Dias ou Dia Fixo obrigatório! ',
          status: 'warning',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleEdit = (index: number) => {
    const itensCopy = Array.from(listaParcela);
    const newParcela = {
      id: 0,
      carneId: carne.id,
      numeroParcela: countParcela,
      dataVencimento: getValues('dataVencimento'),
      dataQuitacao: null,
      valorParcela: getValues('valorParcela'),
      empresaId: carne.empresaId,
      valorTotalAReceber: 0,
    };
    itensCopy.splice(index, 1, newParcela);
    setListaParcela(itensCopy);
  };

  const handleSalvarParcelas = async () => {
    const response = await ParcelaServices.Insert(listaParcela);
    if (response.success && response.resource) {
      const responseCarne = await CarneService.GetByReferencia(
        carne.numeroReferencia,
        carne.empresaId,
      );
      if (responseCarne.success) {
        toast({
          title: 'Salvar Parcela.',
          description: 'Parcelas registradas com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        handleClose(JSON.parse(JSON.stringify(responseCarne.resource)));
      }
    } else {
      toast({
        title: 'Lançar Parcela.',
        description: response.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleReset = () => {
    const novaLista = Array.from(listaParcela).filter(item => item.id !== 0);
    if (novaLista.length === listaParcela.length) {
      toast({
        title: 'Limpar Parcela.',
        description:
          'Todas as parcelas ja foram inclidas na base de dados, exclua ou edite a parcela',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
    }
    setListaParcela(novaLista);
    setQuantidadeDias(0);
  };

  return (
    <Flex w='full' h='full'>
      <Grid
        w="100%"
        templateColumns="repeat(6, 1fr)"
        gap={5}
      >
        <GridItem
          colSpan={3}
          bg="white"
          p="3"
          h='full'
          borderRadius="sm"
          boxShadow="md"
        >
          <Flex direction="column">
            <Text fontSize="xl" fontWeight="semibold">
              {carne.cliente?.nomeCompleto}
            </Text>
            <Text>{`Carne: ${carne.numeroReferencia}`}</Text>
            <Text>
            {moment(carne.dataCompra, 'DD-MM-YYYY').format(
                            'DD/MM/YYYY',
                          )}
            </Text>
            <Text>
              {`Valor da Compra: ${currencyFormatter(carne.valorCompra)}`}
            </Text>
            <Text>
              {`Total das Parcelas: ${currencyFormatter(valorTotalParcela)}`}
            </Text>
          </Flex>

          <Flex direction="row" mt="8">
            <Input
              id="quantidadeParcelas"
              type="text"
              label="Qtd Parcelas"
              register={register}
              w="40"
            />

                <InputControled
                  label='Data fixa'
                  type='date'
                  value={
                    dataFixa ? format(dataFixa, "yyyy-MM-dd") : ""
                  }
                  onChange={(date) =>
                    isValid(new Date(date.target.value))
                      ? setDataFixa(new Date(date.target.value + "EDT"))
                      : ""
                  }
                />

          </Flex>
          <Flex direction="column" mt="6">
            <Button
              type="button"
              w="52"
              onClick={() => {
                const value = getValues();
                handleLancarParcela(value);
              }}
              leftIcon={<Icon as={FaPlus} />}
              mt="30"
            >
              Adicionar Parcela
            </Button>
          </Flex>
        </GridItem>
        <GridItem
          colSpan={3}
          bg="white"
          boxShadow="md"
          borderRadius="sm"
          h='full'
        >
          <Flex flexDir='column' h="full" pb='4' px='4'>
         <TableContainer overflowY="auto" h="full" mt='4'>
          <Table
              size="sm"
              position="relative"
            >
            <Thead >
              <Tr position="sticky" top="0" h="8" bg='gray.300'>
                <Th >
                  PARCELA
                </Th>
                <Th >
                  VENCIMENTO
                </Th>

                <Th isNumeric>
                  VALOR
                </Th>
                <Th >
                  PAGO
                </Th>
              </Tr>
            </Thead>
            <Tbody >
              {listaParcela.map(parcelaItem => {
                return (
                  <>
                    <Tr
                      key={parcelaItem.id}
                      fontSize="xs"
                    >
                      <Td
                        fontSize="xs"
                        fontWeight="semibold"
                        cursor="pointer"
                        color="indigo.400"
                      >
                        {parcelaItem.numeroParcela}
                      </Td>
                      <Td  fontSize="xs">
                      {format(new Date(parcelaItem.dataVencimento), "dd/MM/yyyy", {
                        locale: ptBR,
                      })}

                      </Td>

                      <Td  fontSize="xs" isNumeric>
                        {currencyFormatter(parcelaItem.valorParcela)}
                      </Td>
                      <Td  fontSize="xs">
                        {parcelaItem.dataQuitacao !== null &&
                        parcelaItem.id !== 0 ? (
                          <Icon
                            as={FaCheckCircle}
                            color="teal.300"
                            fontSize={18}
                          />
                        ) : parcelaItem.dataQuitacao === null &&
                          parcelaItem.id !== 0 ? (
                          // eslint-disable-next-line react/jsx-indent
                          <Text fontWeight="semibold" color="purple.300">
                            EDITAR
                          </Text>
                        ) : (
                          <></>
                        )}
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          </Table>
          </TableContainer>
          <Flex gap='4'>
          <Button
            type="button"
            onClick={handleSalvarParcelas}
            leftIcon={<Icon as={FaSave} />}
          >
            Salvar Parcelas
          </Button>
          <Button
            type="button"
            onClick={handleReset}
            leftIcon={<Icon as={FaTrash} />}
            bg="orange"
            color="orange.700"
          >
            Limpar Parcelas
          </Button>
          <Button
            type="button"
            onClick={() => handleClose(carne)}
            leftIcon={<Icon as={FaArrowLeft} />}
            bg="orange"
            color="orange.700"
          >
            Sair
          </Button>
          </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default Parcela;
