import React, { useContext } from 'react';
import AppRoutes from './app.routes';
import Autentication from './autenticacao.routes';
import Auth from '../contexts/auth';
import { Layout } from '../layout';

const Routes: React.FC = () => {
  const { signed } = useContext(Auth);
  return signed ? <Layout><AppRoutes /></Layout> : <Autentication />;
};
export default Routes;
