import axios, { AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {

    "Access-Control-Allow-Headers" : "Content-Type",
    "Access-Control-Allow-Origin": true,
    'Access-Control-Max-Age': 86400,
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem('token') || '';
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
