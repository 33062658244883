import React, { useState, useContext, useRef } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  Select,
  useToast,
  Text,
} from '@chakra-ui/react';

import { FaEdit, FaPlus, FaSearch, FaTrash } from 'react-icons/fa';
import SideBar from '../../components/sideBar';
import * as RotaServices from '../../services/rota';
import rotaInterface from '../../interface/rota';
import Button from '../../components/button';
import RotaCreate from './create';
import empresaContext from '../../contexts/empresa';
import RotaList from '../../components/list/rota';
import carneInterface from '../../interface/carne';
import RotaView from './view';
import TodasRotas from '../../components/list/todosRotas';
import Loading from '../../components/loading';
import { InputControled } from '../../components/Form/inputControled';

const Rota: React.FC = () => {
  const { empresa } = useContext(empresaContext);
  const toast = useToast();
  const [rota, setRota] = useState<rotaInterface>({} as rotaInterface);
  const [rotaLista, setRotaLista] = useState<rotaInterface[]>([]);
  const [carneLista, setCarneLista] = useState<carneInterface[]>([]);

  const [openRotaList, setOpenRotaList] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [openCreate, setOpenCreate] = useState(false);
  const [typeSearch, setTypeSearch] = useState('');
  const [openViewData, setOpenViewData] = useState(false);
  const [todasRotas, setTodaRotas] = useState(false);
  const [listaRotas, setlistaRotas] = useState<rotaInterface[]>([]);
  const [openLoading, setOpenLoading] = useState(false);
  const elementRef = useRef<HTMLInputElement>(null);

  async function handleSearch() {
    setOpenLoading(true);
    let response;
    if (textSearch) {
      switch (typeSearch) {
        case 'nome':
          if (empresa) {
            response = await RotaServices.GetByNome(textSearch, empresa.id);

            if (response.success && response.resource) {
              await setRotaLista(JSON.parse(JSON.stringify(response.resource)));
              setOpenRotaList(true);
            } else {
              toast({
                title: 'Consulta Erro.',
                description: response.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          }
          break;
        case '':
          if (empresa) {
            response = await RotaServices.GetByNome(textSearch, empresa.id);
            if (response.success && response.resource) {
              await setRotaLista(JSON.parse(JSON.stringify(response.resource)));
              setOpenRotaList(true);
            } else {
              toast({
                title: 'Consulta Erro.',
                description: response.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          }
          break;

        default:
          break;
      }
    } else {
      toast({
        title: 'Consulta Erro.',
        description: 'Digite nome para consultar',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setOpenLoading(false);
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCloseTodasRotas = async (value: rotaInterface) => {
    if (value.id !== undefined) {
      if (empresa) {
        const response = await RotaServices.GetById(value.id, empresa.id);

        if (response.success && response.resource) {
          await setRotaLista(JSON.parse(JSON.stringify(response.resource)));
          const rotaNova = JSON.parse(JSON.stringify(response.resource));
          setRota(rotaNova);
          setCarneLista(rotaNova.carnes);
          setOpenViewData(true);
          setTodaRotas(false);
        } else {
          toast({
            title: 'Consulta Erro.',
            description: response.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }

      if (elementRef.current !== null) {
        elementRef.current.focus();
      }
    } else {
      setTodaRotas(false);
    }
  };

  const handleTodasRotas = async () => {
    if (empresa) {
      setOpenLoading(true);
      const response = await RotaServices.GetAll(empresa.id);
      if (response.success && response.resource) {
        await setlistaRotas(JSON.parse(JSON.stringify(response.resource)));
        setTodaRotas(true);
      } else {
        toast({
          title: 'Consulta Erro.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
      setOpenLoading(false);
    }
  };

  const handleCloseRota = (value: rotaInterface) => {
    if (!value.id) {
      setOpenRotaList(false);
      return;
    }
    setRota(value);
    setCarneLista(value.carnes);
    setOpenViewData(true);
    setOpenRotaList(false);
  };

  const handleDelete = async () => {
    const response = await RotaServices.Delete(rota.id);
    if (response.success) {
      setOpenViewData(false);
      toast({
        title: 'Exlusão.',
        description: 'Rota excluida com sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Consulta Erro.',
        description: response.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleEdit = () => {
    setOpenCreate(true);
    setOpenViewData(false);
  };

  const handleRotaNova = () => {
    setRota({} as rotaInterface);
    setOpenCreate(true);
  };

  const handleCloseCreate = (value: rotaInterface) => {
    if (value.id !== undefined) {
      setRota(value);
      setOpenCreate(false);
      setOpenViewData(true);
    }
  };
  const handleCloseView = () => {
    setOpenViewData(false);
  };
  return (
    <Flex w="full">
      {openViewData ? (
        <RotaView
          rota={rota}
          handleClose={handleCloseView}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      ) : (
        <Flex w="full">
          {openCreate ? (
            <RotaCreate
              rotaProps={rota}
              handleCloseCreate={handleCloseCreate}
            />
          ) : (
            <Flex flexDir='column'  w='full' borderRadius='sm' bg="white" p="8" boxShadow="md" h='80vh'>
            <Flex gap='4' align="center" w='full'>
                <Text fontSize="2xl" >
                  Rota
                </Text>

                <Flex
                  w='full'
                  align="center"
                  color="gray.500"
                  position="relative"
                  bg="gray.100"
                  borderRadius="md"
                  h='8'
                  px='4'
                  py='2'
                >
                  <Select
                    variant="unstyled"
                    placeholder="Pesquisa por"
                    value={typeSearch}
                    onChange={event => setTypeSearch(event.target.value)}
                    w='52'
                  >
                    <option value="nome">Nome</option>
                  </Select>

                  <InputControled
                      label=''
                      onKeyDown={handleKeyPress}
                      value={textSearch}
                      onChange={event => setTextSearch(event.target.value)}
                      border='none'
                  />
                  <IconButton
                    aria-label="open-search"
                    icon={<Icon as={FaSearch} />}
                    fontSize="18"
                    variant="link"

                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={handleSearch}
                  />
                </Flex>

                <Button
                  type="button"
                  onClick={handleRotaNova}
                  leftIcon={<Icon as={FaPlus} />}
                  w='44'
                >
                  Criar Novo
                </Button>
              </Flex>
              <Grid
                h={350}
                templateRows="repeat(10, 1fr)"
                templateColumns="repeat(6, 1fr)"
                gap={3}
                mt='4'
              >
                <GridItem colSpan={10}>
                  <Flex align="center" justify="center" direction="column">
                    <Text>Utilize o campo acima para consulta</Text>
                    <Text>A consulta de rota é por Nome</Text>
                    <Text
                      color="purple.600"
                      cursor="pointer"
                      onClick={handleTodasRotas}
                      mt="4"
                    >
                      Clique aqui para mostrar todas as rotas
                    </Text>
                  </Flex>
                </GridItem>
              </Grid>
            </Flex>
          )}
        </Flex>
      )}
      {openRotaList && (
        <RotaList
          rotas={rotaLista}
          handleClose={handleCloseRota}
          title="Lista de Rotas"
          isOpen={openRotaList}
        />
      )}
      {todasRotas && (
        <TodasRotas
          title="Lista de Rotas"
          rotas={listaRotas}
          handleClose={handleCloseTodasRotas}
          isOpen={todasRotas}
        />
      )}
      {openLoading && <Loading isOpen={openLoading} text="Aguarde!" />}
    </Flex>
  );
};

export default Rota;
