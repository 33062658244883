import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';

interface loginInterface {
  email: string;
  senha: string;
}

export const errorInternet: baseResponse = {
  success: false,
  resource: '',
  message: 'Não foi possivel a conexão com o servidor, verifique sua internet',
};

const usuario = {};

export async function usuarioAuthenticate(
  data: loginInterface,
): Promise<baseResponse> {
  return api
    .post('/usuario/authenticate', data)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}
export async function Insert(data: any): Promise<baseResponse> {
  return api
    .post('/usuario', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/usuario/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function Delete(data: number): Promise<baseResponse> {
  return api
    .delete(`/usuario/${data}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetAll(empresaId: number): Promise<baseResponse> {
  return api
    .get('/usuario/consulta', {
      params: {
        empresaId,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetById(data: any): Promise<baseResponse> {
  return api
    .get(`/usuario/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function GetByName(
  empresaId: number,
  nome: string,
): Promise<baseResponse> {
  return api
    .get(`/usuario/consulta`, {
      params: {
        empresaId,
        nome,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}

export async function GetByEmail(
  empresaId: number,
  email: string,
): Promise<baseResponse> {
  return api
    .get(`/usuario/consulta`, {
      params: {
        empresaId,
        email,
      },
    })
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}

export async function NovaSenha(data: any): Promise<baseResponse> {
  return api
    .put('/usuario/salvar-nova-senha', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (!error.response) {
        return errorInternet;
      }
      return error.response.data;
    });
}

export async function redeFinirSenha(email: any): Promise<baseResponse> {
  return api
    .post('/usuario/enviar-link-redefinir-senha', JSON.stringify(email))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}
