import React, { ForwardRefRenderFunction, ReactNode, forwardRef } from 'react';

import {
  FormControl,
  FormErrorMessage,
  Checkbox as ChakraChekBox,
  CheckboxProps as ChakraInputProps,
} from '@chakra-ui/react';
import { FieldError, Path, UseFormRegister } from 'react-hook-form';

interface Props extends ChakraInputProps {
  id: Path<any>
  label:string;
  register: UseFormRegister<any>
  error?: FieldError;
  children: ReactNode;
}

const CheckBoxBase = ( { id,label,register, error, children, ...rest }:Props) => {
  return (
    <FormControl isInvalid={!!error}>
      <ChakraChekBox
        {...register(id)}
        focusBorderColor="teal.500"
        _hover={{
          color: 'teal.900',
        }}
        mt="8"
        size="lg"
        {...rest}
      >
        {children}
      </ChakraChekBox>
      {!!error && <FormErrorMessage pl={10}>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const CheckBox = forwardRef(CheckBoxBase);
