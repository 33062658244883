import React, { useContext } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';

import SideBarNav from './sideBarNav';
import { useSideBarDrawer } from '../../contexts/drawer';
import auth from '../../contexts/auth';
import { FaPowerOff } from 'react-icons/fa';
import Button from '../button';

const SideBar = () => {
  const { isOpen, onClose } = useSideBarDrawer();
  const isDrawer = useBreakpointValue({ base: true, lg: false });
  const { signOut } = useContext(auth);

  if (isDrawer) {
    return (
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton mt="6" />
            <DrawerHeader>Navegação</DrawerHeader>
            <DrawerBody>
              <SideBarNav />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }
  return (
    <Flex as="aside" 
    pos="sticky"
    top="0"
    left="0"
    h="90vh"
    w='260px'
    flexDir="column"
    justify="space-between"
    transition="all 0.2s ease"
    px={isOpen ? "2" : "0"}
    py='1'
    >
      <SideBarNav />
      
      <HStack w='full'>
        <Button  w='44' colorScheme='indigo' onClick={signOut} leftIcon={<Icon as={FaPowerOff} />}>
          Sair
        </Button>
      </HStack>
      
    </Flex>
  );
};
export default SideBar;
