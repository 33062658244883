import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  List,
  ListItem,
  HStack,
  Avatar,
  Text,
  Input as InputChakra,
  Flex,
  IconButton,
  Icon,
  useToast,
  Stack,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { FaSearch } from 'react-icons/fa';
import rotaInterface from '../../interface/rota';
import usuarioInterface from '../../interface/usuario';
import { SelectRota } from '../Form/selectRota';
import * as UsuarioServices from '../../services/usuario';
import * as RotasServices from '../../services/rota';

import SimpleList from '../list/usuario';
import empresaContext from '../../contexts/empresa';
import { InputControled } from '../Form/inputControled';

interface SelectRotaProps {
  title: string;
  isOpen: boolean;
  onClose(rota: rotaInterface): void;
}

const errorSchema = yup.object().shape({
  usuario: yup.string().required('Usuário é obrigatório'),
  rota: yup.string().required('Rota é obrigatório'),
});

const RotaSelect: React.FC<SelectRotaProps> = ({ title, isOpen, onClose }) => {
  const { empresa } = useContext(empresaContext);

  const [rotas, setRotas] = useState<rotaInterface[]>([]);
  const [textSearch, setTextSearch] = useState('');
  const [openListaSearch, setOpenListaSearch] = useState(false);
  const toast = useToast();
  const [rota, setRota] = useState<rotaInterface>({} as rotaInterface);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState:{
      errors
    }
  } = useForm({
    resolver: yupResolver(errorSchema),
  });

  const handleSearchRota = async () => {
    let response;
    if (textSearch) {
      if (empresa) {
        response = await RotasServices.GetByNome(textSearch, empresa.id);
        if (response.success && response.resource) {
          await setRotas(JSON.parse(JSON.stringify(response.resource)));
          setOpenListaSearch(true);
        } else {
          toast({
            title: 'Consulta Rota.',
            description: response.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }
    } else {
      toast({
        title: 'Consulta Erro.',
        description: 'Digite nome para consultar',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleCloseListaRota = async (value: rotaInterface) => {
    if (!value.id) {
      return;
    }
    setRota(value);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSearchRota();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose({} as rotaInterface)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody p="4">
          <Flex direction="column" w='full'>
            <Flex w='full' align='flex-end'>
              <InputControled
                label='Nome da Rota'
                onKeyDown={handleKeyPress}
                value={textSearch}
                onChange={event => setTextSearch(event.target.value)}
              />
              <IconButton
                aria-label="open-search"
                icon={<Icon as={FaSearch} />}
                fontSize="18"
                mb='2'
                variant="link"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={handleSearchRota}
              />
            </Flex>
            <List overflowY="auto" spacing="2"  h="64" mt='4'>
              {rotas.map(item => {
                return (
                  <ListItem
                    onClick={() => onClose(JSON.parse(JSON.stringify(item)))}
                    key={item.id}
                    bg="gray.100"
                    borderRadius="md"
                    cursor="pointer"
                  >
                    <HStack px="1">
                      <Avatar
                        size="sm"
                        name={item.nomeRota}
                        bg="emerald.400"
                        color="teal.900"
                        boxShadow="md"
                        mx="2"
                        my="2"
                      />
                      <Stack mx="2" my="2">
                        <Text
                          fontWeight="bold"
                          color="gray.600"
                          fontSize="small"
                          casing="capitalize"
                        >
                          {item.nomeRota.toLowerCase()}
                        </Text>
                      </Stack>
                    </HStack>
                  </ListItem>
                );
              })}
            </List>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default RotaSelect;
