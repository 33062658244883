import React, { useState } from 'react';
import { Box, Flex, Grid, GridItem, Text, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '../../components/Form/Input';
import animationData from '../../assets/password.json';
import animationSuccess from '../../assets/done.json';

import Button from '../../components/button';
import * as UsuarioService from '../../services/usuario';

type TParams = { token: string };

const userFormSchema = yup.object().shape({
  novaSenha: yup
    .string()
    .required('senha obrigatório')
    .min(6, 'senha deve ter o minimo 6 caracteres'),
  senhaConfirmada: yup
    .string()
    .oneOf([yup.ref('novaSenha')], 'as senhas precisam ser iguais'),
});

interface senhaInterface {
  novaSenha: string;
}
const RedefinirSenha: React.FC = () => {
  const { token } = useParams<TParams>();
  const [success, setSuccess] = useState(false);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors,isSubmitting} } = useForm({
    resolver: yupResolver(userFormSchema),
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const doneOptions = {
    loop: false,
    autoplay: true,
    animationData: animationSuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleSalvar: SubmitHandler<senhaInterface> = async values => {
    const novaSenha = {
      tokenNovaSenha: token,
      novaSenha: values.novaSenha,
    };
    const response = await UsuarioService.NovaSenha(novaSenha);
    if (response.success) {
      setSuccess(true);
    } else {
      toast({
        title: 'Redefinir Senha.',
        description: response.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Flex
      as="form"
      w="100%"
      my="6"
      maxWidth={1480}
      mx="auto"
      px="6"
      align="center"
      justify="center"
      direction="column"
      onSubmit={handleSubmit(handleSalvar)}
    >
      {success ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          w="100%"
          my="6"
          mx="auto"
          px="6"
        >
         
          <Text
            fontSize={['2xl', '4xl']}
            fontWeight="black"
            w={64}
            mt={50}
            mb={50}
            color="teal.600"
            textAlign="center"
          >
            Senha Atualizada com sucesso!
          </Text>
        </Flex>
      ) : (
        <>
          <Text
            fontSize={['2xl', '4xl']}
            fontWeight="black"
            letterSpacing={-2}
            w={64}
            mb={50}
          >
            supercrediario.
          </Text>
          <Flex direction="column" align="center" justify="center">
                </Flex>
          <Text fontSize={['lg', '2xl']} mb={50}>
            Redefinir Senha!
          </Text>
          <Grid
            h={200}
            templateRows="repeat(6, 1fr)"
            templateColumns="repeat(6, 1fr)"
            gap={4}
          >
            <GridItem colSpan={[6, 3]}>
              <Input
                id="novaSenha"
                type="password"
                error={errors.novaSenha}
                label="Senha"
                register={register}
                autoComplete="off"
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <Input
                id="senhaConfirmada"
                type="password"
                error={errors.senhaConfirmada}
                label="Confirmação de senha"
                register={register}
                autoComplete="off"
              />
            </GridItem>
            <GridItem colSpan={[6, 6]}>
              <Button
                type="submit"
                w="100%"
                mt="8"
                isLoading={isSubmitting}
              >
                Salvar
              </Button>
            </GridItem>
          </Grid>
        </>
      )}
    </Flex>
  );
};
export default RedefinirSenha;
