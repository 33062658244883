import { AxiosResponse, AxiosError } from 'axios';
import baseResponse from '../../interface/baseResponse';
import api from '../api.js';

export async function Insert(data: any): Promise<baseResponse> {
  return api
    .post('/forma-pagamento', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function Update(data: any): Promise<baseResponse> {
  return api
    .put('/forma-pagamento/', JSON.stringify(data))
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function Delete(data: any): Promise<baseResponse> {
  return api
    .delete(`/forma-pagamento/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function GetAll(): Promise<baseResponse> {
  return api
    .get('/forma-pagamento')
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data.message;
    });
}

export async function GetById(data: any): Promise<baseResponse> {
  return api
    .get(`/forma-pagamento/${data.id}`)
    .then((response: AxiosResponse<baseResponse> | AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError<baseResponse>) => {
      return error.response?.data;
    });
}
