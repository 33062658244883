import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  Select,
  Input,
  IconButton,
  Grid,
  GridItem,
  useToast,
} from '@chakra-ui/react';
import { FaEdit, FaEraser, FaPlus, FaSearch, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import * as UsuarioServices from '../../services/usuario';
import * as AcessoServices from '../../services/acesso';

import Button from '../../components/button';
import Header from '../../components/header';
import SideBar from '../../components/sideBar';
import CreateUsuario from './create';
import UsuarioView from './view';
import SimpleList from '../../components/list/usuario';
import usuarioInterface from '../../interface/usuario';
import acessoInterface from '../../interface/acesso';

import empresaContext from '../../contexts/empresa';
import TodosUsuarios from '../../components/list/todosUsuarios';
import Loading from '../../components/loading';
import { InputControled } from '../../components/Form/inputControled';
import todosUsuarios from '../../components/list/todosUsuarios';

interface itens {
  title: string;
  subTitle: string;
}

const Usuario: React.FC = () => {
  const { empresa } = useContext(empresaContext);
  const [acesso, setAcesso] = useState<acessoInterface>({} as acessoInterface);

  const [textSearch, setTextSearch] = useState('');
  const [openCreate, setOpenCreate] = useState(false);
  const [typeSearch, setTypeSearch] = useState('');
  const [openViewData, setOpenViewData] = useState(false);
  const [openListaSearch, setOpenListaSearch] = useState(false);
  const [usuarioLista, setUsuarioLista] = useState<usuarioInterface[]>([]);
  const toast = useToast();
  const [usuario, setUsuario] = useState<usuarioInterface>(
    {} as usuarioInterface,
  );
  const [todosUsuarios, setTodosUsuarios] = useState(false);
  const [listaUsuarios, setListaUsuarios] = useState<usuarioInterface[]>([]);
  const [openLoading, setOpenLoading] = useState(false);
  const elementRef = useRef<HTMLInputElement>(null);

  const getAcesso = async (value: usuarioInterface) => {
    if (empresa) {
      const response = await AcessoServices.GetById(value.id);
      if (response.success && response.resource) {
        const acessos = Array.from<acessoInterface>(
          JSON.parse(JSON.stringify(response.resource)),
        );
        const acessoUsuario = acessos.find(item => {
          return item.empresa.id === empresa.id;
        });

        if (acessoUsuario) {
          setAcesso(acessoUsuario);
        }
      }
    }
  };

  const handleTodosUsuarios = async () => {
    if (empresa) {
      setOpenLoading(true);
      const response = await UsuarioServices.GetAll(empresa.id);
      if (response.success && response.resource) {
        await setListaUsuarios(JSON.parse(JSON.stringify(response.resource)));
        setTodosUsuarios(true);
      } else {
        toast({
          title: 'Consulta Erro.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
      setOpenLoading(false);
    }
  };

  const handleCloseTodosUsuarios = (value: usuarioInterface) => {
    if (value.id !== undefined) {
      setTypeSearch('email');
      setTextSearch(value.email);
      setTodosUsuarios(false);
      if (elementRef.current !== null) {
        elementRef.current.focus();
      }
    } else {
      setTodosUsuarios(false);
    }
  };

  const handleSearch = async () => {
    let response;
    setOpenLoading(true);
    switch (typeSearch) {
      case 'nome':
        if (empresa) {
          response = await UsuarioServices.GetByName(empresa.id, textSearch);

          if (response.success && response.resource) {
            await setUsuarioLista(
              JSON.parse(JSON.stringify(response.resource)),
            );
            setOpenListaSearch(true);
            setTextSearch('');
            setOpenLoading(false);
          } else {
            setOpenLoading(false);
            toast({
              title: 'Consulta usuário.',
              description: response.message,
              status: 'error',
              duration: 2000,
              isClosable: true,
            });
          }
        }
        break;
      case 'email':
        if (empresa) {
          response = await UsuarioServices.GetByEmail(empresa.id, textSearch);
          if (response.success && response.resource) {
            await setUsuario(JSON.parse(JSON.stringify(response.resource)));
            setOpenViewData(true);
            await getAcesso(JSON.parse(JSON.stringify(response.resource)));
            setTextSearch('');
            setOpenLoading(false);
          } else {
            setOpenLoading(false);
            toast({
              title: 'Consulta usuário.',
              description: response.message,
              status: 'error',
              duration: 2000,
              isClosable: true,
            });
          }
        }
        break;
      case '':
        if (empresa) {
          response = await UsuarioServices.GetByName(empresa.id, textSearch);

          if (response.success && response.resource) {
            setOpenLoading(false);
            await setUsuarioLista(
              JSON.parse(JSON.stringify(response.resource)),
            );
            setOpenListaSearch(true);
            setTextSearch('');
          } else {
            setOpenLoading(false);
            toast({
              title: 'Consulta usuário.',
              description: response.message,
              status: 'error',
              duration: 2000,
              isClosable: true,
            });
          }
        }
        break;
      default:
        break;
    }
    if (response?.success === false) {
      toast({
        title: 'Consulta Erro.',
        description: response?.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setOpenLoading(false);
    }
  };
  const handleDelete = async () => {
    const response = await UsuarioServices.Delete(usuario.id);
    if (response.success) {
      toast({
        title: 'Exclusão usuário.',
        description: 'Usuário Excluido com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setOpenViewData(false);
    } else {
      toast({
        title: 'Exclusão usuário.',
        description: response.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const onClose = async (value: usuarioInterface) => {
    if (value.id !== undefined) {
      setUsuario(value);
      setOpenCreate(false);
      setOpenViewData(true);
    }
    setOpenCreate(false);
    setOpenViewData(false);
  };

  const handleCloseListaCliente = async (value: usuarioInterface) => {
    if (!value.id) {
      setOpenListaSearch(false);
      return;
    }
    setUsuario(value);
    await getAcesso(value);
    setOpenListaSearch(false);
    setOpenViewData(true);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleOpenCreate = async () => {
    await setUsuario({} as usuarioInterface);
    await setOpenCreate(true);
  };

  const handleEdit = async () => {
    setOpenViewData(false);
    await setOpenCreate(true);
  };

  const handleClose = async () => {
    setOpenViewData(false);
  };

  return (
    <Flex w='full'>
      {openViewData ? (
        <UsuarioView
          usuario={usuario}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleClose={handleClose}
        />
      ) : (
        <Flex w="full">
          {openCreate ? (
            <CreateUsuario
              usuario={usuario}
              acesso={acesso}
              onClose={onClose}
            />
          ) : (
            <Flex flexDir='column'  w='full' borderRadius='sm' bg="white" p="8" boxShadow="md" h='80vh'>
              <Flex gap='4' align="center" w='full'>
                <Text fontSize="2xl" >
                  Usuário
                </Text>

                <Flex
                  w='full'
                  align="center"
                  color="gray.500"
                  position="relative"
                  bg="gray.100"
                  borderRadius="md"
                  h='8'
                  px='4'
                  py='2'
                >
                  <Select
                    variant="unstyled"
                    placeholder="Pesquisa por"
                    value={typeSearch}
                    onChange={event => setTypeSearch(event.target.value)}
                    w='52'

                  >
                    <option value="nome">Nome</option>
                    <option value="email">Email</option>
                  </Select>

                  <InputControled
                    label=''
                    onKeyDown={handleKeyPress}
                    value={textSearch}
                    onChange={event => setTextSearch(event.target.value)}
                    border='none'
                    
                  />
                  <IconButton
                    aria-label="open-search"
                    icon={<Icon as={FaSearch} />}
                    fontSize="18"
                    variant="link"
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={handleSearch}
                  />
                </Flex>
                <Button
                  type="button"
                  onClick={handleOpenCreate}
                  leftIcon={<Icon as={FaPlus} />}
                  w='44'
                >
                  Criar Novo
                </Button>
              </Flex>
              <Flex align="center" justify="center" direction="column" mt='4'>
                <Text>Utilize o campo acima para consulta</Text>
                <Text>são 2 tipos de consulta por Nome ou E-Mail</Text>
                <Text
                  color="indigo.600"
                  cursor="pointer"
                  onClick={handleTodosUsuarios}
                  mt="4"
                >
                  Clique aqui para mostrar todos os usuários
                </Text>
              </Flex>

              {openListaSearch && (
                <SimpleList
                  title="Consulta Usuário"
                  usuarios={usuarioLista}
                  isOpen={!!openListaSearch}
                  handleClose={handleCloseListaCliente}
                />
              )}
            </Flex>
          )}
        </Flex>
      )}
      {todosUsuarios && (
        <TodosUsuarios
          title="Lista de Usuários"
          usuarios={listaUsuarios}
          handleClose={handleCloseTodosUsuarios}
          isOpen={todosUsuarios}
        />
      )}
      {openLoading && <Loading isOpen={openLoading} text="Aguarde!" />}
    </Flex>
  );
};

export default Usuario;
